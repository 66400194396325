import type { CSSProperties } from 'react';
import styled from 'styled-components';

import { Row, primary } from 'enable-ui';

export const Wrapper = styled(Row)`
  background: ${primary['primary-1']};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex: 1;
  overflow: hidden;
  > div {
    max-height: calc(100vh - 78px);
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    position: relative;
  }
`;

export const PageContent = ({
  containerStyle,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { containerStyle?: CSSProperties }) => (
  <Wrapper style={containerStyle}>
    <div {...props} />
  </Wrapper>
);

export const ContentWrapper = styled.div`
  margin: auto;
  max-width: 952px;
`;
