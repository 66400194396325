import { CustomTypo } from 'components/CustomTypography';
import { TableLoadingRow, TableWrapper } from 'components/TableWrapper';
import { PageContent, PageHeader } from 'components/layout/';
import {
  Button,
  Row,
  Table,
  TextInput,
  Typography,
  neutral,
  neutralTrans,
  openModal,
  updateQueryStateTable,
  useTableStore,
} from 'enable-ui';

import { useOrgId, useOrgName } from 'modules/organization/core';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as NoUser } from 'assets/images/user-x.svg';

import { useTableManager } from 'hooks/table';

import { MODAL_KEY } from 'constants/modal-key';

import { userColumns } from '../core/columns';

const tableKey = 'users';

export const UserPage = () => {
  const orgName = useOrgName();
  const orgId = useOrgId();
  const queryState = useTableStore((s) => s.state.queryState[tableKey]);

  const fetchDataUrl = orgId ? `/organizations/${orgId}/users` : '';

  const {
    rowItems,
    hasNoData,
    isLoading: { value: isLoading },
    isFirstLoad,
    searchValue,
    total,

    onSearchChange,
    onDeleteCallback,
    fetchData,
  } = useTableManager(tableKey, fetchDataUrl);

  const openAddMember = () => {
    openModal(MODAL_KEY.MEM_ADD, { callback: fetchData });
  };

  const renderContent = () => {
    if (isFirstLoad) {
      return TableLoadingRow;
    }

    if (!isLoading && !rowItems.length) {
      return queryState?.search ? (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Search className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            There is no result matching your search
          </Typography>
        </Row>
      ) : (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <NoUser className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']} style={{ marginBottom: 24 }}>
            There is no member yet
          </Typography>
          <Button
            variant="contained"
            color="primary"
            icon={<Plus />}
            placementIcon="left"
            onClick={() => {
              openAddMember();
            }}
          >
            Add
          </Button>
        </Row>
      );
    }

    return (
      <>
        <Table
          dataSource={isLoading ? [] : rowItems}
          columns={userColumns(onDeleteCallback)}
          queryState={queryState}
          updateQueryState={updateQueryStateTable}
          tableKey="users"
          total={total}
          paginationConfigs={{ showRowsPerPage: true, showTotal: true, show: !isLoading }}
          headerConfigs={{
            styledTr: {
              backgroundColor: 'transparent',
              height: 48,
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
            },
          }}
          bodyConfigs={{ isSameRowBg: true }}
        />

        {isLoading ? TableLoadingRow : null}
      </>
    );
  };

  return (
    <>
      <PageHeader title="Members" />
      <PageContent>
        <TableWrapper className={isLoading || !rowItems.length ? 'dt-empty' : ''}>
          <Row style={{ padding: 24 }} justify="space-between" align="center">
            <CustomTypo variant="h3" weight={500}>
              Members of {orgName}
            </CustomTypo>

            {hasNoData ? null : (
              <Button
                variant="contained"
                color="primary"
                icon={<Plus />}
                placementIcon="left"
                onClick={() => {
                  openAddMember();
                }}
              >
                Add
              </Button>
            )}
          </Row>

          {!hasNoData && (
            <Row style={{ padding: '0px 24px' }} justify="start" align="center">
              <TextInput
                size="default"
                containerStyle={{ width: 320 }}
                prefix={<Search />}
                placeholder="Search with email or name"
                onChange={onSearchChange}
                value={searchValue}
              />
            </Row>
          )}

          {renderContent()}
        </TableWrapper>
      </PageContent>
    </>
  );
};
