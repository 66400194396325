import { type ChangeEvent, useCallback, useEffect, useState } from 'react';

import { updateQueryStateTable, useTableStore } from 'enable-ui';

import { debounce } from 'lodash';

import { initialQueryState } from 'constants/general';

import { apiCall } from './service';
import { useBoolean, useNumber, useString } from './state-basic';

export const useTableManager = (
  tableKey: string,
  url: string,
  options?: {
    fetchDataCallback?: (response: any) => void;
    dependencies?: any[];
    params?: any;
  },
) => {
  const queryState = useTableStore((s) => s.state.queryState[tableKey]);

  const searchInput = useString();

  const [rowItems, setRowItems] = useState<any[]>([]);
  const total = useNumber(0);
  const firstLoad = useBoolean(true);
  const isLoading = useBoolean(true);

  const hasNoData = firstLoad.value || (!isLoading.value && !searchInput.value && !rowItems.length);

  const fetchData = async () => {
    if (!url) {
      return;
    }

    isLoading.setValue(true);
    setRowItems([]);

    const result = await apiCall<any, any>({
      url,
      method: 'GET',
      data: {
        page: queryState?.page || 1,
        limit: queryState?.limit || 10,
        order_by: queryState?.orderBy || 'createdAt',
        order_direction: (queryState?.orderDirection || 'DESC').toLocaleLowerCase(),
        search: queryState?.search?.trim() || '',
        ...options?.params,
      },
      onFinally: () => {
        firstLoad.setValue(false);
        isLoading.setValue(false);
      },
    });

    if (result?.items) {
      setRowItems(result?.items);
      total.setValue(result.count);
      options?.fetchDataCallback?.(result);
    }
  };

  useEffect(() => {
    if (queryState) {
      fetchData();
    }
  }, [url, JSON.stringify(queryState), JSON.stringify(options?.params)]);

  useEffect(() => {
    updateQueryStateTable(tableKey, initialQueryState);

    return function unsubscribe() {
      updateQueryStateTable(tableKey, initialQueryState);
      setRowItems([]);
      firstLoad.setValue(true);
      searchInput.setValue('');
    };
  }, options?.dependencies || []);

  const fetchSearch = (value: string) => {
    updateQueryStateTable(tableKey, {
      page: 1,
      search: value,
    });
  };

  const debounceSearch = debounce(fetchSearch, 350);

  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    searchInput.setValue(event.target.value);

    if (!event.target.value) {
      // trigger isLoading immediate for prevent `hasNoData` glitch
      // (because of the debounce delay)
      isLoading.setValue(true);
    }

    debounceSearch(event.target.value);
  }, []);

  const onDeleteCallback = async () => {
    if (total.value > 1 && rowItems.length === 1 && queryState?.page > 1) {
      updateQueryStateTable(tableKey, { page: queryState?.page - 1 });
    } else {
      fetchData();
    }
  };

  return {
    rowItems,
    hasNoData,
    isLoading,
    total: total.value,
    isFirstLoad: firstLoad.value,
    searchValue: searchInput.value,

    onSearchChange,
    fetchData,
    onDeleteCallback,
  };
};
