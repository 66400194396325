import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ModalController } from 'components/modal';
import { PageLoading, ThemeProvider, ToastMessageContainer } from 'enable-ui';
import { AppRoutes } from 'routing/AppRoutes';

import { useOrgName } from 'modules/organization/core';

import './styles/fonts.css';
import './styles/global.css';
import { customConfig } from 'styles/theme';

import ScrollToTopOnNavigate from 'helpers/scroll';
import { useScreen, useWindowVh } from 'hooks';
import { initAxiosInterceptor } from 'services';

// To-do add
initAxiosInterceptor();

function App() {
  const orgName = useOrgName();

  useEffect(() => {
    if (orgName) {
      document.title = `ARTC Experiment Logger - ${orgName}`;
    }
  }, [orgName]);

  useWindowVh();

  useScreen();

  return (
    <ThemeProvider theme={customConfig.theme}>
      <BrowserRouter>
        <ScrollToTopOnNavigate />

        <PageLoading zIndex={999999} />

        <ToastMessageContainer autoClose={2500} position="top-center" />

        <AppRoutes />

        <ModalController />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
