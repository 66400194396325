import { useEffect, useState } from 'react';

import { CustomTypo } from 'components/CustomTypography';
import { Pill } from 'components/Pill';
import { Button, Modal, Row, Typography } from 'enable-ui';

import { useUpdateVariants } from 'modules/project/core/controller/process-edit.controller';
import { getVariantName } from 'modules/project/core/helpers';
import { useProcessStore } from 'modules/project/core/store';
import { type TemplateSection } from 'modules/template/core';

import { cloneDeep, compact, groupBy, includes, initial, xorBy } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

export const VariantSelectModal = () => {
  const process = useProcessStore((s) => s.state.dedProcess);

  const template = process.templates.find((el) => el?.sections?.[0]?.selected === false);

  const listVariants = Object.values(groupBy(template?.sections, 'masterId'));

  const [selectedVariants, setSelectedVariants] = useState<TemplateSection[]>([]);
  const { updateVariants } = useUpdateVariants(process.id);

  useEffect(() => {
    if (listVariants.length) {
      setSelectedVariants(listVariants.map((el) => el[0]));
    }
  }, [listVariants.length]);

  const onSelectVariant = (variant: TemplateSection) => {
    setSelectedVariants((prev) => {
      if (variant?.multipleSelected) {
        return xorBy(prev, [variant], 'id');
      }

      const oldIndex = prev.findIndex((el) => el.masterId === variant.masterId);
      const newSelect = cloneDeep(prev);

      if (oldIndex >= 0) {
        newSelect[oldIndex] = variant;

        return newSelect;
      } else {
        newSelect.push(variant);
      }

      return newSelect;
    });
  };

  return (
    <Modal
      modalKey={MODAL_KEY.VARIANT_SELECT}
      header={
        <CustomTypo variant="h3" weight={500}>
          Select variant
        </CustomTypo>
      }
      size="lg"
      hideCloseIcon
      closeOnClickOutside={false}
      footer={
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '0px 24px 24px 24px' }}
          disabled={selectedVariants.length < listVariants.length}
          onClick={() => {
            updateVariants(selectedVariants, template?.type);
          }}
        >
          Select
        </Button>
      }
    >
      <Typography variant="h4" size={18} weight={500} style={{ paddingBottom: 12, paddingTop: 8 }}>
        {template?.name}
      </Typography>
      {listVariants.map((el) =>
        el.length > 1 ? (
          <>
            <CustomTypo weight={600} style={{ marginBottom: 6 }}>
              {initial(el[0].name.split(' '))?.join(' ')}
            </CustomTypo>
            <Row style={{ gap: 8, flexWrap: 'wrap', marginBottom: 16 }}>
              {el.map((variant, index) => {
                return (
                  <Pill
                    showCheckbox={variant.multipleSelected}
                    key={variant.id}
                    active={includes(
                      selectedVariants.map((vari) => vari.id),
                      variant?.id,
                    )}
                    onClick={() => {
                      onSelectVariant(variant);
                    }}
                  >
                    {getVariantName(variant)}
                  </Pill>
                );
              })}
            </Row>
          </>
        ) : null,
      )}
    </Modal>
  );
};
