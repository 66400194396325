import { useAuthStore } from 'modules/auth/core';

import { API_URL } from 'constants/environment';

export const getImageUrl = (url: string, apiURl?: string) => {
  return `${apiURl ?? API_URL}${url}`;
};

export const checkTypeFile = (fileType: string, type?: string) => {
  const regFile = RegExp(`^.*.(${type ?? 'rar|zip|7z'})$`);

  return regFile.test(fileType);
};

export function downloadURI(uri: string, name: string = '') {
  const link = document.createElement('a');

  link.setAttribute('download', name);
  link.href = uri;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadLocalFile(file: File, name: string = '') {
  const blob = new Blob([file]);
  const url = URL.createObjectURL(blob);

  downloadURI(url, name || file.name);
}

export function downloadFromFile(file: File, name: string = '') {
  const anchor = document.createElement('a');
  const headers = new Headers();

  document.body.appendChild(anchor);
  headers.append('Authorization', `Bearer ${useAuthStore.getState().state.authen.accessToken}`);

  fetch(`${API_URL}/files/${file['id']}/download`, { headers })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);

      downloadURI(url, name || file.name);
    });
}
