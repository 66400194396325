import { includes } from 'lodash';

const PATH = Object.freeze({
  //Auth
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  // Admin
  ORG_ACTION_LOGS: '/org/:orgId/action-logs',
  // Action log - project
  PROJECT_HOME_PAGE: '/org/:orgId/projects/:projectId',
  MEMBERS: '/org/:orgId/members',
  ADMINS: '/admins',
  ALL_MEMBERS: '/all-members',
  TEMPLATES: '/org/:orgId/templates/:type',
  TEMPLATE_ADD: '/org/:orgId/templates/:type/add',
  TEMPLATE_EDIT: '/org/:orgId/templates/:type/:templateId',

  //Userprofile
  USER_PROFILE: '/org/:orgId/my-profile',
  SEARCH: '/org/:orgId/search',
  CHANGE_PASSWORD: '/org/:orgId/change-password',

  // Project
  PROJECTS: '/org/:orgId/projects',
  PROJECT_DED_PROCESSES: '/org/:orgId/projects/:projectId/ded-processes',
  PROJECT_DED_PROCESS_EDIT: '/org/:orgId/projects/:projectId/ded-processes/:processId',
  PROJECT_DED_PROCESS_ADD: '/org/:orgId/projects/:projectId/ded-processes/add',
  PROJECT_BUILDS: '/org/:orgId/projects/:projectId/builds',
  PROJECT_BUILD_EDIT: '/org/:orgId/projects/:projectId/builds/:buildId',
  PROJECT_BUILD_ADD: '/org/:orgId/projects/:projectId/builds/add',
  PROJECT_ACTION_LOG: '/org/:orgId/projects/:projectId/action-logs',

  //Error
  ERROR_404: '/error/404',
  ERROR_403: '/error/403',
  ERROR_500: '/error/500',
});

const AuthPaths = [PATH.LOGIN, PATH.SIGN_UP, PATH.FORGOT_PASSWORD, PATH.RESET_PASSWORD];

// To-do convert to function
const isAuthPath = () => includes(AuthPaths, location.pathname);

export { PATH, isAuthPath };
