import { Outlet } from 'react-router-dom';

import { AuthBody, AuthContainer } from '../styles';

import Logo from 'assets/images/logo.png';

const AuthLayout = () => {
  return (
    <AuthContainer>
      <img src={Logo} alt="logo" />
      <AuthBody>
        <Outlet />
      </AuthBody>
    </AuthContainer>
  );
};

export { AuthLayout };
