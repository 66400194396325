import { EmptyModalWrapper } from 'components/modal';

import { LogDetailModal } from 'modules/log/components/LogDetailModal';

import { MODAL_KEY } from 'constants/modal-key';

import { AddProjectModal } from './AddProjectModal';
import { ChangeProjectOwnerModal } from './ChangeOwnerModal';
import { CreateBuildFromProcessModal } from './CreateBuildFromProcessModal';
import { DeleteProcessBuildModal } from './DeleteProcessBuildModal';
import { DeleteProjectModal } from './DeleteProjectModal';
import { DuplicateProcessBuildModal } from './DuplicateProcessBuildModal';
import { ProjectMemberModal } from './ProjectMemberModal';
import { PublishProcessModal } from './PublishProcessModal';
import { RemoveMemberModal } from './RemoveMemberModal';
import { RenameProjectModal } from './RenameProjectModal';
import { ChangeDedProcessModal } from './build-modals/ChangeDedProcessModal';
import { CreateLinkBuildToNewProcessModal } from './build-modals/CreateLinkBuildToNewProcessModal';
import { SyncBuildDedProcessModal } from './build-modals/SyncBuildDedProcessModal';
import { ChangeTemplateModal } from './process-modals/ChangeTemplateModal';
import { DeleteProcessModal } from './process-modals/DeleteProcessModal';
import { PowderLinkModal } from './process-modals/PowderLinkModal';
import { VariantSelectModal } from './process-modals/VariantSelectModal';

export const ProjectModals = () => {
  return (
    <>
      <EmptyModalWrapper modalKey={MODAL_KEY.PROJECT_RENAME}>
        <RenameProjectModal />
      </EmptyModalWrapper>

      <EmptyModalWrapper modalKey={MODAL_KEY.PROJECT_ADD}>
        <AddProjectModal />
      </EmptyModalWrapper>

      <EmptyModalWrapper modalKey={MODAL_KEY.PROJECT_MEMBER}>
        <ProjectMemberModal />
      </EmptyModalWrapper>
      <ChangeProjectOwnerModal />

      <DeleteProjectModal />

      <DeleteProcessModal />
      <PowderLinkModal />
      <DeleteProcessBuildModal />
      <PublishProcessModal />
      <RemoveMemberModal />

      <EmptyModalWrapper modalKey={MODAL_KEY.PROCESS_BUILD_DUPLICATE}>
        <DuplicateProcessBuildModal />
      </EmptyModalWrapper>
      <EmptyModalWrapper modalKey={MODAL_KEY.CREATE_BUILD_FROM_PROCESS}>
        <CreateBuildFromProcessModal />
      </EmptyModalWrapper>

      <ChangeTemplateModal />
      <VariantSelectModal />

      <ChangeDedProcessModal />
      <SyncBuildDedProcessModal />
      <EmptyModalWrapper modalKey={MODAL_KEY.BUILD_CREATE_LINK_DED_PROCESS_MODAL}>
        <CreateLinkBuildToNewProcessModal />
      </EmptyModalWrapper>

      <LogDetailModal />
    </>
  );
};
