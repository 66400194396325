import { CardWrapper, PageContent, PageHeader } from 'components/layout';
import { Button, Row, Spacing, TextInput, Typography, neutralTrans } from 'enable-ui';

import { ProfileTabs } from '../styles/user-profile';

import { ProfileTab, useProfileController } from '../core/controller';

export const UserProfilePage = () => {
  const { nameForm, passwordForm, activeTab, setActiveTab } = useProfileController();

  return (
    <>
      <PageHeader title="My profile" />
      <PageContent style={{ display: 'flex', justifyContent: 'center' }}>
        <CardWrapper
          style={{
            width: 440,
          }}
        >
          <Typography variant="h3" weight={500} style={{ padding: '0px 24px' }}>
            My profile
          </Typography>
          <Row
            style={{
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
              margin: '24px 0px',
            }}
          >
            <ProfileTabs
              onTabClick={setActiveTab}
              style={{ width: '100%', borderBottom: '1px solid #000' }}
              items={[
                { key: ProfileTab.profile, children: '', label: 'Profile' },
                { key: ProfileTab.password, children: '', label: 'Password' },
              ]}
              activeKey={activeTab}
            ></ProfileTabs>
          </Row>
          <Spacing spacing="xl" type="padding" placement={['left', 'right']}>
            {activeTab === 'profile' ? (
              <form>
                <TextInput
                  {...nameForm.form.register('fullName')}
                  {...nameForm.registerError('fullName')}
                  title="Display name"
                  placeholder=""
                  containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={nameForm.handleSubmit}
                  disabled={nameForm.disabled}
                >
                  Save
                </Button>
              </form>
            ) : (
              <>
                <TextInput
                  {...passwordForm.form.register('oldPassword')}
                  {...passwordForm.registerError('oldPassword')}
                  title="Current Password"
                  type="password"
                  placeholder=""
                  containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                    }
                  }}
                />
                <TextInput
                  {...passwordForm.form.register('newPassword')}
                  {...passwordForm.registerError('newPassword')}
                  title="New Password"
                  type="password"
                  placeholder=""
                  containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                    }
                  }}
                />
                <TextInput
                  {...passwordForm.form.register('confirmPassword')}
                  {...passwordForm.registerError('confirmPassword')}
                  title="Confirm Password"
                  type="password"
                  placeholder=""
                  containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={passwordForm.handleSubmit}
                  disabled={passwordForm.disabled}
                >
                  Save
                </Button>
              </>
            )}
          </Spacing>
        </CardWrapper>
      </PageContent>
    </>
  );
};
