import { useEffect } from 'react';

import { CustomTypo } from 'components/CustomTypography';
import { ContentWrapper, PageContent } from 'components/layout';
import { Button, Row, TextInput, showToastMessage } from 'enable-ui';

import { LogObjectType } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';
import { TemplatePropertyCard } from 'modules/template/components/section-attribute/TemplatePropertyCard';
import { SectionBlockWrapper } from 'modules/template/components/section-attribute/styles';
import { defaultTemplate } from 'modules/template/core';

import { format } from 'date-fns';
import { useString } from 'hooks';
import { apiCall } from 'hooks/service';

import { ChangeLogDrawer } from '../../log/components/ChangeLogDrawer';
import { ProcessAndBuildHeader } from '../components/ded-process/ProcessAndBuildHeader';
import { ProcessMenu } from '../components/ded-process/ProcessMenu';
import { ProcessStatus, useProjectPermission } from '../core';
import { useAddProcessController } from '../core/controller/add-process.controller';
import {
  useFetchProcess,
  useInitProcessController,
} from '../core/controller/process-edit.controller';
import { useProcessStore, useProjectId } from '../core/store';

export const EditProcessPage = () => {
  const { name, status } = useInitProcessController();
  const fetchApi = useFetchProcess();
  const orgId = useOrgId();
  const projectId = useProjectId();
  const selectedIndex = useProcessStore((s) => s.state.selectedIndex);
  const selectedTemplate = useProcessStore(
    (s) => s.state.dedProcess.templates[Number(selectedIndex)] || defaultTemplate,
  );
  const dedProcessId = useProcessStore((s) => s.state.dedProcess.id);
  const subId = useProcessStore((s) => s.state.dedProcess.subId);
  const updatedAt = useProcessStore((s) => s.state.dedProcess.updatedAt);
  const powderTemplates = useProcessStore((s) =>
    s.state.dedProcess.templates.filter((el) => el.type === 'Powder'),
  );

  const isPublished = status === ProcessStatus.Publish;

  const { isMember } = useProjectPermission();

  useAddProcessController();

  const processName = useString(name);

  useEffect(() => {
    if (name) {
      processName.setValue(name);
    }
  }, [name]);

  const disabledChangeNameBtn = !processName.value || processName.value === name;

  const onChangeName = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projectId}/processes/${dedProcessId}`,
      method: 'PATCH',
      data: { name: processName.value.trim() },
      isLoading: true,
      showError: true,
    });

    if (result.id) {
      showToastMessage('success', 'Saved successfully!');
      useProcessStore.getState().mergeState({
        dedProcess: {
          name: processName.value,
          updatedAt: result.updatedAt,
        },
      });

      useActivityLogStore.getState().clearState(LogObjectType.Process, true);
    }
  };

  return (
    <PageContent>
      <ContentWrapper>
        <ProcessAndBuildHeader title={name} status={status} />

        <Row>
          <ProcessMenu />

          {selectedTemplate.sections.length ? (
            <TemplatePropertyCard
              originSections={selectedTemplate.sections}
              templateId={selectedTemplate.id}
              templateIndex={Number(selectedIndex)}
              uploadTargetId={dedProcessId}
              powderTemplates={powderTemplates}
              submitSuccessCallback={fetchApi}
              options={{
                collapsible: true,
                propertyModifiable: isMember && !isPublished,
                propertyModifiableCustomOnly: isMember && !isPublished,
                propertyValueEditable: isMember && !isPublished,
              }}
            />
          ) : (
            <SectionBlockWrapper>
              <CustomTypo className="h3-5-medium" style={{ marginTop: 12 }}>
                DED Process information
              </CustomTypo>
              <Row style={{ padding: '24px 0 16px' }}>
                <div style={{ flex: 1 }}>
                  <CustomTypo weight={600}>Process ID</CustomTypo>
                  <CustomTypo style={{ marginTop: 4 }}>{subId}</CustomTypo>
                </div>
                <div style={{ flex: 1 }}>
                  <CustomTypo weight={600}>Last update</CustomTypo>
                  <CustomTypo style={{ marginTop: 4 }}>
                    {updatedAt ? format(new Date(updatedAt), 'dd MMM, hh:mm a') : ''}
                  </CustomTypo>
                </div>
              </Row>
              <div>
                <TextInput
                  title="Process name"
                  value={processName.value}
                  onChange={(event) => processName.setValue(event.target.value)}
                  containerStyle={isMember ? {} : { pointerEvents: 'none' }}
                  disabled={isPublished}
                />
              </div>

              {isMember && !isPublished ? (
                <Button
                  color="primary"
                  size="large"
                  style={{ marginTop: 24, width: 84 }}
                  disabled={disabledChangeNameBtn}
                  onClick={onChangeName}
                >
                  Save
                </Button>
              ) : null}
            </SectionBlockWrapper>
          )}
        </Row>
      </ContentWrapper>

      <ChangeLogDrawer type={LogObjectType.Process} />
    </PageContent>
  );
};
