import { useNavigate } from 'react-router-dom';

import {
  Modal,
  TextInput,
  Typography,
  closeModal,
  neutral,
  showToastMessage,
  useDialogStore,
} from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { useOrgId } from 'modules/organization/core';
import { useProjectId } from 'modules/project/core';

import { mappingPath } from 'helpers';
import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const CreateBuildFromProcessModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.CREATE_BUILD_FROM_PROCESS]);
  const navigate = useNavigate();

  const { processId } = modalProps || {};

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async ({ name }) => {
      const result = await apiCall<any, any>({
        url: `/organizations/${orgId}/projects/${projId}/builds`,
        data: {
          name,
          processId,
        },
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.CREATE_BUILD_FROM_PROCESS);
        showToastMessage('success', 'Created successfully!');
        navigate(mappingPath(PATH.PROJECT_BUILD_EDIT, [orgId, projId, result.id]));
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.CREATE_BUILD_FROM_PROCESS}
      header="Create a Build with current DED Process"
      submitButton={{
        label: 'Create Build',
        buttonProps: { onClick: handleSubmit, disabled: !isValid },
      }}
    >
      <Typography variant="paragraph" color={neutral['neutral-11']} style={{ marginBottom: 16 }}>
        A Build will be created and linked with the current DED Process. Any unsaved changes you
        made with the current DED Process will not be included in the Build.
      </Typography>
      <TextInput
        title="Build name"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
