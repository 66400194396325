import { useState } from 'react';
import styled, { css } from 'styled-components';

import type { TabPosition, TabVariant, TabsProps } from 'enable-ui';

import { borderRadius, neutral, neutralTrans, primary } from 'styles/theme';

import { clsx } from 'helpers';

export const Tabs: React.FC<TabsProps> = ({
  tabPosition = 'horizontal',
  items,
  variant = 'text',
  onTabClick,
  isLine = false,
  activeKey,
  tabItemStyle,
  ...props
}) => {
  const [selected, setSelected] = useState(items[0]?.key);

  const onClickTabItem = (key: string, disabled?: boolean) => () => {
    if (disabled) {
      return;
    }

    setSelected(key);

    if (onTabClick) {
      onTabClick(key);
    }
  };

  return (
    <StyledContainer
      isLine={isLine}
      tabPosition={tabPosition}
      className={clsx('es-tab', props.className)}
    >
      {Boolean(items.length) &&
        items.map((item, index) => {
          const newSelected = activeKey ?? selected;
          const isSelected = newSelected.startsWith(item.key);
          const itemMarginBottom = tabPosition === 'vertical' ? 8 : 0;

          return (
            <StyledTabItem
              className={`es-tab-item${isSelected ? ' active' : ''}`}
              onClick={onClickTabItem(item.key, item.disabled)}
              key={index}
              variant={variant}
              tabPosition={tabPosition}
              selected={isSelected}
              style={{
                marginBottom: itemMarginBottom,
                ...tabItemStyle,
              }}
              disabled={item.disabled || false}
            >
              {item.label}
              {!item.disabled && isSelected && variant === 'text' && (
                <StyledContainerLine className="tab-line" tabPosition={tabPosition}>
                  <StyledLine tabPosition={tabPosition} />
                </StyledContainerLine>
              )}
            </StyledTabItem>
          );
        })}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<Pick<TabsProps, 'isLine' | 'tabPosition'>>`
  display: flex;
  width: 100%;
  ${({ isLine, tabPosition }) => css`
    flex-direction: ${tabPosition === 'horizontal' ? '' : 'column'};
    border-bottom: 1px solid ${isLine ? neutral['neutral-5'] : 'transparent'};
  `}
`;

const StyledTabItem = styled.div<
  Pick<TabsProps, 'variant' | 'tabPosition'> & { disabled?: boolean; selected: boolean }
>`
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  ${({ variant, tabPosition, selected, disabled }) =>
    variant
      ? css`
          ${disabled ? '' : 'cursor: pointer;'}
          width: ${tabPosition === 'horizontal' ? 'fit-content' : '100%'};
          border-radius: ${tabPosition === 'horizontal' ? borderRadius.xs : 0}px;
          border: ${getTabBorder(variant)};
          color: ${getTabColor(variant, selected, disabled)};
          background-color: ${getTabBgColor(variant, selected, disabled, tabPosition)};
          svg path[stroke] {
            stroke: ${getTabColor(variant, selected, disabled)};
          }
          svg path[fill] {
            fill: ${getTabColor(variant, selected, disabled)};
          }
          .badge {
            color: ${getTabColor(variant, selected, disabled, true)};
            background-color: ${getTabBadgeColor(selected, disabled)};
          }
          &:hover {
            color: ${getTabColor(variant, selected, disabled, true)};
            background-color: ${getTabBgColor(variant, selected, disabled, tabPosition, true)};
            svg path[stroke] {
              stroke: ${getTabColor(variant, selected, disabled, true)};
            }
            svg path[fill] {
              fill: ${getTabColor(variant, selected, disabled, true)};
            }
          }
        `
      : ''}
`;

const StyledContainerLine = styled.div<Pick<TabsProps, 'tabPosition'>>`
  position: absolute;
  display: flex;
  align-items: end;
  top: 0;
  left: 0px;
  right: 0;
  bottom: -2.5px;
  ${({ tabPosition }) => css`
    padding: ${tabPosition === 'horizontal' ? '0px 12px' : '0px'};
    justify-content: ${tabPosition === 'horizontal' ? 'center' : 'start'};
  `}
`;

const StyledLine = styled.div<Pick<TabsProps, 'tabPosition'>>`
  ${({ tabPosition }) => css`
    height: ${tabPosition === 'horizontal' ? '2px' : '100%'};
    width: ${tabPosition === 'horizontal' ? '100%' : '2px'};
    background-color: ${neutral['neutral-11']};
    border-radius: ${borderRadius.max}px;
  `}
`;

const getTabBorder = (variant: TabVariant) => {
  if (variant === 'contained') {
    return `1px solid ${neutralTrans['neutralTrans-15']}`;
  }

  return '';
};

const getTabColor = (
  variant: TabVariant,
  selected: boolean,
  disabled?: boolean,
  isHover?: boolean,
) => {
  if (disabled) {
    return neutralTrans['neutralTrans-25'];
  }

  if (selected) {
    return getColorByVariant(
      variant,
      neutral['neutral-1'],
      neutral['neutral-10'],
      primary['primary-6'],
    );
  }

  if (isHover) {
    return neutral['neutral-10'];
  }

  return getColorByVariant(
    variant,
    neutral['neutral-10'],
    neutral['neutral-7'],
    neutral['neutral-7'],
  );
};

const getTabBgColor = (
  variant: TabVariant,
  selected: boolean,
  disabled?: boolean,
  tabPosition?: TabPosition,
  isHover?: boolean,
) => {
  if (disabled) {
    return neutral['neutral-1'];
  }

  if (selected) {
    if (tabPosition === 'vertical' && variant === 'text') {
      return primary['primary-1'];
    }

    return getColorByVariant(
      variant,
      neutral['neutral-10'],
      neutral['neutral-4'],
      neutral['neutral-1'],
    );
  }

  if (isHover) {
    return getColorByVariant(
      variant,
      neutral['neutral-4'],
      neutral['neutral-1'],
      neutral['neutral-1'],
    );
  }

  return neutral['neutral-1'];
};

const getTabBadgeColor = (selected: boolean, disabled?: boolean) => {
  if (disabled) {
    return neutralTrans['neutralTrans-3'];
  }

  if (selected) {
    return primary['primary-1'];
  }

  return neutral['neutral-4'];
};

const getColorByVariant = (
  variant: TabVariant,
  containedColor: string,
  outlinedColor: string,
  defaultColor: string,
) => {
  switch (variant) {
    case 'contained':
      return containedColor;

    case 'outlined':
      return outlinedColor;

    default:
      return defaultColor;
  }
};
