import { useNavigate } from 'react-router-dom';

import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { removeProjectState, useProjectStore } from '../../core';

export const DeleteProjectModal = () => {
  const curProject = useProjectStore((s) => s.state.curProject);
  const projects = useProjectStore((s) => s.state.projects);
  const orgId = useOrgId();
  const navigate = useNavigate();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROJECT_DELETE]);

  const isBlockNavigate = modalProps?.isBlockNavigate;
  const proj = modalProps?.proj;

  const handleSubmit = async () => {
    if (!proj && !curProject) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${proj?.id || curProject?.id}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      closeModal(MODAL_KEY.PROJECT_DELETE);

      if (curProject) {
        removeProjectState(curProject);
      }

      showToastMessage('success', 'Deleted successfully!');

      if (!isBlockNavigate) {
        navigate('/');
      }

      modalProps?.callback?.();
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_DELETE}
      header="Delete Project"
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: handleSubmit, color: 'danger' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        Deleting this Project will also delete all Project’s data such as DED Processes, Builds,
        etc. Deleted data cannot be recovered.
      </Typography>
    </Modal>
  );
};
