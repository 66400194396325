export const replacePath = (path: string, searchValue: string, replaceValue: string) => {
  return path.replace(searchValue, replaceValue);
};

export const mappingPath = (path: string, values: string[]) => {
  let index = -1;

  const parts = path.split('/').map((part) => {
    if (part.startsWith(':')) {
      index++;

      return values[index];
    }

    return part;
  });

  return parts.join('/');
};
