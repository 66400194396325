import type { FC } from 'react';

import { type ColorConfig, Tag } from 'enable-ui';

import { ProcessStatus } from '../../core';

const STATUS_CONFIG = {
  [ProcessStatus.Draft]: {
    color: 'orange',
    title: 'Draft',
  },
  [ProcessStatus.Publish]: {
    color: 'primary',
    title: 'Published',
  },
};

export interface Props {
  status: ProcessStatus;
}

export const ProcessStatusTag: FC<Props> = ({ status }) => {
  return status ? (
    <Tag
      color={STATUS_CONFIG[status]?.color as ColorConfig}
      size="medium"
      style={status === ProcessStatus.Publish ? { border: '1px solid #184794' } : {}}
    >
      {STATUS_CONFIG[status]?.title}
    </Tag>
  ) : null;
};
