import { CustomTypo } from 'components/CustomTypography';
import { Modal, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { useFetchBuild, useProjectId } from '../../../core';

export const SyncBuildDedProcessModal = () => {
  const orgId = useOrgId();
  const projectId = useProjectId();
  const fetchApi = useFetchBuild();

  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.BUILD_SYNC_MODAL]);

  const buildId = modalProps?.buildId;

  const onSubmit = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projectId}/builds/${buildId}/sync-process`,
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      showToastMessage('success', 'Synced successfully!');
      fetchApi();
      closeModal(MODAL_KEY.BUILD_SYNC_MODAL);
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.BUILD_SYNC_MODAL}
      header="Sync with updates from DED Process"
      submitButton={{
        label: 'Sync',
        buttonProps: { onClick: onSubmit, color: 'primary' },
      }}
    >
      <CustomTypo style={{ lineHeight: '22px' }}>
        This will sync the last updates from the DED Process to this Build. Any changes made with
        sections of this Build will be overwrited.{' '}
      </CustomTypo>
    </Modal>
  );
};
