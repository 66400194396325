import { type FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Dropdown, Typography, neutralTrans } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Import } from 'assets/icons/file-import.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';

import { mappingPath } from 'helpers';

import { useProjectId } from '../core';

const ButtonDropDown = styled.div`
  .dropdown-children {
    &:hover {
      background: ${neutralTrans['neutralTrans-10']};
    }
  }

  .dropdown {
    min-width: 200px;
    max-width: 200px;
    padding: 4px 0;
  }
`;

interface Props {
  addParams: {
    label: string;
    path: string;
  };
  importParams: {
    label: string;
    handleImport: (file: File) => void;
  };
}

export const AddButton: FC<Props> = ({ addParams, importParams }) => {
  const addLabel = addParams.label;
  const addPath = addParams.path;
  const importLabel = importParams.label;
  const handleImport = importParams.handleImport;

  const orgId = useOrgId();
  const projId = useProjectId();
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);

  const onFileChange = async (e: any) => {
    const file = e.target.files?.[0];

    if (file) {
      if (fileRef.current) {
        fileRef.current.value = '';
      }

      handleImport(file);
    }
  };

  return (
    <ButtonDropDown>
      <Dropdown
        className="button-dropdown"
        TickIcon={<></>}
        menu={{
          onClick(item) {
            if (item.key === 'add') {
              navigate(mappingPath(addPath, [orgId, projId]));
            }

            if (item.key === 'import') {
              fileRef.current?.click();
            }
          },
          items: [
            {
              key: 'add',
              label: <Typography variant="body-1">{addLabel}</Typography>,
              prefix: <Plus />,
            },
            {
              key: 'import',
              label: <Typography variant="body-1">{importLabel}</Typography>,
              prefix: <Import />,
            },
          ],
          selectedKeys: [],
        }}
        dropdownConfig={{ trigger: 'click' }}
      >
        <Button variant="contained" color="primary" icon={<ArrowIcon />} placementIcon="right">
          Add
        </Button>
      </Dropdown>
      <input
        type="file"
        accept="application/json"
        name="file"
        ref={fileRef}
        hidden
        onChange={onFileChange}
      />
    </ButtonDropDown>
  );
};
