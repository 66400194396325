import { useParams } from 'react-router-dom';

import {
  PropertyType,
  type TemplateSection,
  type TemplateSectionProperty,
  type TemplateType,
} from './type';

import { cloneDeep, pick, uniq } from 'lodash';

import { TEMPLATE_CONFIG, newPropertyPrefix } from './constant';

export const clearIdStateFromProperty = (properties: TemplateSectionProperty[]) =>
  properties.map(({ id, items, ...prop }) => ({
    ...prop,
    id: id.startsWith(newPropertyPrefix) ? undefined : id,
    items: items ? clearIdStateFromProperty(items) : undefined,
  }));

const fileFieldsFromApi = ['id', 'name', 'path', 'createdAt', 'updatedAt', 'size', 'masterId'];

export const cleanStateDataFromSection = (sections: TemplateSection[] = []) => {
  const cleanSections = cloneDeep(sections);

  cleanSections.forEach(({ properties }, index) => {
    delete cleanSections[index]['expand'];

    properties.forEach((prop, propIndex) => {
      delete prop['timestamp'];
      prop.items?.forEach((item) => {
        delete item['timestamp'];
      });
      prop.files?.forEach((file, fileIndex) => {
        (cleanSections[index].properties[propIndex].files as any)[fileIndex] = pick(
          file,
          fileFieldsFromApi,
        );
      });
    });
  });

  return cleanSections;
};

export const haveEmptyNameProperty = (properties: TemplateSectionProperty[]) =>
  properties.some((property) => {
    /* eslint-disable no-fallthrough */
    switch (property.type) {
      case PropertyType.Checkbox:

      case PropertyType.Radio:
        return !property.name.trim() || property.options?.some((op) => !op.trim());

      case PropertyType.Widget:
        return property.items?.length ? haveEmptyNameProperty(property.items) : false;

      default:
        return !property.name.trim();
    }
    /* eslint-enable no-fallthrough */
  });

export const parseSectionData = (sections: TemplateSection[]): TemplateSection[] =>
  sections.map((section) => ({
    ...section,
    properties: section.properties.map(({ options, ...property }) => {
      if (property.type === PropertyType.Checkbox || property.type === PropertyType.Radio) {
        const newOptions = options?.map((op) => op.trim()) || [];

        const selectedValues = (property.value ?? '').split(',').map((index) => newOptions[index]);
        const uniqOptions = uniq(newOptions);

        return {
          ...property,
          options: uniqOptions,
          value: uniq(selectedValues)
            .map((val) => uniqOptions.findIndex((el) => el === val))
            .join(','),
        };
      }

      return { options, ...property };
    }),
  }));

export const useTemplateConfig = (type?: TemplateType) => {
  const params = useParams<{ type: TemplateType }>();

  return TEMPLATE_CONFIG[type || params.type || 'DEDSystem'];
};
