import type { Template, TemplateType } from './type';

export const TEMPLATE_CONFIG: Record<
  TemplateType,
  {
    title: string;
    navbar: string;
    addTitle: string;
    label: string;
    processTitle: string;
  }
> = {
  DEDSystem: {
    navbar: 'DED System',
    title: 'DED System Models',
    addTitle: 'Add DED System Model',
    processTitle: 'DED System Model',
    label: 'Model',
  },
  Powder: {
    navbar: 'Powder',
    title: 'Powders',
    addTitle: 'Add Powder',
    processTitle: 'Powder',
    label: 'Powder',
  },
  Process: {
    navbar: 'Additional Process',
    title: 'Additional Processes',
    addTitle: 'Add Additional Process',
    processTitle: 'Additional Process',
    label: 'Template',
  },
};

export const newPropertyPrefix = 'new-property-';
export const newSectionPrefix = 'new-section-';

export const templateSectionId = 'template';

export const DEFAULT_TITLE = 'Unnamed';
export const DEFAULT_OPTION_NAME = 'Option';

export const defaultTemplate: Template = {
  id: '',
  name: '',
  sections: [],
  subId: '',
};

export const LINKED_POWDER_INDEX = -99;
