import styled from 'styled-components';

import { ContentWrapper, PageContent, PageHeader } from 'components/layout';
import { Row, Typography } from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';

import { SectionAttributeCard, TemplateSectionMenu } from '../components';
import { ContentHeader } from '../components/ContentHeader';
import { useInitTemplateController } from '../core';

export const EditTemplatePage = () => {
  const {
    config: { navbar },
    name,
  } = useInitTemplateController();
  const isAdmin = useIsAdmin();

  return (
    <>
      <PageHeader title={navbar} />
      <PageContent>
        <ContentWrapper>
          <ContentHeader title={name} style={{}}>
            {isAdmin ? (
              <Typography color="#8C8C8C" style={{ marginTop: 4 }}>
                Read-only fields will prevent non-admin users from editing them and will not be
                visible in the DED process and build.
              </Typography>
            ) : undefined}
          </ContentHeader>

          <Row>
            <TemplateSectionMenu />

            <SectionAttributeCard />
          </Row>
        </ContentWrapper>
      </PageContent>
    </>
  );
};
