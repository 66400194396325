import {
  Modal,
  Typography,
  closeModal,
  openModal,
  showToastMessage,
  useDialogStore,
} from 'enable-ui';

import { useOrgId } from 'modules/organization/core/store';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

export const ResetPasswordModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.MEM_RESET]);
  const curOrg = useOrgId();

  const resetPassword = async () => {
    if (!curOrg || !modalProps?.userId) return;

    const result = await apiCall({
      url: modalProps?.isAdminTable
        ? `/admin/${modalProps?.userId}/reset-password`
        : `/organizations/${curOrg}/users/${modalProps?.userId}/reset-password`,
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result) {
      closeModal(MODAL_KEY.MEM_RESET);
      showToastMessage('success', 'Reset successfully!');
      openModal(MODAL_KEY.NEW_PASSWORD, { account: result });
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.MEM_RESET}
      header="Reset password"
      submitButton={{
        label: 'Reset',
        buttonProps: { onClick: resetPassword, color: 'primary' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        System will generate a random new password for this account and send it to account's email.
      </Typography>
    </Modal>
  );
};
