import { CollapsibleContent } from 'components/CollapsibleContent';
import { CustomTypo } from 'components/CustomTypography';
import { Button, IconClose, Row, openModal } from 'enable-ui';

import { TemplateSelectInput } from 'modules/project/components/ded-process/TemplateSelectInput';
import { getVariantName } from 'modules/project/core/helpers';
import { useProcessStore } from 'modules/project/core/store';
import {
  LINKED_POWDER_INDEX,
  PropertyType,
  type Template,
  type TemplateSection,
  type TemplateSectionProperty,
  defaultTemplate,
} from 'modules/template/core';
import { useTemplatePropertyController } from 'modules/template/core/controller/template-property.controller';
import { useTemplatePropertyStore } from 'modules/template/core/controller/template-property.store';

import { SectionBlockWrapper } from './styles';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-thick.svg';

import type { EditableFieldPermission } from './type';

import { MODAL_KEY } from 'constants/modal-key';

import { EditableField } from './EditableField';

interface Props {
  templateIndex: number;
  templateId: string;

  originSections: TemplateSection[];
  powderTemplates: Template[];
  uploadTargetId: string;
  submitSuccessCallback?: any;

  options: {
    collapsible?: boolean;
    variantModifiable?: boolean;

    propertyValueEditable?: boolean;
    propertyNameEditable?: boolean;
    propertyRemovable?: boolean;
    propertyModifiable?: boolean;
    propertyModifiableCustomOnly?: boolean;
  };
}

export const TemplatePropertyCard = ({
  templateIndex,
  templateId,
  originSections,
  uploadTargetId,
  options,
  submitSuccessCallback,
  powderTemplates,
}: Props) => {
  const { disabled, sectionOrder, onSubmitChange, sections } = useTemplatePropertyController(
    templateIndex,
    templateId,
    originSections,
    uploadTargetId,
    submitSuccessCallback,
  );
  const {
    addProperty,
    addSectionVariant,
    checkPropertyEmptyNameByIndex,
    removePropertyByIndex,
    removeSectionVariant,
    setPropertyState,
    toggleSectionCollapse,
    setLinkPowders,
  } = useTemplatePropertyStore.getState();

  const {
    collapsible,

    propertyModifiable,
    propertyNameEditable,
    propertyRemovable,
    propertyModifiableCustomOnly,
    propertyValueEditable,

    variantModifiable,
  } = options;

  const fieldPermission: EditableFieldPermission = {
    titleEditable: propertyNameEditable,
    customFieldModable: propertyModifiableCustomOnly,
    valueEditable: propertyValueEditable,
    fieldRemovable: propertyRemovable,
    showHiddenField: false, // To-do add prop to customizable (when refactoring template)
    showDisabledField: false, // To-do add prop to customizable (when refactoring template)
  };

  const renderProperty =
    (sectionIndex: number) => (property: TemplateSectionProperty, propertyIndex: number) => {
      if (property.type === PropertyType.Widget) {
        return (
          <article key={propertyIndex}>
            <EditableField
              {...property}
              permission={fieldPermission}
              key={property.id}
              dependencies={[templateId]}
              onChange={(key, value) =>
                setPropertyState(templateIndex, {
                  propertyIndex,
                  sectionIndex,
                  key,
                  value,
                })
              }
              onRemove={() => {
                openModal(MODAL_KEY.FIELD_REMOVE, {
                  callback: () =>
                    removePropertyByIndex(templateIndex, {
                      propertyIndex,
                      sectionIndex,
                    }),
                });
              }}
            />
            {property.items?.map((propertyItem, propertyItemIndex) => (
              <EditableField
                {...propertyItem}
                permission={fieldPermission}
                key={propertyItem.id}
                dependencies={[templateId, propertyItem.timestamp]}
                onChange={(key, value) =>
                  setPropertyState(templateIndex, {
                    propertyIndex,
                    itemIndex: propertyItemIndex,
                    sectionIndex,
                    key,
                    value,
                  })
                }
                onRemove={() => {
                  openModal(MODAL_KEY.FIELD_REMOVE, {
                    callback: () =>
                      removePropertyByIndex(templateIndex, {
                        propertyIndex,
                        sectionIndex,
                        itemIndex: propertyItemIndex,
                      }),
                  });
                }}
                onBlur={(index) => {
                  checkPropertyEmptyNameByIndex(templateIndex, {
                    originSection: originSections[sectionIndex],
                    propertyIndex,
                    sectionIndex,
                    itemIndex: propertyItemIndex,
                    ...index,
                  });
                }}
              />
            ))}
          </article>
        );
      }

      return (
        <EditableField
          {...property}
          permission={fieldPermission}
          key={property.id}
          dependencies={[templateId, property.timestamp]}
          onChange={(key, value, optionIndex) =>
            setPropertyState(templateIndex, {
              propertyIndex,
              sectionIndex,
              optionIndex,
              key,
              value,
            })
          }
          onRemove={(index) => {
            if (index) {
              removePropertyByIndex(templateIndex, {
                propertyIndex,
                sectionIndex,
                ...index,
              });
            } else {
              openModal(MODAL_KEY.FIELD_REMOVE, {
                callback: () =>
                  removePropertyByIndex(templateIndex, {
                    propertyIndex,
                    sectionIndex,
                  }),
              });
            }
          }}
          onAddSubValue={() => {
            addProperty(templateIndex, sectionIndex, propertyIndex);
          }}
          onBlur={(index) => {
            checkPropertyEmptyNameByIndex(templateIndex, {
              originSection: originSections[sectionIndex],
              propertyIndex,
              sectionIndex,
              ...index,
            });
          }}
        />
      );
    };

  return (
    <SectionBlockWrapper>
      {sections.map((sectionBlock, sectionIndex) => {
        // const groupSections = sectionOrder[sectionBlock.masterId];
        // const order =
        //   groupSections.length > 2
        //     ? sectionOrder[sectionBlock.masterId].findIndex((el) => el.id === sectionBlock.id) + 1
        //     : '';
        const templatesByType = useProcessStore.getState().state.templates;
        const filterPowderTemplates = powderTemplates.filter((el) =>
          templatesByType['Powder'].find((e) => e.id === el.masterId),
        );

        const selectedTemplates = filterPowderTemplates
          .filter((el) => sectionBlock.powderIds?.includes(el.id))
          .map((el) => ({
            label: el.name,
            value: el,
          }));

        const linkTemplateOptions = selectedTemplates.length
          ? filterPowderTemplates
          : filterPowderTemplates.concat([defaultTemplate]);

        return (
          <section key={sectionBlock.id}>
            <CustomTypo
              variant="h3"
              weight={500}
              className="h3-5-medium text-ellipsis-1"
              style={{ maxWidth: 'calc(100% - 26px)' }}
            >
              {sectionBlock.name}
            </CustomTypo>

            {variantModifiable && sectionIndex > 0 && (
              <Button
                icon={IconClose}
                placementIcon="left"
                className="section-rm-btn"
                variant="text"
                onClick={() => {
                  openModal(MODAL_KEY.FIELD_REMOVE, {
                    callback: () => removeSectionVariant(templateIndex, sectionIndex),
                  });
                }}
              />
            )}

            <CollapsibleContent
              collapsed={collapsible ? sectionBlock.expand : false}
              dependency={JSON.stringify(sectionBlock)}
            >
              {collapsible && (
                <Button
                  icon={
                    <ArrowIcon
                      style={{
                        transform: sectionBlock.expand ? undefined : 'rotate(180deg)',
                      }}
                    />
                  }
                  placementIcon="left"
                  className="section-rm-btn"
                  variant="text"
                  size="small"
                  style={{
                    right: 0,
                  }}
                  onClick={() => {
                    toggleSectionCollapse(templateIndex, sectionIndex);
                  }}
                />
              )}

              {sectionBlock.multipleSelected ? (
                <div style={{ paddingBottom: 16 }}>
                  <CustomTypo variant={'body-1'} weight={500} style={{ marginBottom: 6 }}>
                    Linked Powder
                  </CustomTypo>

                  <TemplateSelectInput
                    templates={linkTemplateOptions}
                    onChange={(val: any) => {
                      setLinkPowders(
                        templateIndex,
                        sectionIndex,
                        val.map((el) => el.value?.id || ''),
                      );
                    }}
                    value={selectedTemplates}
                    containerStyle={{ marginBottom: 8 }}
                    isClearable={false}
                    hideSelectedOptions={false}
                    showCheckbox
                    isMulti
                    closeMenuOnSelect={false}
                    customPlaceholder={!filterPowderTemplates.length ? 'Not found' : 'No powder'}
                    isDisabled={!propertyModifiable}
                    onBlur={() => {
                      checkPropertyEmptyNameByIndex(templateIndex, {
                        originSection: originSections[sectionIndex],
                        sectionIndex,
                        propertyIndex: LINKED_POWDER_INDEX,
                      });
                    }}
                  />
                </div>
              ) : null}

              {sectionBlock.properties.map(renderProperty(sectionIndex))}

              {propertyModifiable ? (
                <Button
                  variant="text"
                  color="primary"
                  icon={<PlusIcon />}
                  placementIcon="left"
                  style={{ height: 32, gap: 6 }}
                  onClick={() => addProperty(templateIndex, sectionIndex)}
                >
                  Add text field
                </Button>
              ) : null}
            </CollapsibleContent>
          </section>
        );
      })}

      {propertyValueEditable || propertyModifiable ? (
        <Row style={{ gap: 8, marginTop: 40 }}>
          <Button color="primary" size="large" onClick={onSubmitChange} disabled={disabled}>
            Save
          </Button>

          {variantModifiable ? (
            <Button
              size="large"
              placementIcon="left"
              icon={<PlusIcon />}
              onClick={() => addSectionVariant(templateIndex, originSections[0])}
            >
              Add {sections[0]?.name || ''}
            </Button>
          ) : null}
        </Row>
      ) : null}
    </SectionBlockWrapper>
  );
};
