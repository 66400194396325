import { type FieldValues, type Path, type RegisterOptions, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import type { InputColor } from 'enable-ui';

import type { FormManagerProps } from './type';

export function useFormManager<T extends FieldValues>({
  schema,
  onSubmit,
  ...props
}: FormManagerProps<T>) {
  const form = useForm<T>({
    resolver: schema ? yupResolver(schema) : undefined,
    mode: 'onChange',
    reValidateMode: 'onChange',
    // To-do add
    ...props,
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  // const SmartForm = ({ children }) => (
  //   <SmartForm register={form.register} handleSubmit={handleSubmit} children={children} />
  // );

  const registerError = (name: Path<T>) => ({
    ...getInputStatus(form.formState.errors?.[name]?.message),
  });

  const disabled =
    form.formState.disabled ||
    form.formState.isSubmitting ||
    form.formState.isLoading ||
    !form.formState.isValid;

  return {
    form: {
      ...form,
      register: (name: Path<T>, options?: RegisterOptions<T, Path<T>>) => {
        return {
          ...form.register(name, options),
          inputRef: form.register(name, options).ref,
        };
      },
    },
    handleSubmit,
    registerError,
    disabled,
  };
}

const getInputStatus = (messageError?: any) => {
  const result: { color: InputColor; helperText: string } = {
    color: 'default',
    helperText: '',
  };

  if (messageError) {
    result.color = 'error';
    result.helperText = messageError;
  }

  return result;
};
