import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Modal, TextInput, closeModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { primary } from 'styles/theme';

import type { Organization } from '../../core/type';

import { mappingPath } from 'helpers';
import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { addOrgState, useOrgStore } from '../../core/store';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const AddOrgModal = () => {
  const firstCreate = useOrgStore((store) => store.state.orgs.length === 0);
  const navigate = useNavigate();

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async (values) => {
      const result = await apiCall<any, Organization>({
        url: `/organizations`,
        data: values,
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.ORG_ADD);
        addOrgState(result);
        navigate(mappingPath(PATH.MEMBERS, [result.id]));
        showToastMessage('success', 'Added successfully!');
      }
    },
  });

  return (
    <ModalWrapper>
      <Modal
        modalKey={MODAL_KEY.ORG_ADD}
        header="What do you want to name the organization?"
        cancelButton={{ hide: true }}
        submitButton={{
          label: 'Create Organization',
          buttonProps: { onClick: handleSubmit, disabled: !isValid },
        }}
        closeOnClickOutside={false}
        hideCloseIcon={firstCreate}
      >
        <TextInput
          title="Organization name"
          {...register('name')}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </Modal>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .es-modal-wrapper {
    background-color: ${primary['primary-1']};
  }
  .es-modal {
    width: 640px;
  }
  .es-modal-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    min-height: 56px;
  }
  .es-modal-body {
    padding-top: 24px;
  }
  .es-modal-footer {
    justify-content: start;
    button {
      height: 44px;
      width: 183px;
    }
  }
`;
