import type { DeepPartial } from 'enable-ui';

import type { ProjectBuild } from '../type';

import { merge } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { BUILD_INFO_MENU_ITEMS, defaultDedProcess } from '../constant';

interface State {
  build: ProjectBuild;
  selectedIndex: string;
  selectingIds: string[];
  openAddPropDropdown?: boolean;
}

interface Store {
  state: State;

  setState: (payload: Partial<State>) => void;
  mergeState: (payload: DeepPartial<State>) => void;
  clearState: () => void;
}

const defaultBuild: ProjectBuild = {
  id: '',
  name: '',
  subId: '',
  updatedAt: '',
  process: defaultDedProcess,
};

const initState: State = {
  build: defaultBuild,
  selectedIndex: BUILD_INFO_MENU_ITEMS[0].key,
  selectingIds: [],
};

export const useBuildStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    mergeState: (payload) => {
      set((store) => {
        store.state = merge(store.state, payload);
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);
