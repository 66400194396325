import { useNavigate, useParams } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { TableLoadingRow, TableWrapper } from 'components/TableWrapper';
import {
  Button,
  Row,
  Table,
  TextInput,
  Typography,
  neutral,
  neutralTrans,
  updateQueryStateTable,
  useTableStore,
} from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as NoTemplate } from 'assets/icons/template.svg';

import { mappingPath } from 'helpers';
import { useTableManager } from 'hooks/table';

import { PATH } from 'constants/path';

import { type TemplateType, useTemplateConfig } from '../core';
import { templateColumns } from '../core/columns';

const tableKey = 'templates';

export const TemplatesManager = () => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const queryState = useTableStore((s) => s.state.queryState[tableKey]);

  const { title, label } = useTemplateConfig();
  const { orgId, type } = useParams<{ orgId: string; type: TemplateType }>();

  const fetchDataUrl = orgId ? `/organizations/${orgId}/templates` : '';

  const {
    rowItems,
    hasNoData,
    isLoading: { value: isLoading },
    isFirstLoad,
    searchValue,
    total,

    onSearchChange,
    onDeleteCallback,
  } = useTableManager(tableKey, fetchDataUrl, {
    params: { type },
    dependencies: [orgId, type],
  });

  const renderContent = () => {
    if (isFirstLoad) {
      return TableLoadingRow;
    }

    if (!isLoading && !rowItems.length) {
      return queryState?.search ? (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Search className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            There is no result matching your search
          </Typography>
        </Row>
      ) : (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <NoTemplate className="empty-data-icon" />

          <Typography variant="body-1" color={neutral['neutral-7']} style={{ marginBottom: 24 }}>
            There is no {label.toLowerCase()} yet.
          </Typography>

          {!isAdmin ? null : (
            <Button
              variant="contained"
              color="primary"
              icon={<Plus />}
              placementIcon="left"
              onClick={() => {
                navigate(mappingPath(PATH.TEMPLATE_ADD, [orgId || '', type || '']));
              }}
            >
              Add
            </Button>
          )}
        </Row>
      );
    }

    return (
      <>
        <Table
          dataSource={isLoading ? [] : rowItems}
          columns={templateColumns(label, onDeleteCallback, isAdmin)}
          queryState={queryState}
          updateQueryState={updateQueryStateTable}
          tableKey={tableKey}
          total={total}
          paginationConfigs={{ showRowsPerPage: true, showTotal: true, show: !isLoading }}
          headerConfigs={{
            styledTr: {
              backgroundColor: 'transparent',
              height: 48,
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
            },
          }}
          onClickRow={(row) => {
            navigate(
              mappingPath(PATH.TEMPLATE_EDIT, [orgId || '', type || '', row?.original?.id || '']),
            );
          }}
          bodyConfigs={{ isSameRowBg: true }}
        />

        {isLoading ? TableLoadingRow : null}
      </>
    );
  };

  return (
    <TableWrapper className={isLoading || !rowItems.length ? 'dt-empty' : ''}>
      <Row style={{ padding: 24 }} justify="space-between" align="center">
        <CustomTypo variant="h3" weight={500}>
          {title}
        </CustomTypo>
        {hasNoData || !isAdmin ? null : (
          <Button
            variant="contained"
            color="primary"
            icon={<Plus />}
            placementIcon="left"
            onClick={() => {
              navigate(mappingPath(PATH.TEMPLATE_ADD, [orgId || '', type || '']));
            }}
          >
            Add
          </Button>
        )}
      </Row>

      {!hasNoData && (
        <Row style={{ padding: '0px 24px' }} justify="start" align="center">
          <TextInput
            size="default"
            containerStyle={{ width: 320 }}
            prefix={<Search />}
            placeholder="Search with ID or name"
            onChange={onSearchChange}
            value={searchValue}
          />
        </Row>
      )}

      {renderContent()}
    </TableWrapper>
  );
};
