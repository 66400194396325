import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Row, Typography, neutral } from 'enable-ui';

import { ReactComponent as ProcessIcon } from 'assets/icons/map.svg';

import type { DefaultDedProcess } from '../../core';

export const ProcessInfo = ({
  process,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { process?: DefaultDedProcess }) => {
  return (
    <Wrapper {...props}>
      <span className={process ? 'active' : ''}>
        <ProcessIcon />
      </span>
      {process ? (
        <div>
          <CustomTypo variant="body-2" style={{ color: '#8C8C8C' }}>
            {process.subId}
          </CustomTypo>
          <CustomTypo>{process.name}</CustomTypo>
        </div>
      ) : (
        <Typography color={neutral['neutral-7']}>Select process</Typography>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  align-items: center;
  > span {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-6, 6px);
    margin-right: 10px;
    border: 1px solid var(--border-neutral-subtle-default, rgba(0, 0, 0, 0.1));
    background: var(--surface-neutral-neutral-default, #f0f0f0);

    &.active {
      border: 1px solid var(--golden-purple-3, #ffd666);
      background: var(--surface-accent-subtle-purple, #fff4de);

      svg path {
        stroke: #d48806;
        stroke-width: 1.25;
      }
    }
  }
`;
