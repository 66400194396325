import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { useProjectId } from '../../core';

export const DeleteProcessBuildModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROCESS_BUILD_DELETE]);

  const { isBuild, id, callback } = modalProps || {};

  const type = isBuild ? 'builds' : 'processes';
  const header = isBuild ? 'Delete this Build' : 'Delete this DED Process';
  const description = isBuild
    ? 'Deleted data cannot be recovered.'
    : 'Deleted data cannot be recovered. The Builds linking to this DED Process will remain the same.';

  const onDelete = async () => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/${type}/${id}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      closeModal(MODAL_KEY.PROCESS_BUILD_DELETE);
      callback?.();
      showToastMessage('success', 'Deleted successfully!');
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PROCESS_BUILD_DELETE}
      header={header}
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: onDelete, color: 'danger' },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>{description}</Typography>
    </Modal>
  );
};
