import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { openModal, showToastMessage } from 'enable-ui';

import { type UserProfile, useAuthStore, useIsAdmin, userProfileSelector } from 'modules/auth/core';
import { useOrgId } from 'modules/organization/core';

import type { DefaultDedProcess, Project, ProjectRole } from '../type';

import { apiCall } from 'hooks/service';
import { includes, isNil } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { ProcessStatus, type ProjectMember, useProjectMembers, useProjectStore } from '../../core';
import { setProjectState, useIsProjectMember, useIsProjectOwner } from '../store/basic.store';

export const useInitProject = () => {
  const orgId = useOrgId();
  const match = useMatch('/org/:orgId/projects/:projectId/*');

  useEffect(() => {
    if (!orgId) return;

    const fetchList = async () => {
      const result = await apiCall<any, { count: number; items: Project[] }>({
        method: 'GET',
        url: `/organizations/${orgId}/projects`,
        isLoading: true,
        config: {
          params: {
            page: 1,
            limit: 999,
            order_by: 'createdAt',
            order_direction: 'asc',
          },
        },
      });

      if (result.items) {
        setProjectState({
          projects: result.items,
          curProject: match?.params?.projectId
            ? result.items.find((el) => el.id === match?.params?.projectId)
            : result.items[0],
        });
      }
    };

    fetchList();
  }, [orgId]);

  return null;
};

export const useProjectMemberModalController = () => {
  const projMembers = useProjectMembers();

  const curProject = useProjectStore((s) => s.state.curProject);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const orgId = useOrgId();
  const [users, setUsers] = useState<UserProfile[] | null>(null);
  const userProfile = useAuthStore(userProfileSelector);

  const { getProjMembers } = useFetchProjectMembers();

  useEffect(() => {
    if (curProject?.id && orgId) {
      getUsers();
    }

    return () => {
      setUsers(null);
    };
  }, [curProject?.id, orgId]);

  const userOptions = users
    ?.filter(
      (el) =>
        !includes(
          projMembers?.map((val) => val?.member?.id),
          el.id,
        ),
    )
    ?.map((el) => {
      return { label: el.fullName, value: el };
    });

  const getUsers = async () => {
    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/users`,
      method: 'GET',
      data: {
        page: 1,
        limit: 999,
        order_by: 'fullName',
        order_direction: 'asc',
      },
    });

    if (result?.items) {
      setUsers(result?.items);
    }
  };

  const addMember = async () => {
    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/projects/${curProject?.id}/members`,
      method: 'POST',
      data: {
        memberIds: [selectedUser?.id || ''],
      },
      showError: true,
    });

    if (result) {
      getProjMembers();
      showToastMessage('success', 'Added successfully!');
      setSelectedUser(null);
    }
  };

  const updateProjectRole = (member: ProjectMember, role: ProjectRole) => async () => {
    openModal(MODAL_KEY.PROJECT_CHANGE_OWNER, { member, role });
  };

  const removeMember = (member?: ProjectMember) => async () => {
    openModal(MODAL_KEY.PROJECT_REMOVE_MEMBER, { member });
  };

  const projOwner = projMembers?.find((el) => el?.role === 'Owner');
  const isOwner = userProfile?.id === projOwner?.member?.id;
  const isAdmin = useIsAdmin();

  return {
    isAdmin,
    isOwner,
    projOwner,
    userOptions,
    selectedUser,
    setSelectedUser,
    addMember,
    removeMember,
    projMembers,
    updateProjectRole,
  };
};

export const useFetchProjectMembers = () => {
  const curProject = useProjectStore((s) => s.state.curProject);
  const orgId = useOrgId();

  const getProjMembers = async () => {
    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/projects/${curProject?.id}/members`,
      method: 'GET',
      data: {
        page: 1,
        limit: 999,
        order_by: 'createdAt',
        order_direction: 'asc',
      },
      isLoading: true,
      showError: true,
    });

    if (result?.items) {
      setProjectState({ members: result.items?.filter((el) => !isNil(el.member)) });
    }
  };

  return { getProjMembers };
};

export const useProjectPermission = () => {
  const userId = useAuthStore((s) => s.state.userProfile?.id);
  const isMember = useIsProjectMember(userId);
  const isOwner = useIsProjectOwner(userId);
  const isAdmin = useIsAdmin();

  return {
    isMember: isAdmin || isMember,
    isOwner: isAdmin || isOwner,
  };
};

export const useFetchProcesses = () => {
  const match = useMatch('/org/:orgId/projects/:projectId/*');
  const orgId = match?.params?.orgId || '';
  const projectId = match?.params?.projectId || '';

  const fetchApi = async () => {
    const result = await apiCall<any, { items: DefaultDedProcess[] }>({
      url: `/organizations/${orgId}/projects/${projectId}/processes`,
      method: 'GET',
      data: {
        status: ProcessStatus.Publish,
        page: 1,
        limit: 999,
        order_by: 'createdAt',
        order_direction: 'asc',
      },
      isLoading: true,
      showError: true,
    });

    if (result?.items) {
      useProjectStore.getState().setState({
        dedProcesses: result.items,
      });
    }
  };

  return fetchApi;
};
