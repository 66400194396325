import styled from 'styled-components';

import { CardWrapper } from 'components/layout';
import { Row } from 'enable-ui';

export const ContentEditable = styled.span.attrs(() => ({
  onClick: (event) => event.stopPropagation(),
}))`
  width: fit-content;
  display: block;
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: text;
  user-select: none;
  &:focus-visible {
    outline: none;
    border-radius: var(--radius-small, 4px);
    background: var(--surface-neutral-subtlest-hover, #f0f0f0);
    padding-right: 4px;
  }
`;

export const EditableOptionItem = styled(Row)`
  width: fit-content;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  align-items: center;
  span {
    margin-left: 2px;
    padding-right: 4px;
  }
  .option-item-rm {
    width: fit-content;
    height: fit-content;
    margin-left: 2px;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: #8c8c8c;
      }
    }
  }
`;

export const SectionBlockWrapper = styled(CardWrapper)`
  width: 640px;
  padding: 12px 24px 24px;
  margin: 0 0 0 12px;
  p[variant='h3'] {
    margin-bottom: 24px;
  }
  article {
    border-radius: 8px;
    border: 1.5px solid var(--border-neutral-default, rgba(0, 0, 0, 0.15));
    padding: 12px;
    margin-bottom: 16px;

    > div:last-child .editable-field {
      margin-bottom: 0;
    }
  }
  section {
    position: relative;
    padding-top: 12px;
    &:not(:first-child) {
      border-top: 1px solid var(--border-neutral-default, rgba(0, 0, 0, 0.15));
      margin-top: 40px;
    }
  }
  .section-rm-btn {
    position: absolute;
    top: 11px;
    right: -12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  [class$='multiValue'] > div,
  [class$='-MenuList'] .es-check-box-wrapper {
    padding: 0;
  }
`;
