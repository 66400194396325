import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { openModal, showToastMessage } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';
import type { TemplateSection, TemplateType } from 'modules/template/core';

import { mappingPath } from 'helpers';
import { apiCall } from 'hooks/service';
import { countBy, flatMap, some } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { fetchTemplates } from '../helpers';
import {
  clearProcessState,
  selectProcessTemplateByType,
  setProcessSectionsByType,
  setTemplatesByType,
  useProcessStore,
  useSelectedVariants,
} from '../store';
import { useProjectId } from '../store/basic.store';

export const useAddProcessController = () => {
  const orgId = useOrgId();
  const projId = useProjectId();
  const navigate = useNavigate();

  const processName = useProcessStore((s) => s.state.processName);
  const dEDSystemTemplateId = useProcessStore((s) => s.state.DEDSystem.selectedId);
  const processTemplateId = useProcessStore((s) => s.state.Process.selectedId);

  const selectedVariants = useSelectedVariants();
  const dedSelectedVariants = useProcessStore((s) => s.state.DEDSystem.selectedVariants);

  const powderFeederExisted = useProcessStore((s) =>
    some(
      flatMap(s.state.DEDSystem.sections),
      (el) => el?.multipleSelected || el?.name === 'Powder feeder',
    ),
  );

  const hasNoPowderFeeder =
    Boolean(dEDSystemTemplateId) && selectedVariants?.findIndex((el) => el?.multipleSelected) < 0;
  const powderFeederCount = useProcessStore(
    (s) => countBy(s.state.DEDSystem.selectedVariants, (el) => el.multipleSelected).true || 1,
  );

  const selectedTemplateByPowder = useProcessStore((s) =>
    s.state.Powder.map((el) => el.selectedId).filter((el) => el),
  );

  const isMissingPowderFeeder =
    powderFeederExisted && dedSelectedVariants.length !== 0 && hasNoPowderFeeder;

  const disabled =
    !processName ||
    !processTemplateId ||
    !dEDSystemTemplateId ||
    !selectedTemplateByPowder.length ||
    isMissingPowderFeeder;

  useEffect(() => {
    const fetchTemplateByType = async (type: TemplateType) => {
      const templates = await fetchTemplates(orgId, type);

      if (templates?.items) {
        setTemplatesByType(type, templates.items);
      }
    };

    fetchTemplateByType('DEDSystem');
    fetchTemplateByType('Powder');
    fetchTemplateByType('Process');

    return () => {
      clearProcessState();
    };
  }, []);

  const fetchSectionByType = async (type: TemplateType, id: string, index: number = 0) => {
    const result = await apiCall<any, { sections: TemplateSection[] }>({
      url: `/organizations/${orgId}/templates/${id}`,
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result.sections) {
      setProcessSectionsByType(type, result.sections, index);
    }
  };

  const onSubmit = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes`,
      method: 'POST',
      data: {
        name: processName,
        dedSystemTemplateId: dEDSystemTemplateId,
        powderTemplateIds: selectedTemplateByPowder,
        processTemplateId: processTemplateId,
        selectedVariantIds: selectedVariants.map((el) => el.id) || [],
      },
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      showToastMessage('success', 'Added successfully!');
      navigate(mappingPath(PATH.PROJECT_DED_PROCESS_EDIT, [orgId, projId, result.id]));

      if (powderFeederExisted) {
        openModal(MODAL_KEY.PROCESS_LINK_POWDER);
      }
    }
  };

  const onSelectTemplateByType = async (
    type: TemplateType,
    templateId?: string,
    index: number = 0,
  ) => {
    selectProcessTemplateByType(type, templateId || '', index);

    if (templateId) {
      await fetchSectionByType(type, templateId, index);
    }
  };

  return {
    hasNoPowderFeeder,
    disabled,
    isMissingPowderFeeder,
    onSubmit,

    powderFeederCount,
    onSelectTemplateByType,
    selectedTemplateByPowder,
  };
};
