import { type DefaultDedProcess, type Project, type ProjectMember, ProjectRole } from '../type';

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  projects: Project[];
  members?: ProjectMember[];
  curProject?: Project;

  dedProcesses: DefaultDedProcess[];
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
  renameProjectState: (payload: Project) => void;
  addProjectState: (payload: Project) => void;
  removeProjectState: (payload: Project) => void;
}

const initState: State = {
  projects: [],
  members: [],
  dedProcesses: [],
};

export const useProjectStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    renameProjectState: (payload) => {
      set((store) => {
        store.state.curProject = payload;
        store.state.projects = store.state.projects.map((org) =>
          org.id === payload.id ? payload : org,
        );
      });
    },
    addProjectState: (payload) => {
      set((store) => {
        store.state.curProject = payload;
        store.state.projects.push(payload);
      });
    },
    removeProjectState: (payload) => {
      set((store) => {
        store.state.projects = store.state.projects.filter((el) => el.id !== payload.id);
        store.state.curProject = store.state.projects[0];
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const {
  setState: setProjectState,
  clearState: clearProjectState,
  renameProjectState,
  addProjectState,
  removeProjectState,
} = useProjectStore.getState();

export const useProjectName = () => useProjectStore((s) => s.state.curProject?.name || '');
export const useProjectId = () => useProjectStore((s) => s.state.curProject?.id || '');
export const useProjectMembers = () => useProjectStore((s) => s.state.members || null);

export const useIsProjectMember = (id: string) =>
  useProjectStore((s) => s.state.members?.some((el) => el?.member?.id === id) || false);

export const useIsProjectOwner = (id: string) =>
  useProjectStore(
    (s) =>
      s.state.members?.some((el) => el?.role === ProjectRole.Owner && el?.member?.id === id) ||
      false,
  );
