import * as Yup from 'yup';

Yup.setLocale({
  string: {
    // email: (props => {
    //   console.log(props;
    // },
    email: '${label} is invalid',
  },
});

export default Yup;
