import { type Props, components as ReactSelectComponent } from 'react-select';

import { CustomSelect, type CustomSelectProps } from 'components/CustomSelect';
import { CustomTypo } from 'components/CustomTypography';
import { Checkbox } from 'enable-ui';

import { TemplateInfo } from 'modules/project/components/TemplateInfo';
import type { DefaultTemplate } from 'modules/template/core';

import { ReactComponent as Close } from 'assets/icons/x.svg';

export function TemplateSelectInput({
  templates,
  customPlaceholder,
  showCheckbox,
  ...props
}: CustomSelectProps &
  Props<{ value: DefaultTemplate }> & { templates: DefaultTemplate[]; showCheckbox?: boolean } & {
    customPlaceholder?: string;
  }) {
  return (
    <CustomSelect
      isSearchable={false}
      options={templates.map((el) => ({
        label: el.name,
        value: el,
      }))}
      components={{
        Option: (compoProps) => {
          // console.log('compoProps', compoProps);

          if (!compoProps.data.value.id) {
            return (
              <CustomTypo variant={'body-1'} style={{ color: '#DA291C', padding: '8px 14px' }}>
                A Powder feeder must link with at least 1 Powder.
              </CustomTypo>
            );
          }

          return (
            <ReactSelectComponent.Option {...compoProps}>
              {showCheckbox ? (
                <Checkbox checked={compoProps.isSelected}>
                  <TemplateInfo template={compoProps.data.value} style={{ marginLeft: 4 }} />
                </Checkbox>
              ) : (
                <TemplateInfo template={compoProps.data.value} />
              )}
            </ReactSelectComponent.Option>
          );
        },
        Placeholder: (compoProps) => (
          <ReactSelectComponent.Placeholder {...compoProps}>
            <TemplateInfo
              style={{ padding: '10px 2px' }}
              placeholder={customPlaceholder || ''}
              typoProps={{ color: '#8c8c8c' }}
            />
          </ReactSelectComponent.Placeholder>
        ),
        SingleValue: (compoProps) => {
          return (
            <ReactSelectComponent.SingleValue {...compoProps}>
              <TemplateInfo template={compoProps.data.value} style={{ padding: '8px 2px' }} />
            </ReactSelectComponent.SingleValue>
          );
        },
        MultiValue: (compoProps) => {
          return (
            <ReactSelectComponent.MultiValue {...compoProps}>
              <TemplateInfo
                template={compoProps.data.value}
                style={{ padding: 8, maxWidth: 252 }}
              />
            </ReactSelectComponent.MultiValue>
          );
        },
        MultiValueRemove: () => null,
        ClearIndicator: (compoProps) => {
          return (
            <ReactSelectComponent.ClearIndicator {...compoProps} className="clear-icon">
              <Close />
            </ReactSelectComponent.ClearIndicator>
          );
        },
      }}
      optionStyle={{
        padding: '8px 10px',
      }}
      showDropdownIcon
      menuStyle={{
        marginTop: 5.5,
      }}
      isClearable
      maxMenuHeight={160}
      valueStyle={
        props.isMulti
          ? {
              padding: (props.value as any)?.length ? 10 : '0 6px',
              gap: 8,
            }
          : undefined
      }
      styles={{
        multiValue: (base) => ({
          ...base,
          margin: 0,
          padding: 0,
          borderRadius: 8,
          border: '1px solid rgba(0, 0, 0, 0.15)',
          background: '#FAFAFA',
          minWidth: 148,
        }),
      }}
      {...props}
    />
  );
}
