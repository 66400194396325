import { useEffect, useState } from 'react';

import { debounce, throttle } from 'lodash';

export const useWindowSize = () => {
  const [size, setSize] = useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const updateSize = throttle(
    () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    },
    500,
    {
      leading: false,
      trailing: true,
    },
  );

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('orientationchange', updateSize);
    };
    // eslint-disable-next-line
  }, []);

  return size;
};

export const useWindowVh = () => {
  useEffect(() => {
    const resize = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vhi', `${vh}px`);
    };

    resize();
    const debounceResize = debounce(resize, 50);

    window.addEventListener('resize', debounceResize);

    return () => window.removeEventListener('resize', debounceResize);
  }, []);

  return null;
};

type BreakPoints = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
};

const BREAK_POINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const getBreakPoints = (): BreakPoints => ({
  xs: window.innerWidth < BREAK_POINTS['xs'],
  sm: window.innerWidth >= BREAK_POINTS['xs'],
  md: window.innerWidth >= BREAK_POINTS['sm'],
  lg: window.innerWidth >= BREAK_POINTS['md'],
  xl: window.innerWidth >= BREAK_POINTS['lg'],
  xxl: window.innerWidth >= BREAK_POINTS['xl'],
});

export const screenBreak = (bp: keyof BreakPoints) =>
  `@media only screen and (max-width: ${BREAK_POINTS[bp]}px)`;

export const useBreakpoint = () => {
  const [breakPoints, setBreakPoints] = useState<BreakPoints>(getBreakPoints());

  useEffect(() => {
    const updateSize = throttle(
      () => {
        setBreakPoints(getBreakPoints());
      },
      100,
      { leading: false, trailing: true },
    );

    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('orientationchange', updateSize);
    };
    // eslint-disable-next-line
  }, []);

  return breakPoints;
};

export const useScreen = () => {
  const { xs, sm, md, lg } = useBreakpoint();

  const isTablet = (xs || sm || md) && lg === false;
  const isMobile = xs;

  return { isTablet, isMobile };
};
