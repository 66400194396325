import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import {
  Button,
  Checkbox,
  FileCard,
  FileUpload,
  IconClose,
  Radio,
  Row,
  TextInput,
  red,
  showToastMessage,
} from 'enable-ui';

import { PropertyType } from 'modules/template/core';

import { ContentEditable } from './styles';

import { ReactComponent as LockOpenIcon } from 'assets/icons/lock-open.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-thick.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

import type { EditableFieldProps } from './type';

import { downloadFromFile, downloadLocalFile } from 'helpers';
import { useBoolean } from 'hooks';
import { compact, xor } from 'lodash';

import { FILE_SIZE_10_MB } from 'constants/general';

import { EditableCheckbox } from './EditableCheckbox';
import { EditableRadio } from './EditableRadio';

const getFieldActions = (showLocked: boolean, locked?: boolean) =>
  compact([
    showLocked
      ? {
          key: 'lock',
          label: (
            <CustomTypo variant="body-1" color={red['red-6']}>
              {locked ? 'Allow edit' : 'Read only'}
            </CustomTypo>
          ),
          prefix: locked ? <LockOpenIcon /> : <LockIcon />,
        }
      : null,
    {
      key: 'delete',
      label: (
        <CustomTypo variant="body-1" style={{ color: red['red-6'] }}>
          Delete
        </CustomTypo>
      ),
      prefix: <TrashIcon className="danger-ic" />,
    },
  ]);

export const EditableField = ({
  dependencies,
  name,
  value,
  type,
  locked,
  options,
  files,
  masterId,
  id,
  onChange,
  onRemove,
  onAddSubValue,
  onBlur,
  permission,
}: EditableFieldProps) => {
  const fileUploadRef = useRef<any>(null);
  const nameRef = useRef<HTMLSpanElement>(null);
  const visible = useBoolean();
  const {
    fieldRemovable,
    fieldLockable,

    titleEditable,
    valueEditable,
    optionEditable,

    customFieldModable,
    showDisabledField,
    showHiddenField,
  } = permission;

  const isCustomField = !masterId;
  const isWidget = type === PropertyType.Widget;
  const customFieldPermission = customFieldModable ? isCustomField : false;

  const titleEditableProp = titleEditable || customFieldPermission ? 'plaintext-only' : false;

  const isHidden = locked && showHiddenField === false;
  const disabled = locked && !isHidden && showDisabledField;

  const haveAction = fieldRemovable || fieldLockable || customFieldPermission;
  const haveInteraction = !disabled && haveAction;

  // console.log('titleEditableProp', titleEditableProp);
  // console.log('options', name, files, masterId);

  useEffect(() => {
    const uploadBtnEl = document.querySelector('.es-file-upload');

    if (uploadBtnEl) {
      uploadBtnEl.addEventListener('click', () => visible.setValue(false));
    }
  }, []);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.textContent = name;
    }

    if (fileUploadRef.current) {
      fileUploadRef.current.onRemoveFiles(files);
    }
  }, dependencies || []);

  const renderClearOptionButton = (index: number) =>
    optionEditable ? (
      <Button
        icon={IconClose}
        placementIcon="left"
        className="option-item-rm"
        variant="text"
        size="small"
        onClick={() => {
          onRemove({ optionIndex: index });
          visible.setValue(false);
        }}
      />
    ) : null;

  const AddOptionButton = optionEditable ? (
    <Button
      variant="text"
      color="primary"
      icon={<PlusIcon width={16} height={16} />}
      placementIcon="left"
      className="size-xs"
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        onAddSubValue?.();
        visible.setValue(false);
      }}
    >
      Add option
    </Button>
  ) : null;

  const renderValueFieldByType = useCallback(() => {
    /* eslint-disable no-fallthrough */
    switch (type) {
      case PropertyType.Number:
      // fall through

      case PropertyType.Date:

      case PropertyType.Text:
        return (
          <TextInput
            defaultValue={value}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              onChange('value', event.target.value);
            }}
            onFocus={() => visible.setValue(false)}
            disabled={disabled}
            containerStyle={valueEditable ? {} : { pointerEvents: 'none' }}
          />
        );

      case PropertyType.Radio: {
        return (
          <>
            {options?.map((option, index) =>
              optionEditable ? (
                <EditableRadio
                  key={option + index}
                  checked={value === String(index)}
                  onClick={() => {
                    onChange('value', String(index), index);
                    visible.setValue(false);
                  }}
                  editableContentProps={{
                    contentEditable: true,
                    onInput: (event) => {
                      onChange('name', event.currentTarget.textContent ?? '', index);
                      visible.setValue(false);
                    },
                    content: option,
                    onBlur: () => onBlur?.({ optionIndex: index }),
                    onFocus: () => visible.setValue(false),
                  }}
                  dependencies={dependencies}
                >
                  {renderClearOptionButton(index)}
                </EditableRadio>
              ) : (
                <Radio
                  key={option + index}
                  checked={value === String(index)}
                  disabled={disabled}
                  onClick={() => {
                    if (disabled) return;

                    onChange('value', String(index), index);
                    visible.setValue(false);
                  }}
                  style={valueEditable ? {} : { pointerEvents: 'none' }}
                >
                  {option}
                </Radio>
              ),
            )}
            {AddOptionButton}
          </>
        );
      }

      case PropertyType.Checkbox: {
        const values = value?.split(',') || [];

        return (
          <>
            {options?.map((option, index) =>
              optionEditable ? (
                <EditableCheckbox
                  key={option + index}
                  checked={values.includes(String(index))}
                  onClick={() =>
                    valueEditable &&
                    onChange('value', xor(values, [String(index)]).join(','), index)
                  }
                  editableContentProps={{
                    contentEditable: valueEditable,
                    onInput: (event) => {
                      onChange('name', event.currentTarget.textContent ?? '', index);
                    },
                    content: option,
                    onBlur: () => onBlur?.({ optionIndex: index }),
                  }}
                  dependencies={dependencies}
                >
                  {renderClearOptionButton(index)}
                </EditableCheckbox>
              ) : (
                <Checkbox
                  key={option + index}
                  checked={values.includes(String(index))}
                  disabled={disabled}
                  onClick={() =>
                    !disabled && onChange('value', xor(values, [String(index)]).join(','), index)
                  }
                  style={valueEditable ? {} : { pointerEvents: 'none' }}
                >
                  {option}
                </Checkbox>
              ),
            )}
            {AddOptionButton}
          </>
        );
      }

      case PropertyType.File:
        return (
          <FileWrapper>
            <FileUpload
              ref={fileUploadRef}
              id={id ?? ''}
              icon={
                <>
                  <UploadIcon />
                  <CustomTypo weight={500} style={{ marginLeft: 6 }}>
                    Upload file
                  </CustomTypo>
                </>
              }
              disabled={disabled || !valueEditable}
              hideTitle
              onChangeFiles={(newFiles) => {
                onChange('files', newFiles);
              }}
              multiple
              maxFileSize={FILE_SIZE_10_MB}
              hideError
              onError={() => {
                showToastMessage('error', 'Max file size 10MB reached!');
              }}
            />
            <Row style={{ gap: 8, flexWrap: 'wrap', marginTop: files?.length ? 16 : 0 }}>
              {files?.map((file, fileIndex) => (
                <FileItem
                  key={fileIndex}
                  onClick={(event) => {
                    event.stopPropagation();
                    visible.setValue(false);

                    if (file['id']) {
                      downloadFromFile(file);
                    } else {
                      downloadLocalFile(file);
                    }
                  }}
                >
                  <FileCard key={fileIndex} file={file} />
                  {valueEditable && locked == false && !file.masterId ? (
                    <Button
                      icon={IconClose}
                      placementIcon="left"
                      className="file-item-rm"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        visible.setValue(false);
                        onRemove({ fileIndex });
                        fileUploadRef.current?.onRemoveFiles?.([file], file.name);
                      }}
                    />
                  ) : null}
                </FileItem>
              ))}
            </Row>
          </FileWrapper>
        );

      default:
        return null;
    }
    /* eslint-enable no-fallthrough */
  }, [type, value, options?.length, files?.length, ...(dependencies || [])]);

  const renderFieldContent = () => {
    return (
      <>
        <div style={{ flex: 1 }}>
          {titleEditableProp ? (
            <ContentEditable
              ref={nameRef}
              contentEditable={titleEditableProp}
              onInput={(event) => {
                onChange('name', event.currentTarget.textContent ?? '');
              }}
              style={{ marginBottom: 6 }}
              suppressContentEditableWarning
              onBlur={() => onBlur?.()}
              onFocus={() => visible.setValue(false)}
            >
              <CustomTypo variant={'body-1'} weight={500}>
                {useMemo(() => name, dependencies || [])}
              </CustomTypo>
            </ContentEditable>
          ) : (
            <CustomTypo
              variant={'body-1'}
              weight={600}
              style={{ marginBottom: 6, color: disabled ? '#00000040' : undefined }}
            >
              {name}
            </CustomTypo>
          )}

          {renderValueFieldByType()}
        </div>

        {disabled ? null : <span className="eye">{locked ? <LockIcon /> : null}</span>}
      </>
    );
  };

  const renderContent = () => {
    if (haveInteraction) {
      return (
        <EditableFieldWrapper
          className="editable-field"
          onClick={() => visible.setValue((prev) => !prev)}
          style={{
            padding: isWidget ? 12 : '8px 12px 12px',
            borderColor: visible.value ? '#003087' : undefined,
            borderStyle: visible.value ? 'solid' : undefined,
          }}
        >
          {renderFieldContent()}
        </EditableFieldWrapper>
      );
    }

    return <div style={{ marginBottom: 16 }}>{renderFieldContent()}</div>;
  };

  if (isHidden) {
    return null;
  }

  if (isWidget) {
    return (
      <CustomTypo
        variant={'h4'}
        weight={500}
        className="text-ellipsis-1"
        style={{ margin: '8px 0 20px' }}
      >
        {name}
      </CustomTypo>
    );
  }

  if (haveAction) {
    return (
      <DropdownWrapper>
        <CustomDropdown
          size="small"
          dropdownConfig={{
            trigger: 'click',
            visible: visible.value,
            onClickOutside: () => visible.setValue(false),
            placement: 'top',
            offset: [0, 4],
          }}
          menu={{
            items: getFieldActions(!!fieldLockable, locked),
            onClick: ({ key }) => {
              visible.setValue(false);

              if (key === 'delete') {
                onRemove();
              } else {
                onChange('locked', !locked);
              }
            },
            selectedKeys: [],
          }}
        >
          {renderContent()}
        </CustomDropdown>
      </DropdownWrapper>
    );
  }

  return renderContent();
};

const EditableFieldWrapper = styled(Row)`
  border-radius: var(--radius-medium, 8px);
  border: 1.5px dashed var(--border-neutral-default, rgba(0, 0, 0, 0.15));
  gap: 12px;
  flex: 1 0 0;
  margin-bottom: 16px;
  align-items: center;
  &:hover {
    border: 1.5px solid var(--border-brand-default, #003087);
  }

  span.eye {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: #8c8c8c;
      }
    }
  }

  .es-check-box-wrapper {
    padding: 2px;
  }
  .es-file-upload-wrapper {
    width: fit-content;
  }
`;

const DropdownWrapper = styled.div`
  .dropdown-children {
    padding: 0;
    width: 100% !important;
    height: unset !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .tippy-box .dropdown {
    min-width: 300px !important;
    max-width: 300px;
  }
`;

const FileWrapper = styled.div`
  .es-file-upload {
    border-radius: 8px;
    border: 1px solid var(--border-neutral-default, rgba(0, 0, 0, 0.15));
    width: 132px;
    height: 38px;
    padding: 0 12px;
    box-sizing: border-box;
  }
  .es-upload-message {
    width: fit-content;
    text-align: left;
  }
`;
const FileItem = styled.div`
  position: relative;
  .file-item-rm {
    position: absolute;
    display: none;
    top: 4px;
    right: 4px;
  }
  &:hover .file-item-rm {
    display: flex;
  }
`;
