import type { DeepPartial } from 'enable-ui';

import type { ActivityLog, LogObjectType } from './type';

import { merge } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface Pagination<T> {
  items: T[];
  count: number;
  page: number;
}

type LogState = Record<LogObjectType, Pagination<ActivityLog>>;

interface State {
  openDrawer: Record<LogObjectType, boolean>;

  changeLogs: LogState;
}

interface Store {
  state: State;

  setState: (payload: Partial<State>) => void;
  mergeState: (payload: DeepPartial<State>) => void;
  setLogs: (type: LogObjectType, payload: Pagination<ActivityLog>) => void;
  clearState: (type?: LogObjectType, closeDrawer?: boolean) => void;
}

const defaultPaginationState: Pagination<ActivityLog> = {
  count: 0,
  items: [],
  page: 1,
};

const initState: State = {
  changeLogs: {
    Build: defaultPaginationState,
    Process: defaultPaginationState,
    Organization: defaultPaginationState,
    Project: defaultPaginationState,
  },
  openDrawer: {
    Build: false,
    Process: false,
    Organization: false,
    Project: false,
  },
};

export const useActivityLogStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    mergeState: (payload) => {
      set((store) => {
        store.state = merge(store.state, payload);
      });
    },
    setLogs: (type, payload) => {
      set((store) => {
        store.state.changeLogs[type] = {
          ...payload,
          items:
            payload.page > 1
              ? store.state.changeLogs[type].items.concat(payload.items)
              : payload.items,
        };
      });
    },
    clearState: (type, closeDrawer) => {
      set((store) => {
        if (type) {
          store.state.changeLogs[type] = defaultPaginationState;

          if (closeDrawer) {
            store.state.openDrawer[type] = false;
          }
        } else {
          store.state = initState;
        }
      });
    },
  })),
);
