import { useNavigate } from 'react-router-dom';

import { openModal } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import type { LoginRequest } from './type';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { updateAuthen, updateUserProfile } from './store';

const validationSchema = Yup.object({
  email: Yup.string().email().required().label('Email'),
  password: Yup.string().min(6).label('Password'),
});

export const useController = () => {
  const navigate = useNavigate();

  const {
    form: { register },
    handleSubmit,
    registerError,
    disabled,
  } = useFormManager({
    defaultValues: {
      email: '',
      password: '',
    },
    schema: validationSchema,
    onSubmit: async (values) => {
      const result = await apiCall<LoginRequest, any>({
        url: '/auth/login',
        data: values,
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.accessToken) {
        updateAuthen({ accessToken: result.accessToken });

        if (result?.user?.id) {
          updateUserProfile(result.user);
        }

        navigate('/');

        if (result?.user?.isVerified === false) {
          openModal(MODAL_KEY.UPDATE_PASSWORD);
        }
      }
    },
  });

  return {
    handleSubmit,
    register,
    registerError,
    disabled,
  };
};
