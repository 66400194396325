import { passwordSchema } from 'components/layout/core/constants';
import { Modal, TextInput, closeModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { apiCall } from 'hooks/service';
import { omit } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

export const UpdatePasswordModal = () => {
  const passwordForm = useFormManager({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    schema: passwordSchema,
    onSubmit: async (values) => {
      const result = await apiCall<any, any>({
        url: '/user-profile/password',
        data: values,
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        showToastMessage('success', 'Saved successfully!');
        passwordForm?.form?.reset?.();
        closeModal(MODAL_KEY.UPDATE_PASSWORD);
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.UPDATE_PASSWORD}
      header="Update your password"
      closeOnClickOutside={false}
      cancelButton={{ hide: true }}
      hideCloseIcon={true}
      submitButton={{
        label: 'Done',
        buttonProps: { onClick: passwordForm.handleSubmit, disabled: passwordForm.disabled },
      }}
    >
      <>
        <TextInput
          {...passwordForm.form.register('oldPassword')}
          {...passwordForm.registerError('oldPassword')}
          title="Current Password"
          type="password"
          placeholder=""
          containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
        <TextInput
          {...passwordForm.form.register('newPassword')}
          {...passwordForm.registerError('newPassword')}
          title="New Password"
          type="password"
          placeholder=""
          containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
        <TextInput
          {...passwordForm.form.register('confirmPassword')}
          {...passwordForm.registerError('confirmPassword')}
          title="Confirm Password"
          type="password"
          placeholder=""
          containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
      </>
    </Modal>
  );
};
