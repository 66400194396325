import { useParams } from 'react-router-dom';

import { PageContent, PageHeader } from 'components/layout';

import { TemplatesManager } from '../components';
import { TEMPLATE_CONFIG, type TemplateType } from '../core';

export const TemplatePage = () => {
  const { orgId, type } = useParams<{ orgId: string; type: TemplateType }>();

  return (
    <>
      <PageHeader title={type ? TEMPLATE_CONFIG[type].title : ''} />
      <PageContent>
        <TemplatesManager />
      </PageContent>
    </>
  );
};
