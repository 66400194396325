import { EmptyModalWrapper } from 'components/modal';

import { MODAL_KEY } from 'constants/modal-key';

import { AddOrgModal } from './AddOrgModal';
import { DeleteOrgModal } from './DeleteOrgModal';
import { RenameOrgModal } from './RenameOrgModal';

export const OrganizationModals = () => {
  return (
    <>
      <EmptyModalWrapper modalKey={MODAL_KEY.ORG_RENAME}>
        <RenameOrgModal />
      </EmptyModalWrapper>

      <EmptyModalWrapper modalKey={MODAL_KEY.ORG_ADD}>
        <AddOrgModal />
      </EmptyModalWrapper>

      <EmptyModalWrapper modalKey={MODAL_KEY.ORG_DELETE}>
        <DeleteOrgModal />
      </EmptyModalWrapper>
    </>
  );
};
