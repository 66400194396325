import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { type CheckboxProps, showToastMessage } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import type { TemplateType } from '../type';

import { mappingPath } from 'helpers';
import { useString } from 'hooks';
import { apiCall } from 'hooks/service';
import { xor } from 'lodash';

import { PATH } from 'constants/path';

import { useTemplateSectionStore } from '../store';

export const useAddTemplateController = () => {
  const orgId = useOrgId();
  const params = useParams<{ type: TemplateType }>();
  const navigate = useNavigate();
  const sections = useTemplateSectionStore();
  const [sectionIds, setSectionIds] = useState<string[]>([]);
  const name = useString();

  useEffect(() => {
    if (sections?.length) {
      setSectionIds(sections.map((el) => el.id));
    }
  }, [sections?.length]);

  const onSubmit = async () => {
    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/templates`,
      data: {
        type: params.type,
        name: name.value,
        sectionIds,
      },
      method: 'POST',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      navigate(mappingPath(PATH.TEMPLATE_EDIT, [orgId, params.type, result.id]), { replace: true });
      showToastMessage('success', 'Added successfully!');
    }
  };

  const options: CheckboxProps[] =
    sections?.map((el) => ({
      children: el.name,
      onClick: () => {
        setSectionIds((prev) => xor(prev, [el.id]));
      },
      checked: sectionIds.includes(el.id),
    })) || [];

  const disabled = !name.value.length || !sectionIds.length;

  return {
    options,
    onSubmit,
    name,
    disabled,
  };
};
