import { useMatch } from 'react-router-dom';

import { Modal, TextInput, closeModal, showToastMessage, useDialogStore } from 'enable-ui';
import { useFormManager } from 'form/form-manager';
import { current } from 'immer';

import { LogObjectType, useFetchActivityLog } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { renameProjectState, useProjectStore } from '../../core';

export const RenameProjectModal = () => {
  const orgId = useOrgId();
  const curProject = useProjectStore((s) => s.state.curProject);
  const fetchApi = useFetchActivityLog(LogObjectType.Project);
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROJECT_RENAME]);

  const schema = Yup.object({
    name: Yup.string()
      .notOneOf([curProject?.name || ''])
      .required()
      .label('Name'),
  });

  const project = modalProps?.proj || curProject;

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: project?.name || '' },
    onSubmit: async (values) => {
      if (!project) {
        return;
      }

      const result = await apiCall({
        url: `/organizations/${orgId}/projects/${project?.id}`,
        data: values,
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.PROJECT_RENAME);
        renameProjectState({
          ...project,
          name: values.name || project.name,
        });
        showToastMessage('success', 'Renamed successfully!');

        if (window.location.pathname.includes('action-logs')) {
          fetchApi();
        }
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_RENAME}
      header="Rename Project"
      cancelButton={{ hide: true }}
      submitButton={{ label: 'Save', buttonProps: { onClick: handleSubmit, disabled: !isValid } }}
    >
      <TextInput
        title="Project name"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
