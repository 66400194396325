import { EmptyModalWrapper } from 'components/modal';

import { MODAL_KEY } from 'constants/modal-key';

import { AddAdminModal } from './AddAdminModal';
import { AddMemModal } from './AddMemberModal';
import { DeleteMemberModal } from './DeleteMemberModal';
import { NewPasswordModal } from './NewPasswordModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { UpdatePasswordModal } from './UpdatePasswordModal';

export const MemberModal = () => {
  return (
    <>
      <EmptyModalWrapper modalKey={MODAL_KEY.MEM_DELETE}>
        <DeleteMemberModal />
      </EmptyModalWrapper>
      <EmptyModalWrapper modalKey={MODAL_KEY.MEM_ADD}>
        <AddMemModal />
      </EmptyModalWrapper>
      <EmptyModalWrapper modalKey={MODAL_KEY.ADMIN_ADD}>
        <AddAdminModal />
      </EmptyModalWrapper>
      <EmptyModalWrapper modalKey={MODAL_KEY.MEM_RESET}>
        <ResetPasswordModal />
      </EmptyModalWrapper>
      <NewPasswordModal />
      <EmptyModalWrapper modalKey={MODAL_KEY.UPDATE_PASSWORD}>
        <UpdatePasswordModal />
      </EmptyModalWrapper>
    </>
  );
};
