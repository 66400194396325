import { useCallback, useState } from 'react';

import { merge } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ApiStoreKey } from 'types';
import { type APIConfig } from 'types/api';

import { apiCall } from './api';

interface State {
  response: Record<string, any>;
}

interface Store {
  state: State;
  setResponse: (key: string, payload: any) => void;
  removeResponse: (key: string) => void;
  clearState: () => void;
}

const initState: State = {
  response: {},
};

export const useFetchStore = create(
  immer<Store>((set) => ({
    state: initState,

    setResponse: (key, payload) => {
      set((store) => {
        if (payload) {
          store.state.response[key] = payload;
        }
      });
    },

    removeResponse: (key) => {
      set((store) => {
        // If record existed
        if (store.state.response[key]) {
          delete store.state.response[key];
        }
      });
    },

    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const valueByStateKeySelector = (stateKey: ApiStoreKey) => {
  return (store: Store) => {
    return store.state.response[stateKey];
  };
};

/**
 * return necessary methods to get data from server and store as state
 *
 * @param apiConfig : The configs that you set to call the GET api
 * @returns
 *- fetchApi: the function to get response from API and store it to useFetchStore
 *- state: the response as state
 *- clearState: function to remove a given key - value from the store
 *- setState: function to update the given key - value to store
 * @example
 *
 * //Hook into your component
 * const { fetchApi, setState, clearState } = useFetchApiToStore<any, any>('ward', {
 *  url: 'your-url',
 *});
 *
 * //Call fetchApi to get data
 *useEffect(() => {
 *  fetchApi();
 *}, []);
 *
 * //Access the state in the store:
 * const state = useFetchStore(valueByStateKeySelector('ward'));
 *
 */
export const useFetchApiToState = <TRequest = unknown, TResponse = unknown>(
  apiConfig: APIConfig<TRequest>,
) => {
  const [state, setState] = useState<TResponse | null>(null);

  const fetchApi = useCallback(async (overrideApiConfig?: Partial<APIConfig<TRequest>>) => {
    apiCall<TRequest, TResponse>(merge(apiConfig, overrideApiConfig))
      .then((response) => {
        if (response) {
          setState(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return {
    fetchApi,
    state,
    setState,
    clearState: () => setState(null),
  };
};

/**
 * @description Custom hook to call GET API and save the response into general store, in case you want to use the response across multiple components
 *
 * @param stateKey : The unique key that you can access to response data in the store
 * @param apiConfig : The configs that you set to call the GET api
 * @returns
 *- fetchApi: the function to get response from API and store it to useFetchStore;
 *- clearState: function to remove a given key - value from the store
 *- setState: function to update the given key - value to store
 * @example
 *
 * //Hook into your component
 * const { fetchApi, setState, clearState } = useFetchApiToStore<any, any>('ward', {
 *  url: 'your-url',
 *});
 *
 * //Call fetchApi to get data
 *useEffect(() => {
 *  fetchApi();
 *}, []);
 *
 * //Access the state in the store:
 * const state = useFetchStore(valueByStateKeySelector('ward'));
 *
 */

export const useFetchApiToStore = <TRequest = unknown, TResponse = unknown>(
  stateKey: ApiStoreKey,
  apiConfig: APIConfig<TRequest>,
) => {
  const fetchApi = useCallback(async () => {
    apiCall<TRequest, TResponse>({ ...apiConfig, method: 'GET' })
      .then((response) => {
        if (response) {
          useFetchStore.getState().setResponse(stateKey, response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return {
    fetchApi,
    clearState: () => {
      useFetchStore.getState().removeResponse(stateKey);
    },
    setState: (payload: any) => {
      useFetchStore.getState().setResponse(stateKey, payload);
    },
  };
};
