import { useEffect, useRef } from 'react';

import { Checkbox, type CheckboxProps } from 'enable-ui';

import { ContentEditable, EditableOptionItem } from './styles';

interface Props extends CheckboxProps {
  editableContentProps: React.HTMLAttributes<HTMLSpanElement> & { content: string };
  dependencies?: any[];
}

export const EditableCheckbox = ({
  children,
  editableContentProps,
  dependencies,
  ...checkboxProps
}: Props) => {
  const nameRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.textContent = editableContentProps.content;
    }
  }, dependencies || []);

  return (
    <EditableOptionItem onClick={(event) => event.stopPropagation()}>
      <Checkbox {...checkboxProps}>
        <ContentEditable
          ref={nameRef}
          style={{ fontWeight: 400 }}
          {...editableContentProps}
          suppressContentEditableWarning
        >
          {editableContentProps.content}
        </ContentEditable>
      </Checkbox>

      {children}
    </EditableOptionItem>
  );
};
