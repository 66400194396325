import styled from 'styled-components';

import { DropdownWrapper } from 'components/DropdownWrapper';
import { Tabs } from 'components/Tabs';
import { ModalCard } from 'components/layout/components/ModalCard';
import {
  Button,
  Checkbox,
  Dropdown,
  IconClose,
  Row,
  type TabItemType,
  Typography,
  openModal,
  primary,
} from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';

import { ReactComponent as AddIcon } from 'assets/icons/plus-thick.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { resetTemplateSectionState, setTemplateState, useTemplateSectionController } from '../core';

export const TemplateSectionMenu = () => {
  const {
    displaySections,
    remainSections,
    selectingSectionIds,
    curMasterSectionId,
    templateSectionId,
    openAddSectionDropdown,
    onRemoveSection,
    toggleSectionId,
    onSubmitAddSections,
    onCloseDropdown,
    onAddCustomSections,
  } = useTemplateSectionController();

  const readOnly = useIsAdmin() === false;

  const sectionItems: TabItemType[] = displaySections.map(([masterId, sections]) => {
    const active = masterId === curMasterSectionId;

    return {
      children: '',
      key: masterId,
      label: (
        <Row justify="space-between" align="center" className={active ? 'active' : ''}>
          <Typography
            color={active ? primary['primary-6'] : undefined}
            weight={active ? 500 : undefined}
            className="text-ellipsis-1"
          >
            {sections[0].name}
          </Typography>

          {readOnly || masterId === templateSectionId ? null : (
            <Button
              color="primary"
              size="small"
              icon={IconClose}
              placementIcon="left"
              variant="text"
              onClick={(event) => {
                // event.stopPropagation();
                openModal(MODAL_KEY.FIELD_REMOVE, {
                  callback: () => onRemoveSection(sections),
                });
              }}
            />
          )}
        </Row>
      ),
    };
  });

  const RemainSectionDropdownMenu = (
    <DropdownWrapper>
      <Tabs
        variant="outlined"
        tabPosition="vertical"
        items={remainSections.map((el) => ({
          label: (
            <Row
              justify="space-between"
              align="center"
              className={selectingSectionIds.includes(el.id) ? 'active' : ''}
            >
              <Checkbox checked={selectingSectionIds.includes(el.id)}>
                <Typography className="text-ellipsis-1">{el.name}</Typography>
              </Checkbox>
            </Row>
          ),
          children: '',
          key: el.id,
        }))}
        activeKey="none"
        onTabClick={toggleSectionId}
      />

      <div className="add-btm">
        <Button
          color="primary"
          disabled={!selectingSectionIds.length}
          onClick={onSubmitAddSections}
        >
          Add
        </Button>
      </div>
      <Row
        justify="center"
        style={{ borderTop: '1px solid rgba(0, 0, 0, 0.15)', padding: '4px 8px' }}
      >
        <Button
          icon={<AddIcon className="custom-add-icon" />}
          color="primary"
          placementIcon="left"
          variant="text"
          size="small"
          onClick={onAddCustomSections}
        >
          Add custom section
        </Button>
      </Row>
    </DropdownWrapper>
  );

  return (
    <TemplateContentWrapper title="Sections" titleProps={{ className: 'h3-5-medium' }}>
      <Tabs
        variant="outlined"
        tabPosition="vertical"
        className="section"
        items={sectionItems}
        activeKey={curMasterSectionId}
        onTabClick={(key) => {
          setTemplateState({ curMasterSectionId: key });
          resetTemplateSectionState();
        }}
      />

      {readOnly ? null : (
        <div style={{ width: 'fit-content' }}>
          <Dropdown
            dropdownConfig={{
              appendTo: document.body,
              content: RemainSectionDropdownMenu,
              visible: openAddSectionDropdown,
              onClickOutside: onCloseDropdown,
            }}
          >
            <Button
              icon={
                <span>
                  <AddIcon />
                </span>
              }
              color="primary"
              placementIcon="left"
              variant="text"
              size="small"
              className="add-sec"
              onClick={() => setTemplateState({ openAddSectionDropdown: true })}
            >
              Add section
            </Button>
          </Dropdown>
        </div>
      )}
    </TemplateContentWrapper>
  );
};

export const TemplateContentWrapper = styled(ModalCard)`
  width: 300px;
  padding: 24px 0;
  margin: unset;

  p {
    text-align: left;
  }
  .es-tab.section {
    padding: 0 12px;
    .es-tab-item.active {
      background-color: ${primary['primary-1']};
    }
  }
  .mdl-heading {
    padding: 0 24px 24px;
  }
  .es-tab-item {
    height: 36px;
    margin: unset !important;
    border-radius: 8px;
    &:hover {
      svg path {
        stroke: ${primary['primary-6']};
      }
      p {
        color: ${primary['primary-6']};
        font-weight: 500;
      }
    }
    &.active {
      background: ${primary['primary-6']};
      svg path {
        stroke: ${primary['primary-6']};
      }
    }

    button {
      transform: translateX(10px);
      background: transparent;
      &:hover {
        background: transparent;
      }
    }
  }
  .dropdown-children {
    width: fit-content !important;
  }
  .add-sec {
    margin-left: 12px;
    padding: 0 8px;
    span {
      padding: 4px;
      height: 24px;
      width: 24px;
      svg {
        margin-right: 2px;
        height: 16px;
        width: 16px;
      }
    }
    &[disabled] {
      background-color: transparent;
    }
  }
  .add-sec-mdl {
    border-radius: var(--radius-medium, 8px);
    border: 1px solid var(--border-neutral-subtle-default, rgba(0, 0, 0, 0.1));
    background: var(--surface-neutral-subtlest-default, #fff);
    width: 300px;
    padding: 4px 0;
    box-shadow:
      0px 12px 16px -4px rgba(0, 0, 0, 0.06),
      0px 8px 12px 0px rgba(0, 0, 0, 0.06);

    svg:not(.custom-add-icon) path[stroke] {
      stroke: #ffffff;
    }

    .es-tab-item {
      padding: 0;
      > div {
        pointer-events: none;
        padding: 0 8px;
        height: 100%;
        &.active {
          background-color: #fafafa;
        }
      }
      &:hover {
        p {
          color: #222222;
          font-weight: normal;
        }
        > div {
          background-color: #f0f0f0;
          height: 100%;
        }
      }
    }
    .add-btm {
      margin: 16px 12px 12px;
      button {
        width: 100%;
      }
    }
  }
`;
