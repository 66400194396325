import { useNavigate } from 'react-router-dom';

import { Modal, TextInput, Typography, closeModal, openModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { removeOrgState, useOrgStore } from '../../core/store';

export const DeleteOrgModal = () => {
  const curOrg = useOrgStore((s) => s.state.curOrg);
  const count = useOrgStore((store) => store.state.orgs.length);
  const navigate = useNavigate();

  const schema = Yup.object({
    name: Yup.string()
      .equals([curOrg?.name || ''])
      .required()
      .label('Name'),
  });

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async () => {
      if (!curOrg) return;

      const result = await apiCall({
        url: `/organizations/${curOrg?.id}`,
        method: 'DELETE',
        isLoading: true,
        showError: true,
      });

      if (result === true) {
        closeModal(MODAL_KEY.ORG_DELETE);
        removeOrgState(curOrg);
        showToastMessage('success', 'Deleted successfully!');

        if (count <= 1) {
          openModal(MODAL_KEY.ORG_ADD);
        } else {
          navigate('/');
        }
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.ORG_DELETE}
      header="Delete Organization"
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: handleSubmit, disabled: !isValid, color: 'danger' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        Deleting this Organization will also delete all Organization’s data such as Members,
        Templates, DED Processes, Builds, etc. Deleted data cannot be recovered.
      </Typography>
      <Typography style={{ marginBottom: 8, lineHeight: '22px' }}>
        To confirm deleting <strong>{curOrg?.name}</strong>, please enter the organization name
        below.
      </Typography>

      <TextInput
        {...register('name')}
        placeholder="Enter the organization name"
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
