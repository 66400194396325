import { CustomTypo } from 'components/CustomTypography';

import type { DefaultTemplate } from 'modules/template/core';

import { TemplateSelectInput } from './TemplateSelectInput';
import { VariantsSelection, type VariantsSelectionProps } from './VariantsSelection';

interface Props extends VariantsSelectionProps {
  title: string;
  templates: DefaultTemplate[];
  templateId: string;
  selectedTemplateIds: string[];
  customPlaceholder?: string;
  onSelectTemplate: (template?: DefaultTemplate) => void;
}

export const ProcessSection = ({
  title,
  templates,
  templateId,
  selectedTemplateIds,
  onSelectTemplate,
  ...props
}: Props) => {
  const value = templates.find((el) => el.id === templateId);

  return (
    <>
      <CustomTypo weight={600} style={{ marginBottom: 6 }}>
        {title}
      </CustomTypo>
      <TemplateSelectInput
        templates={templates.filter((el) => !selectedTemplateIds.includes(el.id))}
        onChange={(val: any) => {
          onSelectTemplate(val?.value);
        }}
        value={templateId && value ? { value } : undefined}
        containerStyle={{ marginBottom: 16 }}
        isClearable
        customPlaceholder={props?.customPlaceholder || ''}
      />
      <VariantsSelection {...props} />
    </>
  );
};
