import { isString } from 'lodash';

export function trimValue<T>(value: T) {
  if (isString(value)) {
    return value.replace(/\s+/g, ' ').trim();
  }

  return value;
}

export const trimObject = (object: any) => {
  const newObject: Record<string, any> = {};

  Object.keys(object).forEach((key: string) => {
    newObject[key] = trimValue(object[key]);
  });

  return newObject;
};

export const compileFormData = (body: any) => {
  const formData = new FormData();

  Object.keys(trimObject(body)).forEach((key) => {
    formData.append(key, body[key]);
  });

  return formData;
};
