import { EmptyModalWrapper } from 'components/modal';

import { MODAL_KEY } from 'constants/modal-key';

import { AddCustomSectionModal } from './AddCustomSectionModal';
import { DeleteFieldModal } from './DeleteFieldModal';
import { DeleteTemplateModal } from './DeleteTemplateModal';

export const TemplateModals = () => {
  return (
    <>
      <DeleteTemplateModal />
      <DeleteFieldModal />
      <EmptyModalWrapper modalKey={MODAL_KEY.ADD_CUSTOM_SECTION}>
        <AddCustomSectionModal />
      </EmptyModalWrapper>
    </>
  );
};
