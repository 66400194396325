import { OrganizationModals } from 'modules/organization/components';
import { ProjectModals } from 'modules/project/components/modal/ProjectModals';
import { TemplateModals } from 'modules/template/components/modal/TemplateModals';
import { MemberModal } from 'modules/user/components/modal';

export const ModalController = () => {
  return (
    <>
      <OrganizationModals />

      <ProjectModals />

      <MemberModal />

      <TemplateModals />
    </>
  );
};
