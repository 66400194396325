import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { openModal } from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';

import type { Organization } from './type';

import { mappingPath } from 'helpers';
import { apiCall } from 'hooks/service';
import { isNull } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { setOrgState } from './store';

export const useInitOrg = () => {
  const navigate = useNavigate();
  const match = useMatch('/org/:orgId/*');
  const isAdmin = useIsAdmin();

  useEffect(() => {
    const fetchOrgs = async () => {
      const result = await apiCall<any, { count: number; items: Organization[] }>({
        method: 'GET',
        url: '/organizations',
        isLoading: true,
        config: {
          params: {
            page: 1,
            limit: 999,
            order_by: 'createdAt',
            order_direction: 'asc',
          },
        },
      });

      if (!result.items) {
        return;
      }

      if (!result.items.length) {
        return isAdmin ? openModal(MODAL_KEY.ORG_ADD) : navigate('/no-org');
      }

      setOrgState({
        orgs: result.items,
        curOrg: match?.params?.orgId
          ? result.items.find((el) => el.id === match?.params?.orgId)
          : result.items[0], // Set default
      });

      if (!isNull(match) && !match?.params?.orgId) {
        // Navigate to default
        navigate(mappingPath(PATH.MEMBERS, [result.items[0]?.id]));
      }
    };

    fetchOrgs();
  }, []);

  return null;
};
