import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import { Typography, openModal, red } from 'enable-ui';

import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Reset } from 'assets/icons/reload.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { MODAL_KEY } from 'constants/modal-key';

export const templateColumns = (
  label: string,
  modalCallback?: () => Promise<void>,
  isAdmin?: boolean,
) => {
  return [
    {
      id: 'subId',
      Header: `${label} ID`,
      width: '20%',
      minWidth: '140px',
      flex: 1,
      accessor: 'subId',
      sort: true,
      sortType: 'subId',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.subId}</CustomTypo>;
      },
    },
    {
      id: 'name',
      Header: `${label} name`,
      accessor: 'name',
      width: 'auto',
      flexGrow: 1,
      sort: true,
      sortType: 'name',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.name}</CustomTypo>;
      },
    },
    {
      id: 'action',
      width: '20px',
      minWidth: '20px',
      Cell: ({ ...props }) => {
        return (
          <div
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={isAdmin ? {} : { display: 'none' }}
          >
            <CustomDropdown
              size="small"
              TickIcon={<></>}
              menu={{
                onClick() {
                  openModal(MODAL_KEY.TEMPLATE_DELETE, {
                    templateId: props.data?.[props?.row?.index || 0]?.id,
                    callback: modalCallback,
                  });
                },
                items: [
                  {
                    key: 'delete',
                    label: (
                      <Typography variant="body-1" color={red['red-6']}>
                        Delete
                      </Typography>
                    ),
                    prefix: <Trash className="delete-icon" />,
                  },
                ],
                selectedKeys: [],
              }}
              dropdownConfig={{ trigger: 'click' }}
            >
              <div className="action-icon">
                <Dots />
              </div>
            </CustomDropdown>
          </div>
        );
      },
    },
  ];
};
