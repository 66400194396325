import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import { Checkbox, Typography, openModal, red } from 'enable-ui';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Publish } from 'assets/icons/tick-circle.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { ProcessStatus } from './type';

import { MODAL_KEY } from 'constants/modal-key';

import { ProcessStatusTag } from '../components/ded-process/ProcessState';
import { ExportType } from './store';

export const processColumns = (
  modalCallback: () => Promise<void>,
  onDuplicate: (id?: string) => Promise<void>,
  isAllowAdd: boolean,
  isAllowExport: boolean,
  exportPayload: {
    exportType: ExportType;
    exportIds: string[];
    onChangeHeaderCheckbox: () => void;
    onChangeCheckbox: (id: string) => void;
  },
  fetchData,
) => {
  const { exportType, exportIds, onChangeHeaderCheckbox, onChangeCheckbox } = exportPayload;

  const isChecked = [ExportType.All, ExportType.Selected].includes(exportType);
  const isLine = exportType === ExportType.Selected;

  return [
    {
      id: 'checkbox',
      Header: (
        <Checkbox
          className="export-checkbox"
          checked={isChecked}
          isLine={isLine}
          onClick={onChangeHeaderCheckbox}
          style={isAllowExport ? {} : { display: 'none' }}
        />
      ),
      width: isAllowExport ? '48px' : '0px',
      accessor: 'checkbox',
      sort: false,
      sortType: 'checkbox',
      Cell: ({ ...props }) => {
        const id = props.data?.[props?.row?.index || 0]?.id;
        const checked = exportType === ExportType.All || exportIds.includes(id);

        return (
          <Checkbox
            className="export-checkbox"
            checked={checked}
            onClick={(e) => {
              e.stopPropagation();
              onChangeCheckbox(id);
            }}
            style={isAllowExport ? {} : { display: 'none' }}
          />
        );
      },
    },
    {
      id: 'subId',
      Header: 'Process ID',
      width: '124px',
      accessor: 'subId',
      sort: true,
      sortType: 'subId',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.subId}</CustomTypo>;
      },
    },
    {
      id: 'name',
      Header: 'Process name',
      accessor: 'name',
      width: 'auto',
      sort: true,
      sortType: 'name',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.name}</CustomTypo>;
      },
    },
    {
      id: 'createdBy.fullName',
      Header: 'Process Owner',
      accessor: 'createdBy.fullName',
      width: '25%',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.createdBy?.fullName}</CustomTypo>;
      },
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      width: '85px',
      sort: true,
      sortType: 'status',
      Cell: ({ ...props }) => (
        <ProcessStatusTag status={props.data?.[props?.row?.index || 0]?.status} />
      ),
    },
    {
      id: 'action',
      width: '56px',
      Cell: ({ ...props }) => {
        return (
          <div
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={isAllowAdd ? {} : { display: 'none' }}
          >
            <CustomDropdown
              size="small"
              TickIcon={<></>}
              menu={{
                onClick(item) {
                  if (item.key === 'delete') {
                    openModal(MODAL_KEY.PROCESS_BUILD_DELETE, {
                      id: props.data?.[props?.row?.index || 0]?.id,
                      callback: modalCallback,
                    });
                  }

                  if (item.key === 'duplicate') {
                    onDuplicate?.(props.data?.[props?.row?.index || 0]?.id);
                  }

                  if (item.key === 'publish') {
                    openModal(MODAL_KEY.PUBLISH_PROCESS, {
                      id: props.data?.[props?.row?.index || 0]?.id,
                      callback: fetchData,
                    });
                  }
                },
                items: (() => {
                  const items = [
                    {
                      key: 'duplicate',
                      label: <Typography variant="body-1">Duplicate</Typography>,
                      prefix: <Copy />,
                    },
                    {
                      key: 'delete',
                      label: (
                        <Typography variant="body-1" color={red['red-6']}>
                          Delete
                        </Typography>
                      ),
                      prefix: <Trash className="delete-icon" />,
                    },
                  ];

                  if (props.data?.[props?.row?.index || 0]?.status === ProcessStatus.Draft) {
                    items.unshift({
                      key: 'publish',
                      label: <Typography variant="body-1">Publish</Typography>,
                      prefix: <Publish />,
                    });
                  }

                  return items;
                })(),
                selectedKeys: [],
              }}
              dropdownConfig={{ trigger: 'click' }}
            >
              <div className="action-icon">
                <Dots />
              </div>
            </CustomDropdown>
          </div>
        );
      },
    },
  ];
};
