import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CustomDropdown } from 'components/CustomDropdown';
import {
  Button,
  Row,
  type RowProps,
  Tag,
  Tooltip,
  Typography,
  neutral,
  openModal,
} from 'enable-ui';

import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';
import {
  ProcessStatus,
  useFetchProcess,
  useProjectId,
  useProjectPermission,
} from 'modules/project/core';

import { red } from 'styles/theme';

import { ReactComponent as BoxAddIcon } from 'assets/icons/box-add.svg';
import { ReactComponent as ActivityLog } from 'assets/icons/change-log.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Publish } from 'assets/icons/share.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { mappingPath } from 'helpers';
import { compact } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { ProcessStatusTag } from './ProcessState';

interface Props extends RowProps {
  title: string;
  isBuild?: boolean;
  status?: ProcessStatus;
}

export const ProcessAndBuildHeader: FC<Props> = ({
  title,
  isBuild,
  children,
  status,
  ...props
}) => {
  const navigate = useNavigate();
  const orgId = useOrgId();
  const projId = useProjectId();
  const { isMember } = useProjectPermission();
  const fetchApi = useFetchProcess();
  const isDraft = status === ProcessStatus.Draft;
  const isPublished = status === ProcessStatus.Publish;

  const params = useParams<{ processId: string; buildId: string }>();

  const callbackPath = isBuild ? PATH.PROJECT_BUILDS : PATH.PROJECT_DED_PROCESSES;
  const paramId = isBuild ? params.buildId : params.processId;

  const actionItems = compact([
    {
      key: 'change-log',
      label: (
        <Typography variant="body-1" color={neutral['neutral-11']}>
          Change log
        </Typography>
      ),
      prefix: <ActivityLog />,
    },
    isMember
      ? {
          key: 'delete',
          label: (
            <Typography variant="body-1" color={red['red-6']}>
              Delete
            </Typography>
          ),
          prefix: <Trash className="danger-ic" />,
        }
      : null,
  ]);

  return (
    <Row direction="column" style={{ gap: 12 }}>
      {!isBuild && status ? <ProcessStatusTag status={status} /> : null}
      <Wrapper justify="space-between" {...props}>
        <div>
          <Typography variant="h3" weight={500}>
            {title}
          </Typography>

          {children}
        </div>

        <Row style={{ width: 'fit-content' }} align="center">
          {!isMember || isBuild || isDraft ? null : (
            <Tooltip
              content="Create a Build with current DED Process"
              arrow={false}
              placement="top"
              offset={[0, 4]}
            >
              <div>
                <Button
                  icon={<BoxAddIcon />}
                  placementIcon="left"
                  variant="text"
                  size="small"
                  color="default"
                  className="add-build-btn"
                  onClick={() =>
                    openModal(MODAL_KEY.CREATE_BUILD_FROM_PROCESS, { processId: paramId })
                  }
                />
              </div>
            </Tooltip>
          )}

          {!isMember || isBuild || isPublished ? null : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className="add-build-btn"
              onClick={() =>
                openModal(MODAL_KEY.PUBLISH_PROCESS, { id: paramId, callback: fetchApi })
              }
              style={{ marginRight: 8 }}
            >
              Publish
            </Button>
          )}

          <CustomDropdown
            size="small"
            menu={{
              items: actionItems,
              selectedKeys: [],
              onClick: ({ key }) => {
                if (key === 'delete') {
                  openModal(MODAL_KEY.PROCESS_BUILD_DELETE, {
                    id: paramId,
                    isBuild,
                    callback: () =>
                      navigate(mappingPath(callbackPath, [orgId, projId]), {
                        replace: true,
                      }),
                  });
                } else {
                  useActivityLogStore
                    .getState()
                    .mergeState({ openDrawer: isBuild ? { Build: true } : { Process: true } });
                }
              },
            }}
          >
            <div className="action-icon">
              <Dots />
            </div>
          </CustomDropdown>
        </Row>
      </Wrapper>
    </Row>
  );
};

const Wrapper = styled(Row)`
  margin-bottom: 16px;

  .add-build-btn {
    background-color: transparent;
    &:hover {
      background-color: #0000001a;
    }
  }
`;
