enum Role {
  SubAdmin = 'SubAdmin',
  Admin = 'Admin',
  Member = 'Member',
}

enum Policy {
  Dashboard = 'dashboard',
  User = 'user',
  Team = 'team',
}

enum Permission {
  ViewDashboard = 'view-dashboard',
  ViewUser = 'view-user',
  EditUser = 'edit-user',
  DeleteUser = 'delete-user',
}

export { Role, Permission, Policy };
