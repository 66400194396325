import type { LogType } from 'modules/log/core';

export const ACTION_LOG_CONFIG: Partial<Record<LogType, string>> = {
  CreateOrg: 'added organization',
  UpdateOrg: 'renamed organization',
  CreateProject: 'added project',
  DeleteProject: 'deleted project',
  UpdateProject: 'renamed project',

  CreateProcess: 'added process',
  DeleteProcess: 'deleted process',
  UpdateProcess: 'renamed process',

  CreateBuild: 'added build',
  DeleteBuild: 'deleted build',
  UpdateBuild: 'renamed build',
};
