import type { UserProfile } from 'modules/auth/core';

export interface ActivityLog {
  id: string;
  type: LogType;
  objectSubId: string;
  objectType: LogObjectType;
  oldContent: any;
  newContent: any;
  logDetails: LogDetail[];
  createdAt: string;
  createdBy: UserProfile;
}

export interface LogDetail {
  template: string;
  section: string;
  property: string;
  type: LogDetailType;
  action: LogDetailAction;
  oldValue: string;
  newValue: string;
}

export enum LogType {
  CreateOrg = 'CreateOrg',
  UpdateOrg = 'UpdateOrg',

  CreateProject = 'CreateProject',
  UpdateProject = 'UpdateProject',
  DeleteProject = 'DeleteProject',

  CreateProcess = 'CreateProcess',
  UpdateProcess = 'UpdateProcess',
  DeleteProcess = 'DeleteProcess',

  CreateBuild = 'CreateBuild',
  UpdateBuild = 'UpdateBuild',
  DeleteBuild = 'DeleteBuild',

  UpdateProcessProperty = 'UpdateProcessProperty',
  ChangeProcessTemplate = 'ChangeProcessTemplate',
  ChangeLinkPowder = 'ChangeLinkPowder',

  UpdateBuildProperty = 'UpdateBuildProperty',
  ChangeBuildProcess = 'ChangeBuildProcess',
  SyncBuildProcess = 'SyncBuildProcess',
}

export enum LogObjectType {
  Organization = 'Organization',
  Project = 'Project',
  Process = 'Process',
  Build = 'Build',
}

export enum LogDetailType {
  Field = 'Field',
  Value = 'Value',
}

export enum LogDetailAction {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
}
