import { PageContent, PageHeader } from 'components/layout';

import { ProjectsManager } from '../components/ProjectManager';

export const ProjectPage = () => {
  return (
    <>
      <PageHeader title={'Projects'} />
      <PageContent>
        <ProjectsManager />
      </PageContent>
    </>
  );
};
