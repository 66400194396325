import { type Props, components as ReactSelectComponent } from 'react-select';

import { CustomSelect, type CustomSelectProps } from 'components/CustomSelect';
import { Checkbox } from 'enable-ui';

import type { DefaultDedProcess } from 'modules/project/core';

import { ProcessInfo } from '../ded-process/ProcessInfo';

export function ProcessSelectInput({
  processes,
  customPlaceholder,
  showCheckbox,
  ...props
}: CustomSelectProps &
  Props<{ value: DefaultDedProcess }> & {
    processes: DefaultDedProcess[];
    showCheckbox?: boolean;
  } & {
    customPlaceholder?: string;
  }) {
  return (
    <CustomSelect
      isSearchable={false}
      options={processes.map((el) => ({
        label: el.name,
        value: el,
      }))}
      components={{
        Option: (compoProps) => {
          return (
            <ReactSelectComponent.Option {...compoProps}>
              {showCheckbox ? (
                <Checkbox checked={compoProps.isSelected}>
                  <ProcessInfo process={compoProps.data.value} style={{ marginLeft: 4 }} />
                </Checkbox>
              ) : (
                <ProcessInfo process={compoProps.data.value} />
              )}
            </ReactSelectComponent.Option>
          );
        },
        Placeholder: (compoProps) => (
          <ReactSelectComponent.Placeholder {...compoProps}>
            <ProcessInfo style={{ padding: '10px 2px' }} />
          </ReactSelectComponent.Placeholder>
        ),
        SingleValue: (compoProps) => {
          return (
            <ReactSelectComponent.SingleValue {...compoProps}>
              <ProcessInfo process={compoProps.data.value} style={{ padding: '8px 2px' }} />
            </ReactSelectComponent.SingleValue>
          );
        },
      }}
      optionStyle={{
        padding: '8px 10px',
      }}
      showDropdownIcon
      menuStyle={{
        marginTop: 5.5,
      }}
      maxMenuHeight={250}
      {...props}
    />
  );
}
