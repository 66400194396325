import { useEffect, useState } from 'react';
import { components as ReactSelectComponent } from 'react-select';

import { CustomSelect } from 'components/CustomSelect';
import { CustomTypo } from 'components/CustomTypography';
import {
  Modal,
  Row,
  TextInput,
  closeModal,
  openModal,
  showToastMessage,
  useDialogStore,
} from 'enable-ui';

import type { UserProfile } from 'modules/auth/core';
import { useOrgId } from 'modules/organization/core/store';
import { MemberCard } from 'modules/project/components/MemberCard';

import { apiCall } from 'hooks/service';
import { find, some } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

const getUser = (users: UserProfile[], mail: string) => {
  return find(users, (el) => el.email === mail);
};

export const AddAdminModal = () => {
  const [users, setUsers] = useState<UserProfile[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.ADMIN_ADD]);
  const curOrg = useOrgId();

  const userOptions =
    users?.map((el) => {
      return { label: el?.email || '', value: el?.email || '' };
    }) || [];

  useEffect(() => {
    getUsers();
  }, []);

  const handleSubmit = async () => {
    const result = await apiCall<any, any>({
      url: `/admin`,
      data: { fullName: displayName, email: selectedUser },
      method: 'POST',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      closeModal(MODAL_KEY.ADMIN_ADD);
      setSelectedUser(null);
      setDisplayName(null);
      modalProps?.callback?.();
      if (result?.isVerified === false) openModal(MODAL_KEY.NEW_PASSWORD, { account: result });
      showToastMessage('success', 'Added successfully!');
    }
  };

  const getUsers = async () => {
    const result = await apiCall<any, any>({
      url: `/users`,
      method: 'GET',
      data: {
        page: 1,
        limit: 999,
        order_by: 'fullName',
        order_direction: 'asc',
        exclude_org_id: curOrg,
        has_no_org: true,
      },
    });

    if (result?.items?.length) {
      setUsers(result.items);
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.ADMIN_ADD}
      header="Add Admin account"
      cancelButton={{ hide: true }}
      submitButton={{
        label: 'Add',
        buttonProps: { onClick: handleSubmit, disabled: !displayName || !selectedUser },
      }}
    >
      <Row direction="column" style={{ gap: 6, marginBottom: 16 }}>
        <CustomTypo variant="body-1" weight={600}>
          Email
        </CustomTypo>
        <CustomSelect
          creatable
          options={userOptions}
          placeholder="Search with name or email"
          menuPortalTarget={null}
          onChange={(val) => {
            setSelectedUser(val?.value || null);
            setDisplayName(getUser(users || [], val?.value || '')?.fullName || null);
          }}
          value={{ label: selectedUser, value: selectedUser }}
          noOptionsMessage={() => null}
          menuStyle={{ paddingTop: 0, paddingBottom: 0 }}
          indicatorsContainerStyle={{ backgroundColor: 'purple' }}
          containerStyle={{ padding: 0 }}
          components={{
            Option: (compoProps: any) => {
              const profile = getUser(users || [], compoProps?.value);

              return (
                <ReactSelectComponent.Option {...compoProps}>
                  <MemberCard
                    fullName={profile?.fullName || 'New user'}
                    email={profile?.email || compoProps?.value}
                    styles={{ width: '100%' }}
                  />
                </ReactSelectComponent.Option>
              );
            },
          }}
        />
      </Row>
      <TextInput
        title="Name"
        placeholder="Their display name"
        disabled={some(users, (el) => el?.email === selectedUser)}
        onChange={(ev) => setDisplayName(ev.target.value)}
        value={displayName || ''}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
