import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { onLogout } from 'components/layout/core/controller';
import { Button, Row, Typography, neutral } from 'enable-ui';

import { boxShadow } from 'styles/theme';

import { ReactComponent as Folder } from 'assets/icons/folder-x.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';

import { PATH } from 'constants/path';

export const NoOrg = () => {
  const navigationTo = useNavigate();

  return (
    <Container>
      <div
        style={{
          backgroundColor: '#edf6ff',
          width: '100%',
          height: '100%',
          flex: 1,
          border: '1px solid rgba(0, 0, 0, 0.15)',
          borderRadius: 16,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Row
          direction="column"
          align="center"
          style={{
            padding: '134px 72px 158px',
            width: 800,
            height: 'fit-content',
            marginTop: 24,
            backgroundColor: '#fff',
            boxShadow: boxShadow['trans-6'],
            borderRadius: 16,
          }}
        >
          <Folder className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            <p style={{ margin: 0 }}>
              It looks like you haven't been assigned to an Organisation yet.
            </p>{' '}
            {'\n'}Please reach out to your Admins.
          </Typography>
          <Button
            icon={<Logout style={{ minWidth: 20 }} />}
            placementIcon="left"
            style={{ marginTop: 40 }}
            onClick={() => {
              onLogout();
              navigationTo(PATH.LOGIN);
            }}
          >
            Log out
          </Button>
        </Row>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  padding: 8px;
`;
