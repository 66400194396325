import styled from 'styled-components';

import { RadiusConfig, Row, Spinner, neutral, primary } from 'enable-ui';

import { customConfig } from 'styles/theme';

const customPrimary = customConfig.theme?.color?.primary || primary;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 310px;
  height: fit-content;
  padding-bottom: 24px;
  background-color: ${neutral['neutral-1']};
  border-radius: ${RadiusConfig.xl}px;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 4px 40px 0px rgba(0, 64, 179, 0.04),
    0px 0.8px 6.5px 0px rgba(0, 64, 179, 0.02);

  tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 60px;
  }

  .active a {
    background: ${customPrimary['primary-1']};
    color: ${customPrimary['primary-6']};
  }
  th,
  td {
    padding: 0px 24px;
  }
  .pagination-container {
    padding: 0px 16px;
  }

  .es-paginate-info div[data-tippy-root] .dropdown-item .selected svg path {
    stroke: #003087;
  }
  .es-paginate-info div[data-tippy-root] .dropdown-item .selected {
    display: flex;
    align-items: center;
  }

  .es-paginate-info > div > div > div > div {
    width: fit-content !important;
  }

  .es-paginate-info > div > div > div > div > div {
    margin-left: 0px;
  }

  .es-paginate-info > div > div > div:first-child > div {
    border-radius: 8px;
    padding-right: 4px;
  }

  svg path[fill]:not([fill-opacity]) {
    fill: ${customPrimary['primary-6']};
  }

  .dropdown-item {
    height: 36px;
  }

  .delete-icon path {
    stroke: #da291c !important;
  }

  th:has(.export-checkbox),
  td:has(.export-checkbox) {
    padding-left: 16px;
    padding-right: 0px;

    & + th,
    & + td {
      padding-left: 8px;
    }
  }

  .export-checkbox {
    padding: 8px;

    .checkbox {
      margin: 0px;
    }
  }

  &.dt-empty table tbody {
    display: none;
  }
`;

export const TableLoadingRow = (
  <Row justify="center" align="center" style={{ flex: 1, paddingTop: 24 }}>
    <Spinner />
  </Row>
);
