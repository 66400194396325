import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { useFetchBuild, useProjectId } from '../../../core';

export const ChangeDedProcessModal = () => {
  const projectId = useProjectId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.BUILD_CHANGE_DED_PROCESS_MODAL]);
  const fetchApi = useFetchBuild();

  const buildId = modalProps?.buildId;
  const processId = modalProps?.processId;

  const orgId = useOrgId();

  const onSubmit = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projectId}/builds/${buildId}`,
      method: 'PATCH',
      data: { processId },
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      showToastMessage('success', 'Changed successfully!');
      fetchApi();
      closeModal(MODAL_KEY.BUILD_CHANGE_DED_PROCESS_MODAL);
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.BUILD_CHANGE_DED_PROCESS_MODAL}
      header="Change DED Process"
      submitButton={{
        label: 'Change',
        buttonProps: { onClick: onSubmit, color: 'primary' },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>
        All the updates you made with sections of DED Process will be lost.
      </Typography>
    </Modal>
  );
};
