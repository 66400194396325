import {
  type FieldProps,
  type PropertySelection,
  PropertyType,
  type TemplateSection,
  type TemplateSectionProperty,
} from '../type';

import { cloneDeep, isNumber, merge } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  DEFAULT_OPTION_NAME,
  DEFAULT_TITLE,
  LINKED_POWDER_INDEX,
  newPropertyPrefix,
  newSectionPrefix,
} from '../constant';

export type PropertyState = TemplateSection[][];

interface Store {
  state: PropertyState;

  // Actions
  setState: (payload: PropertyState) => void;
  setTemplateState: (templateIndex: number, payload: TemplateSection[]) => void;
  mergeState: (payload: PropertyState) => void;
  clearState: () => void;

  setPropertyState: (
    templateIndex: number,
    payload: { key: FieldProps; value: any } & PropertySelection,
  ) => void;
  removePropertyByIndex: (templateIndex: number, payload: PropertySelection) => void;
  addProperty: (templateIndex: number, sectionIndex: number, propertyIndex?: number) => void;
  addSectionVariant: (templateIndex: number, section: TemplateSection) => void;
  removeSectionVariant: (templateIndex: number, sectionIndex: number) => void;
  checkPropertyEmptyNameByIndex: (
    templateIndex: number,
    payload: PropertySelection & { originSection: TemplateSection },
  ) => void;
  toggleSectionCollapse: (templateIndex: number, sectionIndex: number) => void;
  setLinkPowders: (templateIndex: number, sectionIndex: number, powderIds: string[]) => void;
  triggerRefreshInput: (templateIndex: number) => void;
}

const initState: PropertyState = [];

export const useTemplatePropertyStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = payload;
      });
    },
    setTemplateState: (templateIndex, payload) => {
      set((store) => {
        store.state[templateIndex] = payload;
      });
    },
    mergeState: (payload) => {
      set((store) => {
        merge(store.state, payload);
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
    setPropertyState: (
      templateIndex,
      { key, value, sectionIndex, propertyIndex, itemIndex, optionIndex },
    ) => {
      set((store) => {
        switch (true) {
          case isNumber(optionIndex):
            if (key === 'name') {
              (
                store.state[templateIndex][sectionIndex].properties[propertyIndex]
                  .options as string[]
              )[optionIndex] = value;
            } else {
              store.state[templateIndex][sectionIndex].properties[propertyIndex][key] = value;
            }

            break;

          case isNumber(itemIndex):
            ((
              store.state[templateIndex][sectionIndex].properties[propertyIndex]
                .items as TemplateSectionProperty[]
            )[itemIndex][key] as any) = value;
            break;

          case key === 'files': {
            const originFiles = (
              store.state[templateIndex][sectionIndex].properties[propertyIndex].files ?? []
            ).filter((el) => el['id']);

            store.state[templateIndex][sectionIndex].properties[propertyIndex].files =
              originFiles.concat(value);
            break;
          }

          default:
            (store.state[templateIndex][sectionIndex].properties[propertyIndex][key] as any) =
              value;
        }
      });
    },
    removePropertyByIndex: (
      templateIndex,
      { sectionIndex, propertyIndex, itemIndex, optionIndex, fileIndex },
    ) => {
      set((store) => {
        switch (true) {
          // For both case checkbox & radio - optionIdex
          case isNumber(optionIndex): {
            const newOptions = cloneDeep(
              store.state[templateIndex][sectionIndex].properties[propertyIndex].options || [],
            );

            newOptions.splice(optionIndex, 1);

            store.state[templateIndex][sectionIndex].properties[propertyIndex].options = newOptions;

            const optionValue =
              store.state[templateIndex][sectionIndex].properties[propertyIndex].value ?? '';
            const values = optionValue.split(',') || [];
            const newValues = values
              .filter((val) => val !== String(optionIndex))
              .map((val) => (Number(val) >= optionIndex ? String(Number(val) - 1) : val));

            store.state[templateIndex][sectionIndex].properties[propertyIndex].value =
              newValues.join(',');
            break;
          }

          case isNumber(itemIndex): {
            const newItems = cloneDeep(
              store.state[templateIndex][sectionIndex].properties[propertyIndex].items || [],
            );

            newItems.splice(itemIndex, 1);

            store.state[templateIndex][sectionIndex].properties[propertyIndex].items = newItems;
            break;
          }

          case isNumber(fileIndex): {
            const newFiles = cloneDeep(
              store.state[templateIndex][sectionIndex].properties[propertyIndex].files || [],
            );

            newFiles.splice(fileIndex, 1);

            store.state[templateIndex][sectionIndex].properties[propertyIndex].files = newFiles;
            break;
          }

          default: {
            const newProperties = cloneDeep(
              store.state[templateIndex][sectionIndex].properties || [],
            );

            newProperties.splice(propertyIndex, 1);

            store.state[templateIndex][sectionIndex].properties = newProperties;
          }
        }
      });
    },
    addProperty: (templateIndex, sectionIndex, propertyIndex) => {
      set((store) => {
        if (isNumber(propertyIndex)) {
          store.state[templateIndex][sectionIndex].properties[propertyIndex]?.options?.push(
            'Option',
          );
        } else {
          store.state[templateIndex][sectionIndex].properties.push({
            id: newPropertyPrefix + crypto.randomUUID(),
            name: DEFAULT_TITLE,
            type: PropertyType.Text,
          });
        }
      });
    },
    addSectionVariant: (templateIndex, section: TemplateSection) => {
      set((store) => {
        store.state[templateIndex].push({
          ...section,
          id: newSectionPrefix + crypto.randomUUID(),
        });
      });
    },
    removeSectionVariant: (templateIndex, sectionIndex) => {
      set((store) => {
        const newSections = cloneDeep(store.state[templateIndex] || []);

        newSections.splice(sectionIndex, 1);

        store.state[templateIndex] = newSections;
      });
    },
    checkPropertyEmptyNameByIndex: (
      templateIndex,
      { sectionIndex, propertyIndex, optionIndex, itemIndex, originSection },
    ) => {
      set((store) => {
        switch (true) {
          case isNumber(itemIndex): {
            if (
              !store.state[templateIndex][sectionIndex].properties[propertyIndex].items?.[itemIndex]
            ) {
              break;
            }

            const itemName =
              store.state[templateIndex][sectionIndex].properties[propertyIndex].items?.[itemIndex]
                ?.name ?? '';

            if (!itemName.trim()) {
              (
                store.state[templateIndex][sectionIndex].properties[propertyIndex]
                  .items as TemplateSectionProperty[]
              )[itemIndex].name =
                originSection.properties[propertyIndex].items?.[itemIndex]?.name ?? DEFAULT_TITLE;

              (
                store.state[templateIndex][sectionIndex].properties[propertyIndex]
                  .items as TemplateSectionProperty[]
              )[itemIndex].timestamp = Date.now();
            }

            store.state[templateIndex][sectionIndex].properties[propertyIndex].timestamp =
              Date.now();
            break;
          }

          case isNumber(optionIndex): {
            if (!store.state[templateIndex][sectionIndex].properties[propertyIndex].options) {
              break;
            }

            const optionName =
              store.state[templateIndex][sectionIndex].properties[propertyIndex].options?.[
                optionIndex
              ] ?? '';

            if (!optionName.trim()) {
              (store.state[templateIndex][sectionIndex].properties[propertyIndex].options || [])[
                optionIndex
              ] =
                originSection.properties[propertyIndex].options?.[optionIndex] ??
                DEFAULT_OPTION_NAME;
            }

            store.state[templateIndex][sectionIndex].properties[propertyIndex].timestamp =
              Date.now();
            break;
          }

          case propertyIndex === LINKED_POWDER_INDEX: {
            if (!store.state[templateIndex][sectionIndex].powderIds?.length) {
              store.state[templateIndex][sectionIndex].powderIds = originSection.powderIds;
            }

            break;
          }

          default: {
            const propertyName =
              store.state[templateIndex][sectionIndex].properties[propertyIndex].name;

            if (!propertyName.trim()) {
              store.state[templateIndex][sectionIndex].properties[propertyIndex].name =
                originSection.properties[propertyIndex]?.name || DEFAULT_TITLE;
            }

            store.state[templateIndex][sectionIndex].properties[propertyIndex].timestamp =
              Date.now();
          }
        }
      });
    },
    toggleSectionCollapse: (templateIndex, sectionIndex) => {
      set((store) => {
        store.state[templateIndex][sectionIndex].expand =
          !store.state[templateIndex][sectionIndex].expand;
      });
    },
    setLinkPowders: (templateIndex, sectionIndex, powderIds) => {
      set((store) => {
        store.state[templateIndex][sectionIndex].powderIds = powderIds;
      });
    },
    triggerRefreshInput: (templateIndex) => {
      set((store) => {
        store.state[templateIndex].forEach((section, sectionIndex) => {
          section.properties.forEach((prop, propIndex) => {
            if (prop.files?.length) {
              store.state[templateIndex][sectionIndex].properties[propIndex].timestamp = Date.now();
            }
          });
        });
      });
    },
  })),
);
