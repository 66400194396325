import {
  Modal,
  Typography,
  closeModal,
  openModal,
  showToastMessage,
  useDialogStore,
} from 'enable-ui';

import { useOrgId } from 'modules/organization/core';
import { useUpdateVariants } from 'modules/project/core/controller/process-edit.controller';
import { setProcessState, useProcessStore } from 'modules/project/core/store';
import { useFetchTemplate } from 'modules/template/core';

import { apiCall } from 'hooks/service';
import { compact, flatMap, orderBy, uniqBy } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { useFetchProcess, useProjectId } from '../../../core';

export const ChangeTemplateModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.TEMPLATE_CHANGE]);
  const process = useProcessStore((s) => s.state.dedProcess);

  const newTemplate = modalProps?.newVal;
  const currentTemplate = modalProps?.currentVal;

  const projId = useProjectId();
  const orgId = useOrgId();

  const { updateVariants } = useUpdateVariants(process.id);

  const updateProcess = async () => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/${process.id}`,
      method: 'PATCH',
      data: {
        name: process?.name || '',
        dedSystemTemplateId:
          newTemplate?.type === 'DEDSystem'
            ? newTemplate?.id
            : process?.templates.find((el) => el?.type === 'DEDSystem')?.id,
        processTemplateId:
          newTemplate?.type === 'Process'
            ? newTemplate?.id
            : process?.templates.find((el) => el?.type === 'Process')?.id,
        powderTemplateIds:
          newTemplate?.type === 'Powder'
            ? [{ oldId: currentTemplate?.id || '', newId: newTemplate?.id || '' }]
            : undefined,
      },
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      const reorderTemplates = compact([
        result.templates.find((el) => el.type === 'DEDSystem'),
        ...orderBy(
          result.templates.filter((el) => el.type === 'Powder'),
          'createdAt',
          'asc',
        ),
        result.templates.find((el) => el.type === 'Process'),
      ]);

      setProcessState({
        dedProcess: { ...result, templates: reorderTemplates },
      });

      const allSections = flatMap(result?.templates.map((el) => el.sections));

      const newSections = allSections.filter((el: any) => el.selected === false);

      //check has variants
      closeModal(MODAL_KEY.TEMPLATE_CHANGE);
      showToastMessage('success', 'Changed successfully!');

      const hasNotSelected = newSections.length > 0;
      const hasVariants =
        hasNotSelected && uniqBy(newSections, 'masterId').length < newSections?.length;

      if (hasNotSelected && hasVariants) {
        openModal(MODAL_KEY.VARIANT_SELECT);
      }

      if (hasNotSelected && !hasVariants) {
        updateVariants((allSections as any) || [], newTemplate?.type, false);
      }
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.TEMPLATE_CHANGE}
      header="Change DED Process"
      cancelButton={{ hide: false }}
      submitButton={{
        label: 'Change',
        buttonProps: { onClick: updateProcess, color: 'primary' },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>
        All the updates you made with sections of DED Process will be lost.
      </Typography>
    </Modal>
  );
};
