import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';
import { isNil } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { ProjectRole, useFetchProjectMembers, useProjectStore } from '../../core';

export const RemoveMemberModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROJECT_REMOVE_MEMBER]);
  const isOwner = modalProps?.member?.role === ProjectRole.Owner;
  const curProject = useProjectStore((s) => s.state.curProject);
  const orgId = useOrgId();

  const { getProjMembers } = useFetchProjectMembers();

  const removeMember = async () => {
    if (isNil(modalProps?.member?.id)) return;

    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/projects/${curProject?.id}/members/${modalProps?.member?.id}`,
      method: 'DELETE',
      showError: true,
      isLoading: true,
    });

    if (result) {
      getProjMembers();
      showToastMessage('success', 'Removed successfully!');
      closeModal(MODAL_KEY.PROJECT_REMOVE_MEMBER);
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_REMOVE_MEMBER}
      header={isOwner ? 'Remove Project Owner' : 'Remove this member'}
      submitButton={{
        label: 'Remove',
        buttonProps: { onClick: removeMember, color: 'danger' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        After being removed, they can still view the project but are restricted from updating Builds
        and DED Processes.
      </Typography>
    </Modal>
  );
};
