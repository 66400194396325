import type { FC } from 'react';

import { CustomTypo } from 'components/CustomTypography';
import { Pill } from 'components/Pill';
import { Button, Row } from 'enable-ui';

import type { TemplateSelectionState } from 'modules/project/core';
import { getVariantName } from 'modules/project/core/helpers';
import type { TemplateSection } from 'modules/template/core';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';

import { includes } from 'lodash';

export interface VariantsSelectionProps {
  template: TemplateSelectionState;
  onSelectVariant: (variant: TemplateSection, index: number) => void;
  onToggleShow: () => void;
}

export const VariantsSelection: FC<VariantsSelectionProps> = ({
  template,
  onSelectVariant,
  onToggleShow,
}) => {
  if (!template?.selectedId) {
    return null;
  }

  const noVariant = template.sections.some((section) => section.length > 1) === false;

  return (
    <>
      {noVariant ? null : (
        <Button
          icon={
            <ArrowIcon
              style={{
                transform: template.expand ? 'rotate(180deg)' : undefined,
                transition: 'transform 0.1s',
              }}
            />
          }
          placementIcon="right"
          variant="text"
          color="primary"
          size="small"
          className="size-xs"
          style={{ marginTop: 8, marginBottom: 10 }}
          onClick={onToggleShow}
        >
          Select variant
        </Button>
      )}

      <div style={template.expand && !noVariant ? { marginBottom: 32 } : { display: 'none' }}>
        {template.sections.map((el) =>
          el.length > 1 ? (
            <>
              <CustomTypo weight={600} style={{ marginBottom: 6 }}>
                {el[0].name}
              </CustomTypo>
              <Row style={{ gap: 8, flexWrap: 'wrap', marginBottom: 16 }}>
                {el.map((variant, index) => (
                  <Pill
                    showCheckbox={variant.multipleSelected}
                    key={variant.id}
                    active={includes(template.selectedVariants, variant)}
                    onClick={() => {
                      onSelectVariant(variant, index);
                    }}
                  >
                    {getVariantName(variant, index)}
                  </Pill>
                ))}
              </Row>
            </>
          ) : null,
        )}
      </div>
    </>
  );
};
