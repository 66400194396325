import styled from 'styled-components';

import {
  BoxShadowConfig,
  RadiusConfig,
  Row,
  Tabs,
  neutral,
  neutralTrans,
  primary,
} from 'enable-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${RadiusConfig.xs}px;
  background-color: ${neutral['neutral-1']};
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  width: 260px;
  box-shadow: ${BoxShadowConfig['trans-6']};
  cursor: default;
  overflow: hidden;
  &:hover {
    background-color: ${neutral['neutral-1']};
  }
`;

export const UserAction = styled(Row)`
  border-width: 1px 0px 1px 0px;
  border-color: ${neutralTrans['neutralTrans-15']};
  border-style: solid;
  padding: 8px !important;
`;

export const Version = styled.div`
  padding: 8px 16px;
`;

export const UserActionItem = styled(Row)`
  padding: 0px 8px !important;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: ${neutralTrans['neutralTrans-6']} !important;
  }
`;

export const ProfileTabs = styled(Tabs)`
  .es-tab-item {
    height: 32px;
    width: 50%;
    justify-content: center;
  }
  .es-tab {
    border: 1px solid saddlebrown !important;
  }
  .tab-line {
    padding: 0px 24px;
    bottom: -1px;
    > div[tabposition] {
      background-color: ${primary['primary-6']};
    }
  }
`;
