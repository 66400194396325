import { useParams } from 'react-router-dom';

import { openModal, showToastMessage } from 'enable-ui';

import type { TemplateSection, TemplateType } from '../type';

import { apiCall } from 'hooks/service';
import { xor } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { templateSectionId } from '../constant';
import { useTemplateConfig } from '../helper';
import { originalSectionSelector, setTemplateState, useTemplateStore } from '../store';
import { useFetchTemplate } from './init-template.controller';

export const useTemplateSectionController = () => {
  const params = useParams<{ templateId: string; type: TemplateType }>();
  const fetchApi = useFetchTemplate();
  const { label } = useTemplateConfig();

  const curMasterSectionId = useTemplateStore((s) => s.state.curMasterSectionId);
  const masterSections = useTemplateStore((s) => s.state.sections);
  const selectingSectionIds = useTemplateStore((s) => s.state.selectingSectionIds);
  const openAddSectionDropdown = useTemplateStore((s) => s.state.openAddSectionDropdown);
  const sectionsGroupByMaster = Object.entries(useTemplateStore(originalSectionSelector));

  const displaySections: [string, TemplateSection[]][] = [
    [
      templateSectionId,
      [
        {
          id: templateSectionId,
          masterId: templateSectionId,
          name: `${label} information`,
          properties: [],
        },
      ],
    ],
    ...sectionsGroupByMaster,
  ];

  const disabledAddBtn = sectionsGroupByMaster.length === masterSections.length;
  const remainSections = masterSections.filter(
    (section) => !sectionsGroupByMaster?.some(([masterId]) => masterId === section.id),
  );

  const onRemoveSection = async (removeSections: TemplateSection[]) => {
    const result = await apiCall({
      url: `/templates/${params.templateId}/sections`,
      method: 'DELETE',
      config: { data: { sectionIds: removeSections.map((el) => el.id) } },
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      await fetchApi();
      showToastMessage('success', 'Deleted successfully!');
    }
  };

  const toggleSectionId = (id: string) => {
    setTemplateState({
      selectingSectionIds: xor(selectingSectionIds, [id]),
    });
  };

  const onCloseDropdown = () =>
    setTemplateState({
      openAddSectionDropdown: false,
      selectingSectionIds: [],
    });

  const onSubmitAddSections = async () => {
    const result = await apiCall({
      url: `/templates/${params.templateId}/sections`,
      method: 'POST',
      data: { sectionIds: selectingSectionIds },
      isLoading: true,
      showError: true,
    });

    if (result.length) {
      onCloseDropdown();
      await fetchApi();
      showToastMessage('success', 'Added successfully!');
    }
  };

  const onAddCustomSections = async () => {
    onCloseDropdown();
    openModal(MODAL_KEY.ADD_CUSTOM_SECTION, {
      templateId: params.templateId,
      callback: () => {
        fetchApi();
      },
    });
  };

  return {
    displaySections,
    remainSections,
    selectingSectionIds,
    curMasterSectionId,
    templateSectionId,

    disabledAddBtn,
    openAddSectionDropdown,

    onRemoveSection,
    toggleSectionId,
    onSubmitAddSections,
    onCloseDropdown,

    onAddCustomSections,
  };
};
