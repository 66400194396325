import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) return;
  ref.current.scrollIntoView({ behavior: 'smooth' });
};

const pageScrollElementSelector = '.pg-content';

export const scrollToPageTop = () => {
  const pageContent = document.querySelector(pageScrollElementSelector);

  pageContent?.scrollIntoView({ behavior: 'smooth' });
};

export default function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
