import { hidePageLoading, showPageLoading, showToastMessage } from 'enable-ui';

import type { TemplateSection, UpdateSectionItem } from '../type';

import { apiCall } from 'hooks/service';
import { compact, groupBy, isEqual, partition } from 'lodash';

import { newSectionPrefix } from '../constant';
import {
  cleanStateDataFromSection,
  clearIdStateFromProperty,
  haveEmptyNameProperty,
  parseSectionData,
} from '../helper';
import { useTemplatePropertyStore } from './template-property.store';

export const useTemplatePropertyController = (
  templateIndex: number,
  templateId: string,
  originSections: TemplateSection[],
  uploadTargetId: string,
  refetchCallback: () => void,
) => {
  const sections = useTemplatePropertyStore((s) => s.state[templateIndex]);
  const triggerRefreshInput = useTemplatePropertyStore((s) => s.triggerRefreshInput);
  const haveUnlinkedPowder = sections?.some(
    (section) => section.multipleSelected && !section.powderIds?.length,
  );
  const stateChanged = isEqual(cleanStateDataFromSection(sections), originSections);
  const sectionOrder = groupBy(sections, 'masterId');

  // console.log('sections', sections);
  // console.log('originSections', originSections);

  const disabled =
    haveUnlinkedPowder ||
    stateChanged ||
    sections?.some((section) => haveEmptyNameProperty(section.properties));

  const onSubmitChange = async () => {
    showPageLoading();

    const [newSections, currentSections] = partition(parseSectionData(sections), (value) =>
      value.id.startsWith(newSectionPrefix),
    );
    const updateSectionIds = currentSections.map((el) => el.id);
    const removeSections = originSections.filter(
      (el) => updateSectionIds.includes(el.id) === false,
    );

    const updateSections: TemplateSection[] = compact(
      currentSections.map((el, index) => {
        if (isEqual(el, originSections[index])) {
          return null;
        }

        const { powderIds, ...rest } = el;

        const haveChangeLinkedPowder =
          isEqual(el.powderIds, originSections[index].powderIds) === false;

        return {
          ...rest,
          powderIds: haveChangeLinkedPowder ? el.powderIds : undefined,
        };
      }),
    );

    let error = false;

    function onError() {
      error = true;
    }

    const addNewSectionResult = newSections.length
      ? await apiCall<any, { id: string }[]>({
          url: `/templates/${templateId}/sections`,
          method: 'POST',
          data: {
            sectionIds: Array(newSections.length).fill(originSections[0].id),
            // ?? master ??
          },
          onError,
        })
      : [];

    const allUpdateSections = updateSections.concat(
      addNewSectionResult.map(({ id }, index) => ({
        ...newSections[index],
        id,
      })),
    );

    const updateSectionPromises = allUpdateSections.map(async ({ id, properties, powderIds }) => {
      let newProperties = properties;

      if (properties.some((prop) => prop.files?.length)) {
        const fileUploadedPropertiesPromises = properties.map(async ({ files, ...prop }) => {
          const newFiles = files?.filter((file) => !file.id);

          if (files?.length && newFiles?.length) {
            const formData = new FormData();

            formData.append('templateOrProcessId', uploadTargetId);
            newFiles.forEach((file) => formData.append('files', file));

            const createdFiles = await apiCall<any, Array<File & { id: string }>>({
              url: `/files`,
              method: 'POST',
              data: formData,
              onError,
            });

            return {
              ...prop,
              fileIds: files
                .filter((el) => el.id)
                .concat(createdFiles)
                .map((el) => el.id ?? ''),
            };
          }

          return { ...prop, fileIds: files ? files.map((el) => el.id || '') : undefined };
        });

        newProperties = await Promise.all(fileUploadedPropertiesPromises);
      }

      return {
        properties: clearIdStateFromProperty(newProperties),
        sectionId: id,
        powderIds,
      } as UpdateSectionItem;
    });

    const updatedSections = await Promise.all(updateSectionPromises);

    await apiCall<{ sections: UpdateSectionItem[] }>({
      url: `/templates/${templateId}/sections`,
      method: 'PUT',
      data: { sections: updatedSections },
      onError,
    });

    if (removeSections.length) {
      await apiCall<any, { id: string }[]>({
        url: `/templates/${templateId}/sections`,
        method: 'DELETE',
        config: { data: { sectionIds: removeSections.map((el) => el.id) } },
        onError,
      });
    }

    hidePageLoading();

    if (error) {
      showToastMessage('error', 'Failed to save.');
    } else {
      showToastMessage('success', 'Saved successfully!');
      refetchCallback();
      triggerRefreshInput(templateIndex);
    }
  };

  return {
    sections,
    disabled,
    sectionOrder,

    onSubmitChange,
  };
};
