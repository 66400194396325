import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import { Typography, openModal, red, showToastMessage } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Reset } from 'assets/icons/reload.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

export const userColumns = (modalCallback?: () => Promise<void>, isAdminTable?: boolean) => [
  {
    id: 'email',
    Header: 'Email',
    width: '50%',
    flex: 1,
    accessor: 'email',
    sort: true,
    sortType: 'email',
    Cell: ({ ...props }) => {
      return <CustomTypo>{props.data?.[props?.row?.index || 0]?.email}</CustomTypo>;
    },
  },
  {
    id: 'fullName',
    Header: 'Name',
    accessor: 'fullName',
    width: '50%',
    flexGrow: 1,
    sort: true,
    sortType: 'fullName',
    Cell: ({ ...props }) => {
      return <CustomTypo>{props.data?.[props?.row?.index || 0]?.fullName}</CustomTypo>;
    },
  },
  {
    id: 'action',
    width: '20px',
    minWidth: '20px',
    Cell: ({ ...props }) => {
      const curOrg = useOrgId();

      return (
        <CustomDropdown
          size="small"
          TickIcon={<></>}
          menu={{
            onClick(item) {
              const resetPassword = async () => {
                if (!curOrg) return;

                const result = await apiCall({
                  url: isAdminTable
                    ? `/admin/${props.data?.[props?.row?.index || 0]?.id}/reset-password`
                    : `/organizations/${curOrg}/users/${props.data?.[props?.row?.index || 0]?.id}/reset-password`,
                  method: 'PATCH',
                  isLoading: true,
                  showError: true,
                });

                if (result) {
                  showToastMessage('success', 'Reset successfully!');
                  openModal(MODAL_KEY.NEW_PASSWORD, { account: result });
                }
              };

              if (item.key === 'reset') {
                resetPassword();
              } else {
                openModal(MODAL_KEY.MEM_DELETE, {
                  userId: props.data?.[props?.row?.index || 0]?.id,
                  callback: modalCallback,
                  isAdminTable,
                });
              }
            },
            items: [
              {
                key: 'reset',
                label: <Typography variant="body-1">Reset password</Typography>,
                prefix: <Reset />,
              },
              {
                key: 'delete',
                label: (
                  <Typography variant="body-1" color={red['red-6']}>
                    {isAdminTable ? 'Delete account' : 'Remove'}
                  </Typography>
                ),
                prefix: <Trash className="danger-ic" />,
              },
            ],
            selectedKeys: [],
          }}
          dropdownConfig={{ trigger: 'click' }}
        >
          <div className="action-icon">
            <Dots />
          </div>
        </CustomDropdown>
      );
    },
  },
];
