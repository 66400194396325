import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { hidePageLoading, showPageLoading, showToastMessage } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import type { TemplateType } from '../type';

import { useString } from 'hooks';
import { apiCall } from 'hooks/service';
import { isEqual, partition, pick } from 'lodash';

import { newSectionPrefix } from '../constant';
import {
  cleanStateDataFromSection,
  clearIdStateFromProperty,
  haveEmptyNameProperty,
  parseSectionData,
} from '../helper';
import {
  curSectionBlocksSelector,
  mergeTemplateState,
  originalSectionBlocksSelector,
  useTemplateStore,
} from '../store';
import { useFetchTemplate } from './init-template.controller';

export const useTemplateAttributeController = () => {
  const orgId = useOrgId();
  const params = useParams<{ templateId: string; type: TemplateType }>();
  const fetchTemplate = useFetchTemplate();
  const originalSectionBlocks = useTemplateStore(originalSectionBlocksSelector);
  const curSectionBlocks = useTemplateStore(curSectionBlocksSelector);
  const curMasterSectionId = useTemplateStore((s) => s.state.curMasterSectionId);
  const templateState = useTemplateStore((s) => pick(s.state.template, 'name', 'subId', 'id'));
  const stateChanged = isEqual(cleanStateDataFromSection(curSectionBlocks), originalSectionBlocks);

  const disableSaveBtn =
    stateChanged || curSectionBlocks?.some((section) => haveEmptyNameProperty(section.properties));

  const templateName = useString();
  const disabledChangeNameBtn =
    !templateName.value || templateName.value.trim() === templateState.name.trim();

  useEffect(() => {
    templateName.setValue(templateState.name);
  }, [templateState.name]);

  const onChangeTemplateName = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/templates/${params.templateId}`,
      method: 'PATCH',
      data: { name: templateName.value.trim() },
      isLoading: true,
      showError: true,
    });

    if (result.id) {
      showToastMessage('success', 'Saved successfully!');
      mergeTemplateState({
        template: {
          name: templateName.value,
        },
      });
    }
  };

  const onSubmitSectionChange = async () => {
    showPageLoading();

    const [newSections, updateSections] = partition(parseSectionData(curSectionBlocks), (value) =>
      value.id.startsWith(newSectionPrefix),
    );

    let error = false;

    const updateSectionIds = updateSections.map((el) => el.id);
    const removeSections = originalSectionBlocks.filter(
      (el) => updateSectionIds.includes(el.id) === false,
    );

    const addNewSectionResult = newSections.length
      ? await apiCall<any, { id: string }[]>({
          url: `/templates/${params.templateId}/sections`,
          method: 'POST',
          data: { sectionIds: Array(newSections.length).fill(curMasterSectionId) },
          onError: () => {
            error = true;
          },
        })
      : [];

    const allUpdateSections = updateSections.concat(
      addNewSectionResult.map(({ id }, index) => ({
        ...newSections[index],
        id,
      })),
    );

    const updateSectionPromises = allUpdateSections.map(async ({ id, properties }) => {
      let newProperties = properties;

      if (properties.some((prop) => prop.files?.length)) {
        const fileUploadedPropertiesPromises = properties.map(async ({ files, ...prop }) => {
          if (files?.length) {
            const formData = new FormData();

            const newFiles = files.filter((file) => !file.id);

            formData.append('templateOrProcessId', templateState.id);
            newFiles.forEach((file) => formData.append('files', file));

            const createdFiles = await apiCall<any, Array<File & { id: string }>>({
              url: `/files`,
              method: 'POST',
              data: formData,
              onError() {
                error = true;
              },
            });

            return {
              ...prop,
              fileIds: files
                .filter((el) => el.id)
                .concat(createdFiles)
                .map((el) => el.id ?? ''),
            };
          }

          return prop;
        });

        newProperties = await Promise.all(fileUploadedPropertiesPromises);
      }

      return apiCall({
        url: `/templates/${params.templateId}/sections/${id}`,
        method: 'PUT',
        data: { properties: clearIdStateFromProperty(newProperties) },
        onError: () => {
          error = true;
        },
      });
    });

    await Promise.all(updateSectionPromises);

    if (removeSections.length) {
      await apiCall<any, { id: string }[]>({
        url: `/templates/${params.templateId}/sections`,
        method: 'DELETE',
        config: { data: { sectionIds: removeSections.map((el) => el.id) } },
        onError: () => {
          error = true;
        },
      });
    }

    hidePageLoading();

    if (error) {
      showToastMessage('error', 'Failed to save.');
    } else {
      showToastMessage('success', 'Saved successfully!');
      await fetchTemplate();
    }
  };

  return {
    curSectionBlocks,
    templateState,
    templateName,
    disabledChangeNameBtn,
    onChangeTemplateName,
    onSubmitSectionChange,
    disableSaveBtn,
    curMasterSectionId,
  };
};
