import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core/store';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

export const DeleteTemplateModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.TEMPLATE_DELETE]);
  const curOrg = useOrgId();

  const deleteTemplate = async () => {
    if (!curOrg || !modalProps?.templateId) return;

    const result = await apiCall({
      url: `/organizations/${curOrg}/templates/${modalProps?.templateId}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      closeModal(MODAL_KEY.TEMPLATE_DELETE);
      modalProps?.callback?.();
      showToastMessage('success', 'Deleted successfully!');
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.TEMPLATE_DELETE}
      header="Delete"
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: deleteTemplate, color: 'danger' },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>
        Deleted data cannot be recovered. The DED Processes linking to this template will remain the
        unchanged.
      </Typography>
    </Modal>
  );
};
