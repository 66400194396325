import type { DefaultTemplate, Template, TemplateSection } from 'modules/template/core';

import { apiCall } from 'hooks/service';
import { type Dictionary, compact, groupBy, isNumber, orderBy, partition } from 'lodash';

export const fetchTemplates = async (orgId: string, type: string) => {
  return apiCall<any, { items: DefaultTemplate[] }>({
    url: `/organizations/${orgId}/templates`,
    method: 'GET',
    data: {
      type,
      page: 1,
      limit: 999,
      order_by: 'createdAt',
      order_direction: 'desc',
    },
  });
};

export const mappingSection = (sections: TemplateSection[]) => {
  const [withMaster, withoutMaster] = partition(sections, (el) => el.masterId !== null);

  return [
    ...Object.values(groupBy(withMaster, 'masterId')),
    ...Object.values(groupBy(withoutMaster, 'id')),
  ];
};

export const reorderTemplates = (templates: Template[]) =>
  compact([
    templates.find((el) => el.type?.toString() === 'BuildProperties'),
    templates.find((el) => el.type === 'DEDSystem'),
    ...orderBy(
      templates.filter((el) => el.type === 'Powder'),
      'createdAt',
      'asc',
    ),
    templates.find((el) => el.type === 'Process'),
  ]);

export const getVariantName = (section: TemplateSection, index?: number) =>
  section?.properties?.find((prop) => prop.isDisplayName)?.value ||
  (isNumber(index) ? `${section?.name} ${index + 1}` : section?.name);

export const getTemplateSectionDict: (
  sections: TemplateSection[],
) => Dictionary<TemplateSection[]> = (sections) => {
  const [originTemplates, customTemplates] = partition(sections, (el) => el.masterId !== null);

  const mappedSections = groupBy(originTemplates, 'masterId');

  customTemplates.forEach((el) => (mappedSections[el.id] = [el]));

  return mappedSections;
};
