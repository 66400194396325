import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const CollapsibleContent = ({
  collapsed,
  dependency,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  collapsed?: boolean;
  dependency?: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.maxHeight = collapsed ? '0' : ref.current.scrollHeight + 'px';
  }, [collapsed, dependency]);

  return <Wrapper ref={ref} {...props} />;
};

const Wrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;
