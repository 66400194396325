import { Outlet, Route, Routes } from 'react-router-dom';

import { authenSelector, useAuthStore } from 'modules/auth/core';

import { Error403Page } from './Error403Page';
import { Error404Page } from './Error404Page';
import { NoOrg } from './NoOrg';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

const AppRoutes = () => {
  const authen = useAuthStore(authenSelector);
  const { accessToken } = authen;

  return (
    <Routes>
      <Route path="/no-org" element={<NoOrg />} />
      <Route path="error/*" element={<Outlet />}>
        <Route path="404" element={<Error404Page />} />
        <Route path="403" element={<Error403Page />} />
        <Route index element={<Error404Page />} />
      </Route>
      <Route path="*" element={accessToken ? <PrivateRoutes /> : <PublicRoutes />} />
    </Routes>
  );
};

export { AppRoutes };
