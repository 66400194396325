import type { Authen, LoginRequest, UserProfile } from './type';

import { Role } from './enum';

export const LOCAL_STORAGE_AUTH_KEY = 'auth-react';

/*
  Passwords must be at least 8 characters long, at least one uppercase letter,
  one lowercase letter, one special character and one number!
*/
export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#^()+=~`{}|/:;'"<>[,.-])[A-Za-z\d@$!%*?&_#^()+=~`{}|/:;'"<>[,.-]{8,}$/;

export const defaultLoginRequest: LoginRequest = {
  email: '',
  password: '',
};

export const defaultUserProfile: UserProfile = {
  email: '',
  id: '',
};

export const defaultAuthen: Authen = {
  accessToken: '',
};
