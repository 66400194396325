import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import {
  Modal,
  Row,
  Table,
  Typography,
  formatDate,
  neutral,
  neutralTrans,
  useDialogStore,
} from 'enable-ui';

import { ReactComponent as DefaultAvatar } from 'assets/icons/Avatar.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { LogType } from '../core';
import { changeSettingLogsColumns, logDetailColumns } from '../core/logDetailColumns';

export const LogDetailModal = () => {
  const detail = useDialogStore((s) => s.state.data[MODAL_KEY.LOG_DETAIL]);

  return (
    <ModalWrapper>
      <Modal
        modalKey={MODAL_KEY.LOG_DETAIL}
        header={
          detail?.logDetails?.length
            ? `Change log of ${detail.logDetails[0]?.template}`
            : `Change log of ${detail?.objectType}`
        }
        cancelButton={{ hide: true }}
        submitButton={{ hide: true }}
      >
        <Row style={{ gap: 24 }} direction="column">
          <Row
            style={{
              padding: '0px 24px',
              position: 'sticky',
              top: 58,
              backgroundColor: neutral['neutral-1'],
            }}
          >
            <div style={{ flex: 1 }}>
              <CustomTypo weight={600}>By</CustomTypo>
              <Row direction="row" align="center" style={{ gap: 8, marginTop: 4 }}>
                <DefaultAvatar width={28} height={28} style={{ minWidth: 28 }} />
                <Row direction="column">
                  <CustomTypo>{detail?.createdBy?.fullName || <i>[Deleted account]</i>}</CustomTypo>
                  <Typography variant="body-2" color={neutral['neutral-7']}>
                    {detail?.createdBy?.email || ''}
                  </Typography>
                </Row>
              </Row>
            </div>
            <div style={{ flex: 1 }}>
              <CustomTypo weight={600}>Saved on</CustomTypo>
              <CustomTypo style={{ marginTop: 4 }}>
                {detail?.createdAt ? formatDate(detail.createdAt, 'dd MMM yyyy, hh:mm a') : ''}
              </CustomTypo>
            </div>
          </Row>
          <TableWrapper
            style={{
              minHeight: 10,
              paddingBottom: 0,
              boxShadow: 'none',
              overflow: 'auto',
            }}
          >
            {detail?.logDetails?.length ? (
              <Table
                columns={logDetailColumns()}
                dataSource={detail?.logDetails || []}
                tableKey="log-detail"
                total={detail?.logDetails?.length || 0}
                queryState={{ limit: 999, page: 1 }}
                bodyConfigs={{ isSameRowBg: true }}
                paginationConfigs={{ show: false }}
                headerConfigs={{
                  styledTr: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: neutral['neutral-1'],
                    height: 48,
                    borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
                  },
                }}
              />
            ) : null}
            {[LogType.ChangeProcessTemplate, LogType.ChangeBuildProcess].includes(detail?.type) ? (
              <Table
                columns={changeSettingLogsColumns(detail?.type)}
                dataSource={[
                  {
                    title: detail?.objectType,
                    oldContent: detail?.oldContent,
                    newContent: detail?.newContent,
                  },
                ]}
                tableKey="log-change template"
                total={1}
                queryState={{ limit: 999, page: 1 }}
                bodyConfigs={{ isSameRowBg: true }}
                paginationConfigs={{ show: false }}
                headerConfigs={{
                  styledTr: {
                    backgroundColor: 'transparent',
                    height: 48,
                    borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
                  },
                }}
              />
            ) : null}
            {detail?.type === LogType.SyncBuildProcess ? (
              <Row direction="column">
                <Typography
                  className="sync-row"
                  variant="body-2"
                  style={{ padding: '16px 24px' }}
                  color={neutral['neutral-7']}
                  weight={600}
                >
                  Action
                </Typography>
                <CustomTypo className="sync-row" variant="body-1" style={{ padding: '20px 24px' }}>
                  Sync Build with updates from DED Process
                </CustomTypo>
              </Row>
            ) : null}
          </TableWrapper>
        </Row>
      </Modal>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .es-modal {
    width: 968px;
  }

  .es-modal-header {
    position: sticky;
    top: 0px;
  }

  .es-modal-body {
    padding: 0px;
    padding-top: 8px;
  }

  .es-modal-footer-wrapper {
    padding: 12px;
  }

  .sync-row {
    width: 100%;
    border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  max-height: calc(100vh - 270px);
  overflow: auto;
  padding-bottom: 24px;
  background-color: ${neutral['neutral-1']};
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 4px 40px 0px rgba(0, 64, 179, 0.04),
    0px 0.8px 6.5px 0px rgba(0, 64, 179, 0.02);

  tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    min-height: 44px;
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;
  }
  th:last-child,
  td:last-child {
    padding-right: 24px;
  }
  .pagination-container {
    padding-left: 16px;
  }
`;
