import { useNavigate } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { TableLoadingRow, TableWrapper } from 'components/TableWrapper';
import {
  Button,
  Row,
  Spinner,
  Table,
  TextInput,
  Typography,
  updateQueryStateTable,
} from 'enable-ui';

import { neutral, neutralTrans } from 'styles/theme';

import { ReactComponent as Export } from 'assets/icons/download.svg';
import { ReactComponent as Map } from 'assets/icons/map.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

import { mappingPath } from 'helpers';

import { PATH } from 'constants/path';

import { processTableKey, useProcessesManagerController } from '../../core';
import { processColumns } from '../../core/ProcessColumns';
import { AddButton } from '../AddButton';

export const ProcessManager = () => {
  const navigate = useNavigate();
  const {
    orgId,
    projId,
    rowItems,
    queryState,
    total,
    onDeleteCallback,
    hasNoData,
    isLoading,
    isFirstLoad,
    isAllowAdd,
    isAllowExport,
    duplicateProcess,
    onSearchChange,
    searchValue,
    exportData,
    fetchData,
  } = useProcessesManagerController();
  const { exportLabel, onExport, onImport } = exportData;

  const renderContent = () => {
    if (isFirstLoad) {
      return TableLoadingRow;
    }

    if (!isLoading && !rowItems.length) {
      return queryState?.search ? (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Search className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            There is no result matching your search
          </Typography>
        </Row>
      ) : (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Map className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']} style={{ marginBottom: 24 }}>
            There is no DED Process yet.
          </Typography>

          {isAllowAdd && (
            <AddButton
              addParams={{
                label: 'Add a DED Process',
                path: PATH.PROJECT_DED_PROCESS_ADD,
              }}
              importParams={{
                label: 'Import',
                handleImport: onImport,
              }}
            />
          )}
        </Row>
      );
    }

    return (
      <>
        <Table
          dataSource={isLoading ? [] : rowItems}
          columns={processColumns(
            onDeleteCallback,
            duplicateProcess,
            isAllowAdd,
            isAllowExport,
            exportData,
            fetchData,
          )}
          queryState={queryState}
          updateQueryState={updateQueryStateTable}
          tableKey={processTableKey}
          total={total}
          paginationConfigs={{ showRowsPerPage: true, showTotal: true, show: !isLoading }}
          headerConfigs={{
            styledTr: {
              backgroundColor: 'transparent',
              height: 48,
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
            },
          }}
          onClickRow={(row) => {
            navigate(
              mappingPath(PATH.PROJECT_DED_PROCESS_EDIT, [
                orgId || '',
                projId || '',
                row?.original?.id || '',
              ]),
            );
          }}
          bodyConfigs={{ isSameRowBg: true }}
          // tableStyle={{ display: isNil(processes) ? 'none' : undefined }}
        />
        {isLoading ? TableLoadingRow : null}
      </>
    );
  };

  return (
    <TableWrapper className={isLoading || !rowItems.length ? 'dt-empty' : ''}>
      <Row style={{ padding: 24 }} justify="space-between" align="center">
        <CustomTypo variant="h3" weight={500}>
          DED Processes
        </CustomTypo>

        {!hasNoData && (
          <Row style={{ width: 'fit-content', gap: 8, display: hasNoData ? 'none' : undefined }}>
            {isAllowExport && rowItems.length ? (
              <Button icon={<Export />} placementIcon="left" onClick={onExport}>
                {exportLabel}
              </Button>
            ) : null}

            {isAllowAdd ? (
              <AddButton
                addParams={{
                  label: 'Add a DED Process',
                  path: PATH.PROJECT_DED_PROCESS_ADD,
                }}
                importParams={{
                  label: 'Import',
                  handleImport: onImport,
                }}
              />
            ) : null}
          </Row>
        )}
      </Row>

      {!hasNoData && (
        <Row style={{ padding: '0px 24px' }} justify="start" align="center">
          <TextInput
            size="default"
            containerStyle={{ width: 320 }}
            prefix={<Search />}
            placeholder="Search with ID or process name"
            onChange={onSearchChange}
            value={searchValue}
          />
        </Row>
      )}

      {renderContent()}
    </TableWrapper>
  );
};
