import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { useOrgId } from 'modules/organization/core';
import { getTemplateSectionDict } from 'modules/project/core/helpers';

import type { Template, TemplateSection, TemplateType } from '../type';

import { apiCall } from 'hooks/service';

import { TEMPLATE_CONFIG, templateSectionId } from '../constant';
import { clearTemplateState, setTemplateState, useTemplateStore } from '../store';

export const useInitTemplateSections = () => {
  const match = useMatch('/org/:orgId/templates/:type/*');

  useEffect(() => {
    const fetchApi = async () => {
      if (!match?.params?.type) return;

      const results = await apiCall<any, { items: TemplateSection[] }>({
        url: `/sections`,
        method: 'GET',
        config: {
          params: {
            type: match?.params?.type,
            page: 1,
            limit: 999,
            order_by: 'order',
            order_direction: 'asc',
          },
        },
      });

      if (results.items) {
        useTemplateStore.getState().setState({
          sections: results.items,
        });
      }
    };

    fetchApi();
  }, [match?.params?.type]);
};

export const useFetchTemplate = (templateId?: string) => {
  const orgId = useOrgId();
  const params = useParams<{ templateId: string; type: TemplateType }>();

  const fetchApi = async () => {
    const result = await apiCall<any, Template>({
      url: `/organizations/${orgId}/templates/${params.templateId || templateId}`,
      method: 'GET',
      isLoading: true,
    });

    if (result.id) {
      const curMasterSectionId = useTemplateStore.getState().state.curMasterSectionId;
      const selectIdRemoved =
        curMasterSectionId !== templateSectionId &&
        !result.sections.some(
          (section) => section.masterId === curMasterSectionId || section.id === curMasterSectionId,
        );

      const sections = getTemplateSectionDict(result.sections);

      setTemplateState({
        template: result,
        curSections: sections,
        curMasterSectionId: selectIdRemoved ? templateSectionId : curMasterSectionId,
      });
    }
  };

  return fetchApi;
};

export const useInitTemplateController = () => {
  const fetchApi = useFetchTemplate();
  const params = useParams<{ templateId: string; type: TemplateType }>();
  const name = useTemplateStore((s) => s.state.template.name);

  useEffect(() => {
    fetchApi();

    return () => clearTemplateState();
  }, []);

  return { config: TEMPLATE_CONFIG[params.type || 'DEDSystem'], name };
};
