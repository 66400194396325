import { Button, TextInput } from 'enable-ui';

import { LoginContainer, LoginFooter, LoginHeader } from '../styles';

import { useController } from '../core';

export const LoginPage = () => {
  const { handleSubmit, register, registerError } = useController();

  return (
    <LoginContainer>
      <LoginHeader variant="h4" weight={500}>
        Log in to ARTC Experiment Logger
      </LoginHeader>

      <form
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <TextInput
          {...register('email')}
          {...registerError('email')}
          title="Email"
          placeholder=""
          containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />

        <TextInput
          {...register('password')}
          {...registerError('password')}
          title="Password"
          type="password"
          placeholder=""
          containerStyle={{ maxWidth: '100%' }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              !registerError('password').helperText && handleSubmit();
            }
          }}
        />

        <LoginFooter>
          <Button size="large" color="primary" style={{ width: '100%' }} onClick={handleSubmit}>
            Log in
          </Button>
        </LoginFooter>
      </form>
    </LoginContainer>
  );
};
