import styled from 'styled-components';

import { neutralTrans } from 'enable-ui';

export const Container = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  .user-item:hover {
    background-color: ${neutralTrans['neutralTrans-6']};
    svg,
    p {
      background-color: transparent !important;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
`;
