import { useEffect, useRef } from 'react';

import { Radio, type RadioProps } from 'enable-ui';

import { ContentEditable, EditableOptionItem } from './styles';

interface Props extends RadioProps {
  editableContentProps: React.HTMLAttributes<HTMLSpanElement> & { content: string };
  dependencies?: any[];
}

export const EditableRadio = ({
  children,
  editableContentProps,
  dependencies,
  ...radioProps
}: Props) => {
  const nameRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.textContent = editableContentProps.content;
    }
  }, dependencies || []);

  return (
    <EditableOptionItem onClick={(event) => event.stopPropagation()}>
      <Radio {...radioProps}>
        <ContentEditable
          ref={nameRef}
          {...editableContentProps}
          style={{ fontWeight: 400 }}
          suppressContentEditableWarning
        ></ContentEditable>
      </Radio>

      {children}
    </EditableOptionItem>
  );
};
