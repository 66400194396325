import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CustomDropdown } from 'components/CustomDropdown';
import { Row, type RowProps, Typography, openModal } from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';
import { useOrgId } from 'modules/organization/core';
import { type TemplateType } from 'modules/template/core';

import { red } from 'styles/theme';

import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { mappingPath } from 'helpers';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

interface Props extends RowProps {
  title: string;
}

export const ContentHeader: FC<Props> = ({ title, children, ...props }) => {
  const navigate = useNavigate();
  const orgId = useOrgId();
  const params = useParams<{ templateId: string; type: TemplateType }>();
  const readOnly = useIsAdmin() === false;

  return (
    <Wrapper justify="space-between" {...props}>
      <div>
        <Typography variant="h3" weight={500}>
          {title}
        </Typography>

        {children}
      </div>

      {readOnly ? null : (
        <CustomDropdown
          size="small"
          menu={{
            items: [
              {
                key: 'delete',
                label: (
                  <Typography variant="body-1" color={red['red-6']}>
                    Delete
                  </Typography>
                ),
                prefix: <Trash className="danger-ic" />,
              },
            ],
            selectedKeys: [],
            onClick: ({ key }) => {
              if (key === 'delete') {
                openModal(MODAL_KEY.TEMPLATE_DELETE, {
                  templateId: params.templateId,
                  callback: () =>
                    navigate(mappingPath(PATH.TEMPLATES, [orgId, params.type || '']), {
                      replace: true,
                    }),
                });
              }
            },
          }}
        >
          <div className="action-icon">
            <Dots />
          </div>
        </CustomDropdown>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  margin-bottom: 16px;
`;
