import type { Authen, UserProfile } from './type';

import { includes, pick } from 'lodash';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { LOCAL_STORAGE_AUTH_KEY, defaultAuthen, defaultUserProfile } from './constant';
import { Role } from './enum';

interface State {
  userProfile: UserProfile;
  authen: Authen;
}

interface Actions {
  updateUserProfile(payload: UserProfile): void;
  clearUserProfile(): void;
  updateAuthen(payload: Authen): void;
  clearAuthen(): void;
  clearAuthState(): void;
}

interface Store extends Actions {
  state: State;
}

const initState: State = {
  userProfile: defaultUserProfile,
  authen: defaultAuthen,
};

export const useAuthStore = create(
  persist(
    immer<Store>((set) => ({
      state: initState,
      updateUserProfile: (payload) => {
        set((store) => {
          store.state.userProfile = payload;
        });
      },
      clearUserProfile: () => {
        set((store) => {
          store.state.userProfile = defaultUserProfile;
        });
      },
      updateAuthen: (payload) => {
        set((store) => {
          store.state.authen = payload;
        });
      },
      clearAuthen: () => {
        set((store) => {
          store.state.authen = defaultAuthen;
        });
      },
      clearAuthState: () => {
        set((store) => {
          store.state = initState;
        });
      },
    })),
    {
      name: LOCAL_STORAGE_AUTH_KEY,
      // To-do fix persist not correct
      partialize: (store: Store) => ({
        state: pick(store.state, 'authen'),
      }),
    },
  ),
);

export const { updateUserProfile, updateAuthen, clearUserProfile, clearAuthen, clearAuthState } =
  useAuthStore.getState();

export const userProfileSelector = (s: Store) => s.state.userProfile;

export const authenSelector = (s: Store) => s.state.authen;
export const useIsAdmin = () =>
  useAuthStore((s) => includes([Role.Admin, Role.SubAdmin], s.state?.userProfile?.role?.key));

export const useIsSuperAdmin = () =>
  useAuthStore((s) => s.state?.userProfile?.role?.key === Role.Admin);
