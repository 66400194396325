import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Row, Typography, type TypographyProps } from 'enable-ui';

import { type DefaultTemplate, TEMPLATE_CONFIG } from 'modules/template/core';

import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg';

interface Props {
  template?: DefaultTemplate;
  placeholder?: string;
  typoProps?: TypographyProps;
}

export const TemplateInfo = ({
  template,
  placeholder,
  typoProps,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & Props) => {
  return (
    <Wrapper {...props}>
      <span className={template ? 'active' : ''}>
        <TemplateIcon />
      </span>
      {template ? (
        <div style={{ maxWidth: 'calc(100% - 42px)' }}>
          <CustomTypo variant="body-2" style={{ color: '#8C8C8C' }}>
            {template.subId}
          </CustomTypo>
          <CustomTypo style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {template.name}
          </CustomTypo>
        </div>
      ) : (
        <Typography {...typoProps}>{placeholder || 'Select template'} </Typography>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  align-items: center;
  p {
    text-align: left;
  }
  > span {
    height: 32px;
    width: 32px;
    border-radius: var(--radius-6, 6px);
    padding: 5px;
    margin-right: 10px;
    border: 1px solid var(--border-neutral-subtle-default, rgba(0, 0, 0, 0.1));
    background: var(--surface-neutral-neutral-default, #f0f0f0);

    &.active {
      border: 1px solid var(--golden-purple-3, #d3adf7);
      background: var(--surface-accent-subtle-purple, #f9f0ff);

      svg path {
        stroke: #722ed1;
      }
    }
  }
`;
