import { styled } from 'styled-components';

import { primary } from 'styles/theme';

import { screenBreak } from 'hooks';

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${primary['primary-1']};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
`;

const AuthBody = styled.div`
  margin-top: 24px;
  border: 1px solid #f4f4f4;
  border-radius: 16px;
  padding: 24px;
  width: 400px;
  background: #fff;

  ${screenBreak('md')} {
    width: calc(100% - 32px);
  }
`;

export { AuthContainer, AuthBody };
