import styled from 'styled-components';

import { borderRadius, boxShadow, neutral } from 'styles/theme';

export const CardWrapper = styled.div`
  padding: 24px 0px;
  border-radius: ${borderRadius.md}px;
  width: 100%;
  height: fit-content;
  background-color: ${neutral['neutral-1']};
  box-shadow: ${boxShadow['trans-6']};
`;
