import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { ModalCard } from 'components/layout/components/ModalCard';
import { Button, IconClose, Row, TextInput, openModal, red } from 'enable-ui';

import { useIsAdmin } from 'modules/auth/core';
import {
  PropertyType,
  type TemplateSectionProperty,
  addTemplateSectionPropertyState,
  addTemplateVariantState,
  checkPropertyEmptyNameByIndex,
  removeTemplatePropertyByIndex,
  removeTemplateVariantState,
  setPropertyState,
  useTemplateAttributeController,
  useTemplateConfig,
} from 'modules/template/core';

import { SectionBlockWrapper } from './styles';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-thick.svg';

import type { EditableFieldPermission } from './type';

import { isNil } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { EditableField } from './EditableField';

export const SectionAttributeCard = () => {
  const {
    templateState,
    curSectionBlocks,
    templateName,
    disabledChangeNameBtn,
    onChangeTemplateName,
    onSubmitSectionChange,
    disableSaveBtn,
    curMasterSectionId,
  } = useTemplateAttributeController();
  const { label } = useTemplateConfig();

  const isAdmin = useIsAdmin();

  const fieldPermission: EditableFieldPermission = {
    fieldRemovable: isAdmin,
    fieldLockable: isAdmin,

    valueEditable: isAdmin,
    titleEditable: isAdmin,
    optionEditable: isAdmin,

    showDisabledField: !isAdmin,
  };

  const renderProperty =
    (sectionIndex: number) => (property: TemplateSectionProperty, propertyIndex: number) => {
      if (property.type === PropertyType.Widget) {
        return (
          <article key={propertyIndex}>
            <EditableField
              {...property}
              permission={fieldPermission}
              id={`${sectionIndex}-${property.id}`}
              key={property.id}
              dependencies={[curMasterSectionId]}
              onChange={(key, value) =>
                setPropertyState({
                  propertyIndex,
                  sectionIndex,
                  key,
                  value,
                })
              }
              onRemove={() => {
                openModal(MODAL_KEY.FIELD_REMOVE, {
                  callback: () =>
                    removeTemplatePropertyByIndex({
                      propertyIndex,
                      sectionIndex,
                    }),
                });
              }}
            />
            {property.items?.map((propertyItem, propertyItemIndex) => (
              <EditableField
                {...propertyItem}
                permission={fieldPermission}
                id={`${sectionIndex}-${property.id}`}
                key={propertyItem.id}
                dependencies={[curMasterSectionId, propertyItem.timestamp]}
                onChange={(key, value) =>
                  setPropertyState({
                    propertyIndex,
                    itemIndex: propertyItemIndex,
                    sectionIndex,
                    key,
                    value,
                  })
                }
                onRemove={() => {
                  openModal(MODAL_KEY.FIELD_REMOVE, {
                    callback: () =>
                      removeTemplatePropertyByIndex({
                        propertyIndex,
                        sectionIndex,
                        itemIndex: propertyItemIndex,
                      }),
                  });
                }}
                onBlur={(index) => {
                  checkPropertyEmptyNameByIndex({
                    propertyIndex,
                    sectionIndex,
                    itemIndex: propertyItemIndex,
                    ...index,
                  });
                }}
              />
            ))}
          </article>
        );
      }

      return (
        <EditableField
          {...property}
          permission={fieldPermission}
          id={`${sectionIndex}-${property.id}`}
          key={property.id}
          dependencies={[curMasterSectionId, property.timestamp]}
          onChange={(key, value, optionIndex) =>
            setPropertyState({
              propertyIndex,
              sectionIndex,
              optionIndex,
              key,
              value,
            })
          }
          onRemove={(index) => {
            if (index) {
              removeTemplatePropertyByIndex({
                propertyIndex,
                sectionIndex,
                ...index,
              });
            } else {
              openModal(MODAL_KEY.FIELD_REMOVE, {
                callback: () =>
                  removeTemplatePropertyByIndex({
                    propertyIndex,
                    sectionIndex,
                  }),
              });
            }
          }}
          onAddSubValue={() => {
            addTemplateSectionPropertyState(sectionIndex, propertyIndex);
          }}
          onBlur={(index) => {
            checkPropertyEmptyNameByIndex({
              propertyIndex,
              sectionIndex,
              ...index,
            });
          }}
        />
      );
    };

  if (!curSectionBlocks) {
    return (
      <ContentWrapper
        title={`${label} information`}
        titleProps={{
          className: 'h3-5-medium text-ellipsis-1',
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <CustomTypo weight={600}>{label} ID</CustomTypo>
          <CustomTypo style={{ marginTop: 4 }}>{templateState.subId}</CustomTypo>
        </div>
        <div>
          <TextInput
            title={`${label} name`}
            value={templateName.value}
            onChange={(event) => templateName.setValue(event.target.value)}
            containerStyle={isAdmin ? {} : { pointerEvents: 'none' }}
          />
        </div>

        {isAdmin && (
          <Button
            color="primary"
            size="large"
            style={{ marginTop: 24, width: 84 }}
            disabled={disabledChangeNameBtn}
            onClick={onChangeTemplateName}
          >
            Save
          </Button>
        )}
      </ContentWrapper>
    );
  }

  return (
    <SectionBlockWrapper>
      {curSectionBlocks.map((sectionBlock, sectionIndex) => (
        <section key={sectionIndex}>
          <CustomTypo
            variant="h3"
            weight={500}
            className="h3-5-medium text-ellipsis-1"
            style={{ maxWidth: 'calc(100% - 26px)' }}
          >
            {sectionBlock.name} {curSectionBlocks.length > 1 ? sectionIndex + 1 : ''}
          </CustomTypo>

          {isAdmin && sectionIndex > 0 && (
            <Button
              icon={IconClose}
              placementIcon="left"
              className="section-rm-btn"
              variant="text"
              onClick={() => {
                openModal(MODAL_KEY.FIELD_REMOVE, {
                  callback: () => removeTemplateVariantState(sectionIndex),
                });
              }}
            />
          )}

          {sectionBlock.properties.map(renderProperty(sectionIndex))}

          {isAdmin ? (
            <Button
              variant="text"
              color="primary"
              icon={<PlusIcon />}
              placementIcon="left"
              style={{ height: 32, gap: 6 }}
              onClick={() => addTemplateSectionPropertyState(sectionIndex)}
            >
              Add text field
            </Button>
          ) : null}
        </section>
      ))}

      {isAdmin ? (
        <Row style={{ gap: 8, marginTop: 40 }}>
          <Button
            color="primary"
            size="large"
            onClick={onSubmitSectionChange}
            disabled={disableSaveBtn}
          >
            Save
          </Button>
          {!isNil(curSectionBlocks[0].masterId) ? (
            <Button
              size="large"
              placementIcon="left"
              icon={<PlusIcon />}
              onClick={() => addTemplateVariantState()}
            >
              Add {curSectionBlocks[0].name}
            </Button>
          ) : null}
        </Row>
      ) : null}
    </SectionBlockWrapper>
  );
};

const ContentWrapper = styled(ModalCard)`
  width: 640px;
  margin: 0 0 0 12px;
`;
