import type { ActivityLog } from 'modules/log/core';

import type { Organization } from './type';

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  orgs: Organization[];
  curOrg?: Organization;

  actionLog: {
    items: ActivityLog[];
    count: number;
    page: number;
  };
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
  renameOrgState: (payload: Organization) => void;
  addOrgState: (payload: Organization) => void;
  removeOrgState: (payload: Organization) => void;
}

const initState: State = {
  orgs: [],
  actionLog: {
    count: 0,
    items: [],
    page: 1,
  },
};

export const useOrgStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    renameOrgState: (payload) => {
      set((store) => {
        store.state.curOrg = payload;
        store.state.orgs = store.state.orgs.map((org) => (org.id === payload.id ? payload : org));
      });
    },
    addOrgState: (payload) => {
      set((store) => {
        store.state.curOrg = payload;
        store.state.orgs.push(payload);
      });
    },
    removeOrgState: (payload) => {
      set((store) => {
        store.state.orgs = store.state.orgs.filter((el) => el.id !== payload.id);
        store.state.curOrg = store.state.orgs[0];
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const {
  setState: setOrgState,
  clearState: clearOrgState,
  renameOrgState,
  addOrgState,
  removeOrgState,
} = useOrgStore.getState();

export const useOrgName = () => useOrgStore((s) => s.state.curOrg?.name || '');
export const useOrgId = () => useOrgStore((s) => s.state.curOrg?.id || '');
