import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useOrgStore } from 'modules/organization/core/store';

import { SidebarMenu } from './components/SidebarMenu';

export const MasterLayout = () => {
  const firstCreate = useOrgStore((store) => store.state.orgs.length === 0);

  if (firstCreate) {
    return null;
  }

  return (
    <Wrapper>
      <nav>
        <SidebarMenu />
      </nav>

      <div className="pg-content">
        <div className="pg-card-wrapper">
          <section>
            <Outlet />
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 248px;
    background: #fff;
    z-index: 1;
  }

  .pg-content {
    margin-left: 248px;
    padding: 8px 8px 8px 0;
    height: 100vh;
  }

  .pg-card-wrapper {
    border-radius: 16px;
    border: 1px solid var(--border-neutral-subtle-default, rgba(0, 0, 0, 0.1));
    height: 100%;
    > section {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
`;
