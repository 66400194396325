import styled from 'styled-components';

import { Typography } from 'enable-ui';

export const CustomTypo = styled(Typography)`
  color: #222222;

  &.h3-5-medium {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
`;
