import { CustomTypo } from 'components/CustomTypography';
import { RadiusConfig, Row, Tooltip, Typography, neutral } from 'enable-ui';

import { useAuthStore, useIsAdmin, userProfileSelector } from 'modules/auth/core';

import { Container } from '../styles/menu-footer';

import { ReactComponent as DefaultAvatar } from 'assets/icons/Avatar.svg';

import { useBoolean } from 'hooks';

import { PopoverUserProfile } from './PopoverUserProfile';

export const MenuFooter: React.FC<{ isResponsive?: boolean }> = ({ isResponsive }) => {
  const userProfile = useAuthStore(userProfileSelector);
  const isAdmin = useIsAdmin();

  const visible = useBoolean(false);

  return (
    <Container>
      <Tooltip
        bg="transparent"
        trigger="click"
        arrow={false}
        placement="right-end"
        content={isResponsive ? null : <PopoverUserProfile setVisible={visible.setValue} />}
        visible={visible.value}
        offset={[0, 0]}
        allowHTML
        interactive
        onClickOutside={() => visible.setValue(false)}
      >
        <Row
          className="user-profile"
          align="center"
          onClick={() => visible.setValue((prev) => !prev)}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: RadiusConfig.xs,
          }}
        >
          <DefaultAvatar style={{ minWidth: 34 }} />
          <Row direction="column" style={{ alignItems: 'start' }}>
            <div style={{ width: '100%', display: 'flex' }}>
              <CustomTypo
                variant="body-1"
                weight={400}
                style={{
                  textAlign: 'start',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  marginLeft: 8,
                  maxWidth: '10rem',
                }}
              >
                {userProfile?.fullName}
              </CustomTypo>
            </div>
            {!isAdmin ? null : (
              <div style={{ width: '100%', display: 'flex' }}>
                <Typography
                  variant="body-2"
                  weight={400}
                  color={neutral['neutral-7']}
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginLeft: 8,
                    overflow: 'hidden',
                    maxWidth: '10rem',
                  }}
                >
                  {userProfile?.role?.name === 'Admin' ? 'Super Admin' : 'Admin'}
                </Typography>
              </div>
            )}
          </Row>
        </Row>
      </Tooltip>
    </Container>
  );
};
