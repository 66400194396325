import type { QueryState } from 'enable-ui';

export const FILE_SIZE_10_MB = 10 * 1024 * 1024;

export const initialQueryState = Object.freeze<QueryState>({
  page: 1,
  limit: 10,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
  search: '',
});
