import { useNavigate } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { TableLoadingRow, TableWrapper } from 'components/TableWrapper';
import { PageContent, PageHeader } from 'components/layout';
import {
  Button,
  Row,
  Spinner,
  Table,
  TextInput,
  Typography,
  updateQueryStateTable,
} from 'enable-ui';

import { processTableKey } from 'modules/project/core/controller';

import { neutral, neutralTrans } from 'styles/theme';

import { ReactComponent as Map } from 'assets/icons/map.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

import { mappingPath } from 'helpers';

import { PATH } from 'constants/path';

import { processColumns } from '../core/columns';
import { useProcessesManagerController } from '../core/controller';

export const ProcessSearch = () => {
  const navigate = useNavigate();
  const {
    orgId,
    rowItems,
    queryState,
    total,
    onDeleteCallback,
    hasNoData,
    isLoading,
    isFirstLoad,
    isAllowAdd,
    duplicateProcess,
    onSearchChange,
    searchValue,
    fetchData,
  } = useProcessesManagerController();

  const fetchDataUrl = orgId ? `/organizations/${orgId}/processes` : '';

  const renderContent = () => {
    if (isFirstLoad) {
      return TableLoadingRow;
    }

    if (!isLoading && !rowItems.length) {
      return queryState?.search ? (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Search className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            There is no result matching your search
          </Typography>
        </Row>
      ) : (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Map className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']} style={{ marginBottom: 24 }}>
            There is no DED Process yet.
          </Typography>
        </Row>
      );
    }

    return (
      <>
        <Table
          dataSource={isLoading ? [] : rowItems}
          columns={processColumns(onDeleteCallback, duplicateProcess, isAllowAdd, fetchData)}
          queryState={queryState}
          updateQueryState={updateQueryStateTable}
          tableKey={processTableKey}
          total={total}
          paginationConfigs={{ showRowsPerPage: true, showTotal: true, show: !isLoading }}
          headerConfigs={{
            styledTr: {
              backgroundColor: 'transparent',
              height: 48,
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
            },
          }}
          onClickRow={(row) => {
            navigate(
              mappingPath(PATH.PROJECT_DED_PROCESS_EDIT, [
                orgId || '',
                row?.original?.project?.id || '',
                row?.original?.id || '',
              ]),
            );
          }}
          bodyConfigs={{ isSameRowBg: true }}
          // tableStyle={{ display: isNil(processes) ? 'none' : undefined }}
        />
        {isLoading ? TableLoadingRow : null}
      </>
    );
  };

  return (
    <>
      <PageHeader title="Process search" />
      <PageContent>
        <TableWrapper className={isLoading || !rowItems.length ? 'dt-empty' : ''}>
          <Row style={{ padding: 24 }} justify="space-between" align="center">
            <CustomTypo variant="h3" weight={500}>
              Advanced DED Process search
            </CustomTypo>
          </Row>

          {!hasNoData && (
            <Row style={{ padding: '12px 24px' }} justify="center" align="center">
              <TextInput
                size="default"
                containerStyle={{ width: 500 }}
                prefix={<Search />}
                placeholder="Powder name, DED system model, and chemical composition"
                onChange={onSearchChange}
                value={searchValue}
              />
            </Row>
          )}

          {renderContent()}
        </TableWrapper>
      </PageContent>
    </>
  );
};
