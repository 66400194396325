import { useNavigate } from 'react-router-dom';

import { Modal, TextInput, closeModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { useOrgId } from 'modules/organization/core/store';

import { mappingPath } from 'helpers';
import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import type { Project } from '../../core';
import { addProjectState } from '../../core/store/basic.store';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const AddProjectModal = () => {
  const orgId = useOrgId();
  const navigate = useNavigate();

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async (values) => {
      const result = await apiCall<any, Project>({
        url: `/organizations/${orgId}/projects`,
        data: values,
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.PROJECT_ADD);
        addProjectState(result);
        showToastMessage('success', 'Added successfully!');
        navigate(mappingPath(PATH.PROJECT_DED_PROCESSES, [orgId, result.id]));
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_ADD}
      header="Add Project"
      cancelButton={{ hide: true }}
      submitButton={{
        label: 'Add',
        buttonProps: { onClick: handleSubmit, disabled: !isValid },
      }}
    >
      <TextInput
        title="Project name"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
