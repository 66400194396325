import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core/store';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

export const DeleteMemberModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.MEM_DELETE]);
  const curOrg = useOrgId();

  const deleteUser = async () => {
    if (!curOrg || !modalProps?.userId) return;

    const result = await apiCall({
      url: modalProps?.isAdminTable
        ? `/admin/${modalProps?.userId}`
        : `/organizations/${curOrg}/users/${modalProps?.userId}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      closeModal(MODAL_KEY.MEM_DELETE);
      modalProps?.callback?.();
      showToastMessage('success', 'Removed successfully!');
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.MEM_DELETE}
      header={modalProps.isAdminTable ? 'Delete account' : 'Remove from organization'}
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: deleteUser, color: 'danger' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        {modalProps.isAdminTable
          ? 'Their account will be permanently deleted. They will no longer have access to this organization.'
          : ' They will no longer have access to this organization.'}
      </Typography>
    </Modal>
  );
};
