import { Modal, TextInput, closeModal, showToastMessage, useDialogStore } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { useOrgId } from 'modules/organization/core';
import { useProjectId } from 'modules/project/core';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const DuplicateProcessBuildModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROCESS_BUILD_DUPLICATE]);

  const { isBuild, id, callback } = modalProps || {};

  const type = isBuild ? 'builds' : 'processes';
  const title = isBuild ? 'Name of the new Build' : 'Name of the new DED Process';

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async ({ name }) => {
      const result = await apiCall<any, any>({
        url: `/organizations/${orgId}/projects/${projId}/${type}/${id}/copy`,
        data: {
          name,
        },
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.PROCESS_BUILD_DUPLICATE);
        showToastMessage('success', 'Duplicated successfully!');
        callback();
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.PROCESS_BUILD_DUPLICATE}
      header="Duplicate"
      submitButton={{
        label: 'Duplicate',
        buttonProps: { onClick: handleSubmit, disabled: !isValid },
      }}
    >
      <TextInput
        title={title}
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
