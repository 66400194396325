import { styled } from 'styled-components';

import { Typography } from 'enable-ui';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoginHeader = styled(Typography)`
  text-align: center;
  margin-bottom: 40px;
`;

export const LoginFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  button {
    width: 50%;
  }
`;
