import { useMatch } from 'react-router-dom';

import { useOrgId } from 'modules/organization/core';

import { type ActivityLog, LogObjectType } from './type';

import { apiCall } from 'hooks/service';

import { ACTION_LOG_LIMIT, CHANGE_LOG_LIMIT } from './constant';
import { useActivityLogStore } from './store';

const getActivityLogUrlByType = (type: LogObjectType, orgId: string, targetId?: string) => {
  switch (type) {
    case LogObjectType.Process:
      return `/organizations/${orgId}/processes/${targetId}/logs`;

    case LogObjectType.Build:
      return `/organizations/${orgId}/builds/${targetId}/logs`;

    case LogObjectType.Project:
      return `/organizations/${orgId}/projects/${targetId}/logs`;

    case LogObjectType.Organization:
      return `/organizations/${orgId}/logs`;
  }
};

export const useFetchActivityLog = (type: LogObjectType) => {
  const orgId = useOrgId();
  const match = useMatch('/org/:orgId/projects/:projectId/:type/:targetId/*');
  const matchProject = useMatch('/org/:orgId/projects/:projectId/*');
  const targetId = match?.params?.targetId || matchProject?.params?.projectId || '';

  const fetchApi = async (loadMore?: boolean) => {
    const newPage = useActivityLogStore.getState().state.changeLogs[type].page + (loadMore ? 1 : 0);

    const result = await apiCall<any, { items: ActivityLog[]; count: number }>({
      url: getActivityLogUrlByType(type, orgId, targetId),
      method: 'GET',
      config: {
        params: {
          limit: [LogObjectType.Build, LogObjectType.Process].includes(type)
            ? CHANGE_LOG_LIMIT
            : ACTION_LOG_LIMIT,
          page: newPage,
          types: [],
        },
      },
      isLoading: true,
    });

    if (result.items) {
      useActivityLogStore.getState().setLogs(type, {
        count: result.count,
        items: result.items,
        page: newPage,
      });
    }
  };

  return fetchApi;
};
