import { CustomTypo } from 'components/CustomTypography';
import { Button, Row, Tabs, Tooltip, Typography, primary } from 'enable-ui';

import {
  BUILD_INFO_MENU_ITEMS,
  useEditBuildController,
  useProjectPermission,
} from 'modules/project/core';
import { reorderTemplates } from 'modules/project/core/helpers';
import { useProjectStore } from 'modules/project/core/store';
import { useBuildStore } from 'modules/project/core/store/build.store';
import { TEMPLATE_CONFIG } from 'modules/template/core';
import { useTemplatePropertyStore } from 'modules/template/core/controller/template-property.store';

import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';

import { omit, pick } from 'lodash';

import { ProcessSelectInput } from '../builds/ProcessSelectInput';
import { ProjectContentWrapper } from '../ded-process/ProcessMenu';

export const ProjectBuildMenu = () => {
  const process = useBuildStore((s) => omit(s.state.build.process, 'templates'));
  const templates = useBuildStore((s) => reorderTemplates(s.state.build.process.templates) || []);
  const selectedIndex = useBuildStore((s) => s.state.selectedIndex);
  const setState = useBuildStore((s) => s.setState);
  const processes = useProjectStore((s) => s.state.dedProcesses);
  const { onChangeDedProcess, onSyncClick, onSaveClick } = useEditBuildController();
  const { isMember } = useProjectPermission();

  const curTemplate = templates[Number(selectedIndex)];

  return (
    <ProjectContentWrapper title="Build information" titleProps={{ variant: 'h5' }}>
      <Tabs
        variant="text"
        tabPosition="vertical"
        className="section"
        items={BUILD_INFO_MENU_ITEMS.map((el) => ({
          children: '',
          key: el.key,
          label: (
            <Typography
              color={selectedIndex === el.key ? primary['primary-6'] : undefined}
              weight={selectedIndex === el.key ? 500 : undefined}
              className="text-ellipsis-1"
            >
              {el.label}
            </Typography>
          ),
        }))}
        activeKey={selectedIndex}
        onTabClick={(key) => {
          setState({ selectedIndex: key });
        }}
      />

      <div className="section-divider" style={{ height: 24 }} />

      <section>
        <div style={{ position: 'relative', paddingBottom: 8 }}>
          <CustomTypo variant="h5" weight={500} className="text-ellipsis-1">
            DED Process
          </CustomTypo>

          <ProcessSelectInput
            processes={processes.filter((el) => el.id !== process.parentId)}
            value={{ value: process }}
            onChange={(option: any) => {
              onChangeDedProcess(option.value?.id || '');
            }}
            isDisabled={!isMember}
          />
        </div>

        {templates.length ? (
          <Tabs
            variant="text"
            tabPosition="vertical"
            className="section"
            items={templates
              .filter((el) => el.type?.toString() !== 'BuildProperties')
              .map((el, index) => ({
                children: '',
                key: String(index + 1),
                label:
                  el.type?.toString() === 'BuildProperties' ? null : (
                    <Typography
                      color={selectedIndex === String(index + 1) ? primary['primary-6'] : undefined}
                      weight={selectedIndex === String(index + 1) ? 500 : undefined}
                      className="text-ellipsis-1"
                    >
                      {el.type ? TEMPLATE_CONFIG[el.type]?.processTitle : ''}{' '}
                      {templates.length > 3 && index >= 2 ? index - 1 : ''}
                    </Typography>
                  ),
              }))}
            activeKey={selectedIndex + 1}
            onTabClick={(key) => {
              setState({ selectedIndex: key });

              // Clear current template state
              if (curTemplate) {
                useTemplatePropertyStore
                  .getState()
                  .setTemplateState(Number(selectedIndex), curTemplate.sections || []);
              }
            }}
          />
        ) : null}
      </section>
    </ProjectContentWrapper>
  );
};
