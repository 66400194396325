import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Pill } from 'components/Pill';
import { Button, Modal, Typography, closeModal, showToastMessage } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';
import { useProcessStore } from 'modules/project/core/store';

import { apiCall } from 'hooks/service';
import { cloneDeep, isNil, some, xor } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { useFetchProcess, useProjectId } from '../../../core';
import { TemplateInfo } from '../../TemplateInfo';

const CustomModal = styled(Modal)`
  .es-modal-body {
    max-height: 500px;
    overflow: auto;
  }
`;

export const PowderLinkModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const process = useProcessStore((s) => s.state.dedProcess);
  const fetchApi = useFetchProcess();

  const powderFeeders = process.templates
    .find((el) => el?.type === 'DEDSystem')
    ?.sections?.filter((el) => el?.multipleSelected || el?.name === 'Powder feeder');
  const originalLinks: Record<string, string[]> = {};

  powderFeeders?.forEach((el) => (originalLinks[el?.id] = el?.powderIds || []));

  const powders = process?.templates?.filter((el) => el?.type === 'Powder');
  const [links, setLinks] = useState<Record<string, string[]>>(originalLinks);

  useEffect(() => {
    if (process)
      setLinks(() => {
        powderFeeders?.forEach((el) => (originalLinks[el?.id] = el?.powderIds || []));

        return originalLinks;
      });
  }, [process]);

  const postLinks = async () => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/${process?.id}/powders`,
      method: 'PATCH',
      data: {
        linkPowders: Object.entries(links).map(([key, value]) => ({
          powderFeederId: key,
          powderIds: value,
        })),
      },
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      closeModal(MODAL_KEY.PROCESS_LINK_POWDER);
      fetchApi(process?.id);
      showToastMessage('success', 'Saved successfully!');
    }
  };

  const isDisable = some(Object.values(links), (el) => el?.length === 0);

  return (
    <CustomModal
      modalKey={MODAL_KEY.PROCESS_LINK_POWDER}
      footer={
        !isNil(links) ? (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={isDisable}
            style={{ margin: '0px 24px 24px 24px' }}
            onClick={postLinks}
          >
            Save
          </Button>
        ) : null
      }
      header="Link Powder Feeder with Powder"
      hideCloseIcon
      closeOnClickOutside={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {powderFeeders?.map((el, index) => (
          <div key={el?.id} style={{ marginBottom: 16 }}>
            <Typography variant="body-1" weight={600} style={{ marginBottom: 6 }}>
              {el?.name}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              {powders?.map((powder) => {
                return (
                  <Pill
                    active={links[el?.id]?.includes(powder?.id)}
                    showCheckbox
                    key={powder?.id}
                    style={{
                      width: '100%',
                      padding: 8,
                      height: 'fit-content',
                      borderRadius: 8,
                    }}
                    onClick={() => {
                      setLinks((prev) => {
                        const newLink = cloneDeep(prev);

                        newLink[el?.id] = xor(newLink[el?.id], [powder?.id]);

                        return newLink;
                      });
                    }}
                  >
                    <TemplateInfo template={powder} />
                  </Pill>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};
