import styled from 'styled-components';

import { NavigationBar, Row, Typography, openModal, primary } from 'enable-ui';

import { OrganizationMenu } from 'modules/organization/components';

import { neutral, neutralTrans } from 'styles/theme';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Logo from 'assets/images/logo.png';

import { MODAL_KEY } from 'constants/modal-key';

import { useSideBarController } from '../core/controller';
import { MenuFooter } from './MenuFooter';

const NavigationWrapper = styled.div`
  padding: 8px 0px;
  padding-left: 0px;
  height: 100%;
  .es-navbar-body {
    max-height: calc(100vh - 216px);
    overflow: auto;
    display: block;
    padding-right: 8px;

    > div:not([barmode]) {
      width: 100%;
      padding: 8px;
      padding-top: 24px;
      p {
        text-align: left;
      }
    }

    > div[barmode] > div > div {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }

  .nav-item {
    padding: 0px 8px;
    svg g[id='folder'] path[id='Vector'] {
      fill: white !important;
    }
  }

  .nav-item:not(.active) {
    color: #222222;
    svg path {
      fill: ${neutral['neutral-7']};
      stroke: ${neutral['neutral-7']};
    }
  }

  .nav-item.active {
    svg path:not([id='Subtract']) {
      fill: ${primary['primary-6']};
      stroke: ${primary['primary-6']};
    }
  }

  .nav-item:not(.active):hover {
    svg path {
      fill: ${neutral['neutral-11']};
      stroke: ${neutral['neutral-11']};
    }
  }

  .admin-icon path {
    fill: transparent !important;
  }
`;

export const SidebarMenu = () => {
  const { locationPathName, menus, logoNavigate } = useSideBarController();

  return (
    <NavigationWrapper>
      <NavigationBar
        header={
          <div style={{ paddingBottom: 4, width: '100%' }}>
            <div
              style={{
                height: 60,
                width: '100%',
                marginLeft: 8,
                backgroundImage: `url("${Logo}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                cursor: 'pointer',
              }}
              onClick={logoNavigate}
            />
            <OrganizationMenu locationPathname={locationPathName} />
          </div>
        }
        containerStyle={{ paddingLeft: 8 }}
        menuItems={menus}
        mode="vertical"
        width="100%"
        height="100%"
        locationPathName={locationPathName}
        footer={<MenuFooter />}
      />
    </NavigationWrapper>
  );
};

const LabelWrapper = styled(Row)`
  > div {
    height: 28px;
    width: 28px;
    padding: 4px;
    transform: translateX(6px);
    border-radius: 8px;
    &:hover {
      cursor: pointer;
      background-color: ${neutralTrans['neutralTrans-10']};
    }
    &:active {
      background-color: ${neutralTrans['neutralTrans-15']};
    }
  }
`;

export const CustomProjectLabel = () => {
  return (
    <LabelWrapper justify="space-between" align="center">
      <Typography variant="body-2" weight={700} color="#8c8c8c">
        PROJECTS
      </Typography>

      <div onClick={() => openModal(MODAL_KEY.PROJECT_ADD)}>
        <PlusIcon />
      </div>
    </LabelWrapper>
  );
};
