import type { FC } from 'react';
import styled from 'styled-components';

import { Row, type RowProps, Typography } from 'enable-ui';

interface Props extends RowProps {
  title?: string;
}

export const PageHeader: FC<Props> = ({ title, children, ...props }) => {
  return (
    <Wrapper justify="space-between" align="center" {...props}>
      {title ? (
        <Typography variant="h5" weight={500}>
          {title}
        </Typography>
      ) : null}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  height: 60px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0 24px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow:
    0px 4px 40px 0px rgba(0, 64, 179, 0.04),
    0px 0.8px 6.5px 0px rgba(0, 64, 179, 0.02);
`;
