import { Modal, TextInput, closeModal, showToastMessage, useDialogStore } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const AddCustomSectionModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.ADD_CUSTOM_SECTION]);
  const { templateId, callback } = modalProps || {};

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async (values) => {
      const result = await apiCall({
        url: `/templates/${templateId}/sections/custom`,
        method: 'POST',
        data: { sectionName: values.name },
        isLoading: true,
        showError: true,
      });

      if (result.id) {
        closeModal(MODAL_KEY.ADD_CUSTOM_SECTION);
        showToastMessage('success', 'Added successfully!');
        callback?.();
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.ADD_CUSTOM_SECTION}
      header="Add Custom section"
      cancelButton={{ hide: true }}
      submitButton={{
        label: 'Add',
        buttonProps: { onClick: handleSubmit, disabled: !isValid },
      }}
    >
      <TextInput
        title="Section name"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
