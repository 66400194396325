import { ContentWrapper, PageContent } from 'components/layout';
import { Row } from 'enable-ui';

import { ChangeLogDrawer } from 'modules/log/components/ChangeLogDrawer';
import { LogObjectType } from 'modules/log/core';

import { ProcessAndBuildHeader } from '../components/ded-process/ProcessAndBuildHeader';
import { ProjectBuildMenu } from '../components/project-build/ProjectBuildMenu';
import { ProjectBuildSection } from '../components/project-build/ProjectBuildSection';
import { useInitBuildController } from '../core/controller/build-edit.controller';

export const EditBuildPage = () => {
  const name = useInitBuildController();

  return (
    <PageContent>
      <ContentWrapper>
        <ProcessAndBuildHeader title={name} isBuild />

        <Row>
          <ProjectBuildMenu />

          <ProjectBuildSection />
        </Row>
      </ContentWrapper>

      <ChangeLogDrawer type={LogObjectType.Build} />
    </PageContent>
  );
};
