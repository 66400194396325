import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { useProjectId } from '../../../core';

export const DeleteProcessModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROCESS_DELETE]);

  const deleteProcess = async () => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/${modalProps?.processId}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result === true) {
      closeModal(MODAL_KEY.PROCESS_DELETE);
      modalProps?.callback?.();
      showToastMessage('success', 'Deleted successfully!');
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PROCESS_DELETE}
      header="Delete this DED Process"
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: deleteProcess, color: 'danger' },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>
        Deleted data cannot be recovered. The Builds linking to this DED Process will remain the
        same.
      </Typography>
    </Modal>
  );
};
