import { Modal, Typography, closeModal, useDialogStore } from 'enable-ui';

import { MODAL_KEY } from 'constants/modal-key';

export const DeleteFieldModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.FIELD_REMOVE]);

  const handleSubmit = () => {
    if (!modalProps) return;

    modalProps.callback?.();
    closeModal(MODAL_KEY.FIELD_REMOVE);
  };

  return (
    <Modal
      modalKey={MODAL_KEY.FIELD_REMOVE}
      header="Delete"
      submitButton={{
        label: 'Delete',
        buttonProps: { onClick: handleSubmit, color: 'danger' },
      }}
    >
      <Typography>Any changes you've made will not be saved if you delete this.</Typography>
    </Modal>
  );
};
