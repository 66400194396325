import type { DeepPartial } from 'enable-ui';

import { getTemplateSectionDict } from 'modules/project/core/helpers';

import {
  type FieldProps,
  type PropertySelection,
  PropertyType,
  type Template,
  type TemplateSection,
  type TemplateSectionProperty,
} from './type';

import { type Dictionary, cloneDeep, isNumber, merge } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  DEFAULT_OPTION_NAME,
  DEFAULT_TITLE,
  defaultTemplate,
  newPropertyPrefix,
  newSectionPrefix,
  templateSectionId,
} from './constant';

interface State {
  // Base state (From API)
  template: Template;
  sections: TemplateSection[]; // master sections
  // originalSections: using originalSectionBlocksSelector on below

  // Add section dropdown
  selectingSectionIds: string[];
  openAddSectionDropdown: boolean;

  // Attribute/Property state
  curMasterSectionId: string;
  curSections: Dictionary<TemplateSection[]>; // Group by master
}

interface Store {
  state: State;

  setState: (payload: Partial<State>) => void;
  mergeState: (payload: DeepPartial<State>, noMerge?: boolean) => void;
  clearState: () => void;
  resetSectionState: () => void;
  setPropertyState: (payload: { key: FieldProps; value: any } & PropertySelection) => void;
  removeTemplatePropertyByIndex: (payload: PropertySelection) => void;
  addTemplateSectionPropertyState: (sectionIndex: number, propertyIndex?: number) => void;
  addTemplateVariantState: () => void;
  removeTemplateVariantState: (sectionIndex: number) => void;
  checkPropertyEmptyNameByIndex: (payload: PropertySelection) => void;
}

const initState: State = {
  sections: [],
  curSections: {},
  template: defaultTemplate,
  curMasterSectionId: templateSectionId,
  selectingSectionIds: [],
  openAddSectionDropdown: false,
};

export const useTemplateStore = create(
  immer<Store>((set) => ({
    state: initState,
    mergeState: (payload) => {
      set((store) => {
        store.state = merge(store.state, payload);
      });
    },
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = { ...initState, sections: store.state.sections };
      });
    },
    resetSectionState: () => {
      set((store) => {
        store.state.curSections = getTemplateSectionDict(store.state.template.sections);
      });
    },
    setPropertyState: ({ key, value, sectionIndex, propertyIndex, itemIndex, optionIndex }) => {
      set((store) => {
        switch (true) {
          case isNumber(optionIndex):
            if (key === 'name') {
              ((
                store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                  propertyIndex
                ].options as string[]
              )[optionIndex] as any) = value;
            } else {
              (store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ][key] as any) = value;
            }

            break;

          case isNumber(itemIndex):
            ((
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].items as TemplateSectionProperty[]
            )[itemIndex][key] as any) = value;
            break;

          case key === 'files': {
            const originFiles = (
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].files ?? []
            ).filter((el) => el['id']);

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ].files = originFiles.concat(value);
            break;
          }

          default:
            (store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ][key] as any) = value;
        }
      });
    },
    removeTemplatePropertyByIndex: ({
      sectionIndex,
      propertyIndex,
      itemIndex,
      optionIndex,
      fileIndex,
    }) => {
      set((store) => {
        switch (true) {
          // For both case checkbox & radio - optionIdex
          case isNumber(optionIndex): {
            const newOptions = cloneDeep(
              curSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex].options || [],
            );

            newOptions.splice(optionIndex, 1);

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ].options = newOptions;

            const optionValue =
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].value ?? '';
            const values = optionValue.split(',') || [];
            const newValues = values
              .filter((val) => val !== String(optionIndex))
              .map((val) => (Number(val) >= optionIndex ? String(Number(val) - 1) : val));

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ].value = newValues.join(',');
            break;
          }

          case isNumber(itemIndex): {
            const newItems = cloneDeep(
              curSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex].items || [],
            );

            newItems.splice(itemIndex, 1);

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ].items = newItems;
            break;
          }

          case isNumber(fileIndex): {
            const newFiles = cloneDeep(
              curSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex].files || [],
            );

            newFiles.splice(fileIndex, 1);

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
              propertyIndex
            ].files = newFiles;
            break;
          }

          default: {
            const newProperties = cloneDeep(
              curSectionBlocksSelector(store)[sectionIndex].properties || [],
            );

            newProperties.splice(propertyIndex, 1);

            store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties =
              newProperties;
          }
        }
      });
    },
    addTemplateSectionPropertyState: (sectionIndex, propertyIndex) => {
      set((store) => {
        if (isNumber(propertyIndex)) {
          store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
            propertyIndex
          ]?.options?.push('Option');
        } else {
          store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties.push({
            id: newPropertyPrefix + crypto.randomUUID(),
            name: DEFAULT_TITLE,
            type: PropertyType.Text,
          });
        }
      });
    },
    addTemplateVariantState: () => {
      set((store) => {
        const masterProperties =
          store.state.sections.find((el) => el.id === store.state.curMasterSectionId)?.properties ||
          [];

        store.state.curSections[store.state.curMasterSectionId].push({
          id: newSectionPrefix + crypto.randomUUID(),
          masterId: store.state.curMasterSectionId,
          name: store.state.curSections[store.state.curMasterSectionId][0].name,
          properties: masterProperties,
        });
      });
    },
    removeTemplateVariantState: (sectionIndex) => {
      set((store) => {
        const newSections = cloneDeep(curSectionBlocksSelector(store) || []);

        newSections.splice(sectionIndex, 1);

        store.state.curSections[store.state.curMasterSectionId] = newSections;
      });
    },
    checkPropertyEmptyNameByIndex: ({ sectionIndex, propertyIndex, optionIndex, itemIndex }) => {
      set((store) => {
        switch (true) {
          case isNumber(itemIndex): {
            if (
              !store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].items?.[itemIndex]
            ) {
              break;
            }

            const itemName =
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].items?.[itemIndex]?.name ?? '';

            if (!itemName.trim()) {
              (
                store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                  propertyIndex
                ].items as TemplateSectionProperty[]
              )[itemIndex].name =
                originalSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex]
                  .items?.[itemIndex]?.name ?? DEFAULT_TITLE;

              (
                store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                  propertyIndex
                ].items as TemplateSectionProperty[]
              )[itemIndex].timestamp = Date.now();
            }

            break;
          }

          case isNumber(optionIndex): {
            if (
              !store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].options
            ) {
              break;
            }

            const optionName =
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].options?.[optionIndex] ?? '';

            if (!optionName.trim()) {
              (store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].options || [])[optionIndex] =
                originalSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex]
                  .options?.[optionIndex] ?? DEFAULT_OPTION_NAME;
            }

            break;
          }

          default: {
            const propertyName =
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].name;

            if (!propertyName.trim()) {
              store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
                propertyIndex
              ].name =
                originalSectionBlocksSelector(store)[sectionIndex].properties[propertyIndex]
                  ?.name || DEFAULT_TITLE;
            }
          }
        }

        store.state.curSections[store.state.curMasterSectionId][sectionIndex].properties[
          propertyIndex
        ].timestamp = Date.now();
      });
    },
  })),
);

export const {
  setState: setTemplateState,
  clearState: clearTemplateState,
  mergeState: mergeTemplateState,
  resetSectionState: resetTemplateSectionState,
  setPropertyState,
  removeTemplatePropertyByIndex,
  addTemplateSectionPropertyState,
  addTemplateVariantState,
  removeTemplateVariantState,
  checkPropertyEmptyNameByIndex,
} = useTemplateStore.getState();

export const useTemplateSectionStore = () => useTemplateStore((s) => s.state.sections);

export const originalSectionSelector = (store: Store) => {
  return getTemplateSectionDict(store.state.template.sections);
};

// Section blocks = Sections group by master id
export const originalSectionBlocksSelector = (store: Store) =>
  originalSectionSelector(store)[store.state.curMasterSectionId];

// Grouped by master
export const curSectionBlocksSelector = (store: Store) =>
  store.state.curSections[store.state.curMasterSectionId];

export const filteredSectionBlockSelector =
  (props: PropertySelection | undefined) => (store: Store) => {
    if (!props)
      return {
        properties: [],
        variantId: '',
      };

    const { sectionIndex, propertyIndex } = props;

    const curSectionBlocks = curSectionBlocksSelector(store);

    const properties = cloneDeep(curSectionBlocks[sectionIndex].properties) || [];

    properties.splice(propertyIndex, 1);

    return { properties, variantId: curSectionBlocks[sectionIndex].id };
  };
