import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Button, IconClose, Row, type TypographyProps } from 'enable-ui';

import { CardWrapper } from '../styles';

export const ModalCard = ({
  title,
  children,
  onClose,
  showCloseIcon,
  titleProps,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
  titleProps?: TypographyProps;
}) => {
  const navigate = useNavigate();

  const onCloseClick = () => {
    if (onClose) {
      return onClose();
    }

    navigate(-1);
  };

  return (
    <ModalWrapper {...props}>
      <Row justify="space-between" className="mdl-heading">
        <CustomTypo variant="h3" weight={500} {...titleProps}>
          {title}
        </CustomTypo>
        {showCloseIcon ? (
          <Button icon={IconClose} placementIcon="left" variant="text" onClick={onCloseClick} />
        ) : null}
      </Row>

      {children}
    </ModalWrapper>
  );
};

const ModalWrapper = styled(CardWrapper)`
  width: 640px;
  margin: auto;
  padding: 24px;

  .mdl-heading {
    padding-bottom: 24px;
    button {
      transform: translate(12px, -12px);
      height: 38px;
      width: 38px;
    }
  }
`;
