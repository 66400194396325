import { PageContent } from 'components/layout';
import { ModalCard } from 'components/layout/components/ModalCard';
import { Button, TextInput, Typography, neutral } from 'enable-ui';

import { TEMPLATE_CONFIG, type TemplateType } from 'modules/template/core';

import { pick } from 'lodash';

import { ProcessSection } from '../components/ded-process/ProcessSection';
import { useAddProcessController } from '../core/controller/add-process.controller';
import {
  selectProcessSectionVariantByType,
  toggleExpandProcessVariantByType,
  useProcessStore,
} from '../core/store';

export const AddProcessPage = () => {
  const {
    disabled,
    isMissingPowderFeeder,
    onSubmit,
    powderFeederCount,
    selectedTemplateByPowder,
    onSelectTemplateByType,
  } = useAddProcessController();

  const processName = useProcessStore((s) => s.state.processName);
  const templates = useProcessStore((s) => s.state.templates);
  const state = useProcessStore((s) => pick(s.state, 'DEDSystem', 'Process', 'Powder'));
  const setState = useProcessStore((s) => s.setState);

  const renderProcessSectionByType = (type: TemplateType, index: number = 0) => {
    return (
      <ProcessSection
        key={type === 'Powder' ? state[type][index]?.selectedId || index : undefined}
        title={`${TEMPLATE_CONFIG[type].processTitle} ${type === 'Powder' && powderFeederCount > 1 ? index + 1 : ''}`}
        templates={templates[type]}
        selectedTemplateIds={selectedTemplateByPowder}
        templateId={
          type === 'Powder' ? state[type][index]?.selectedId || '' : state[type].selectedId
        }
        onSelectTemplate={(template) => {
          onSelectTemplateByType(type, template?.id, index);
        }}
        template={type === 'Powder' ? state[type][index] : state[type]}
        onSelectVariant={(variant, variantIndex) =>
          selectProcessSectionVariantByType(type, variant, index, variantIndex)
        }
        onToggleShow={() => toggleExpandProcessVariantByType(type, index)}
        customPlaceholder={`Select ${TEMPLATE_CONFIG[type].label.toLowerCase()}`}
      />
    );
  };

  return (
    <PageContent>
      <ModalCard title="Add DED process" showCloseIcon style={{ height: 'fit-content' }}>
        <TextInput
          title="Process name"
          containerStyle={{ marginBottom: 16 }}
          value={processName}
          onChange={(event) =>
            setState({
              processName: event.target.value,
            })
          }
        />

        {renderProcessSectionByType('DEDSystem')}

        {new Array(powderFeederCount)
          .fill(null)
          .map((_v, index) => renderProcessSectionByType('Powder', index))}

        {renderProcessSectionByType('Process')}

        <Typography variant="body-1" color={neutral['neutral-7']}>
          The maximum number of Powder you can select depends on the number of Powder Feeders
          selected in the DED System Model.
        </Typography>

        {isMissingPowderFeeder ? (
          <Typography
            variant="body-1"
            weight={500}
            style={{ padding: '16px 0px 0px', color: '#DA291C' }}
          >
            Each section must select at least 1 variant.
          </Typography>
        ) : null}

        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={disabled}
          onClick={onSubmit}
          style={{ marginTop: 24 }}
        >
          Add DED Process
        </Button>
      </ModalCard>
    </PageContent>
  );
};
