import { type DeepPartial, type ThemeContextProps, themeConfig } from 'enable-ui';

import { merge } from 'lodash';

export const customConfig: DeepPartial<ThemeContextProps> = {
  theme: {
    boxShadow: {
      'trans-6': `0px 4px 40px 0px rgba(0, 64, 179, 0.04), 0px 0.8px 6.5px 0px rgba(0, 64, 179, 0.02)`,
    },
    color: {
      neutral: {
        'neutral-11': '#222222',
      },
      primary: {
        'primary-1': '#EDF6FF',
        'primary-2': '#6C757D',
        'primary-3': '#6C757D',
        'primary-4': '#6C757D',
        'primary-5': '#184794',
        'primary-6': '#003087',
        'primary-7': '#001F61',
        'primary-8': '#00113B',
        'primary-9': '#000514',
        'primary-10': '#000000',
      },
      red: {
        'red-1': '#FFF3F0',
        'red-2': '#FFD0C4',
        'red-3': '#FFAC9C',
        'red-4': '#F27F6D',
        'red-5': '#E65343',
        'red-6': '#DA291C',
        'red-7': '#B3140E',
        'red-8': '#8C0404',
        'red-9': '#660003',
        'red-10': '#400004',
      },
    },
    buttonSize: {
      padding: {
        medium: '4px 12px',
        large: '0 24px',
      },
      height: {
        medium: '38px',
      },
    },
    typography: {
      h4: {
        height: 26, // Not working, how about line-height?
      },
    },
  },
};

export const theme = (merge(themeConfig, customConfig) as ThemeContextProps).theme;

export const {
  avatarItem,
  borderRadius,
  boxShadow,
  buttonSize,
  buttonContained,
  buttonOutline,
  buttonText,
  dropdownItem,
  input,
  select,
  modal,
  navigationBar,
  spacing,
  tagSize,
  typography,
  switchSize,
  color,
} = theme;

export const {
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
  neutral,
  neutralTrans,
  primary,
  primaryTrans,
} = color;
