import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { openModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import {
  type UserProfile,
  clearAuthState,
  clearUserProfile,
  updateUserProfile,
  useAuthStore,
  useIsAdmin,
  userProfileSelector,
} from 'modules/auth/core';
import { clearOrgState, useOrgId, useOrgStore } from 'modules/organization/core/store';
import { clearProjectState, useProjectStore } from 'modules/project/core';
import { TEMPLATE_CONFIG } from 'modules/template/core';

import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg';
import { ReactComponent as UserIcon } from 'assets/icons/users.svg';

import type {
  MenuItem,
  NavigationBarProps,
} from 'enable-ui/components/Navigation/NavigationBar/core/types';

import { mappingPath } from 'helpers';
import { apiCall } from 'hooks/service/api';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { CustomProjectLabel } from '../components/SidebarMenu';
import { nameSchema, passwordSchema } from './constants';

export const useSideBarController = () => {
  const userProfile = useAuthStore(userProfileSelector);
  const orgId = useOrgStore((store) => store.state.curOrg?.id || '');
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (userProfile?.id) {
      return;
    }

    getUserProfile();
  }, [userProfile?.id]);

  const getUserProfile = async () => {
    const result: UserProfile = await apiCall({
      url: `/user-profile`,
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      updateUserProfile(result);
    }

    if (result?.isVerified === false) {
      openModal(MODAL_KEY.UPDATE_PASSWORD);
    }
  };

  const onClickMenuItem = (item: MenuItem) => navigate(item.path);

  const memberMenu: MenuItem = {
    label: 'Members',
    icon: <UserIcon />,
    path: mappingPath(PATH.MEMBERS, [orgId]),
    onClickMenuItem,
  };

  const templateMenu: MenuItem[] = Object.entries(TEMPLATE_CONFIG).map(([type, label]) => ({
    label: label.navbar,
    icon: <TemplateIcon />,
    path: mappingPath(PATH.TEMPLATES, [orgId, type]),
    onClickMenuItem,
  }));

  const projectMenu: MenuItem = {
    label: 'Projects',
    icon: <FolderIcon />,
    path: mappingPath(PATH.PROJECTS, [orgId]),
    onClickMenuItem: (item) => {
      navigate(item.path);
    },
    pathActive: [mappingPath(PATH.PROJECTS, [orgId])],
  };

  const ProcessSearch: MenuItem = {
    label: 'Process Search',
    icon: <MapIcon />,
    path: mappingPath(PATH.SEARCH, [orgId]),
    onClickMenuItem: (item) => {
      navigate(item.path);
    },
    pathActive: [mappingPath(PATH.SEARCH, [orgId])],
  };
  const menus: NavigationBarProps['menuItems'] = {
    Manage: isAdmin ? [memberMenu, projectMenu, ProcessSearch] : [projectMenu, ProcessSearch],
    Templates: templateMenu,
    // To-do-design: Custom header (Add Icon)
    // To-do-design: Overflow, scroll in section
  };

  const logoNavigate = () => {
    navigate(isAdmin ? '/' : mappingPath(PATH.TEMPLATES, [orgId, 'DEDSystem']));
  };

  return {
    locationPathName: location.pathname,
    menus,
    navigate,
    logoNavigate,
  };
};

export enum ProfileTab {
  profile = 'profile',
  password = 'password',
}

export const useProfileController = () => {
  const orgId = useOrgId();
  const location = useLocation();
  const userProfile = useAuthStore(userProfileSelector);
  const navigate = useNavigate();

  const activeTab = location.pathname.includes('my-profile')
    ? ProfileTab.profile
    : ProfileTab.password;

  const setActiveTab = (key: string) => {
    navigate(
      mappingPath(key === ProfileTab.password ? PATH.CHANGE_PASSWORD : PATH.USER_PROFILE, [orgId]),
    );
  };

  const nameForm = useFormManager({
    defaultValues: {
      fullName: userProfile?.fullName ?? '',
    },
    schema: nameSchema,
    onSubmit: async (values) => {
      const result = await apiCall<any, any>({
        url: '/user-profile',
        data: values,
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        updateUserProfile(result);
        showToastMessage('success', 'Saved successfully!');
      }
    },
  });

  const passwordForm = useFormManager({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    schema: passwordSchema,
    onSubmit: async (values) => {
      const result = await apiCall<any, any>({
        url: '/user-profile/password',
        data: values,
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        showToastMessage('success', 'Saved successfully!');
        passwordForm?.form?.reset?.();
      }
    },
  });

  useEffect(() => {
    nameForm.form.setValue('fullName', userProfile?.fullName ?? '');

    return () => {
      nameForm.form.setValue('fullName', userProfile?.fullName ?? '');
    };
  }, [JSON.stringify(userProfile)]);

  return { nameForm, passwordForm, activeTab, setActiveTab };
};

export const onLogout = () => {
  clearAuthState();
  clearUserProfile();
  clearOrgState();
  clearProjectState();
};
