import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'constants/path';

import { AuthLayout } from './components';
import { LoginPage } from './pages';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={PATH.LOGIN} element={<LoginPage />} />
        <Route index element={<Navigate to={PATH.LOGIN} />} />
        <Route path="*" element={<Navigate to={PATH.LOGIN} />} />
      </Route>
    </Routes>
  );
};
