import { CustomTypo } from 'components/CustomTypography';
import { formatDate } from 'enable-ui';

import { TemplateInfo } from 'modules/project/components/TemplateInfo';
import { ProcessInfo } from 'modules/project/components/ded-process/ProcessInfo';

import { LogType } from './type';

import { isISOString } from './helpers';

const powderStyle: React.CSSProperties = {
  margin: '8px 0px',
  padding: 6,
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.15)',
  backgroundColor: '#FAFAFA',
  width: 'fit-content',
};

export const logDetailColumns = () => [
  {
    id: 'section',
    Header: 'Section',
    width: '15%',
    flex: 1,
    accessor: 'section',
    Cell: ({ ...props }) => {
      return <CustomTypo>{props.data?.[props?.row?.index || 0]?.section}</CustomTypo>;
    },
  },
  {
    id: 'property',
    Header: 'Field',
    accessor: 'property',
    width: '20%',
    flexGrow: 1,
    Cell: ({ ...props }) => {
      return <CustomTypo>{props.data?.[props?.row?.index || 0]?.property}</CustomTypo>;
    },
  },
  {
    id: 'type',
    Header: 'Property',
    accessor: 'type',
    width: '10%',
    flexGrow: 1,
    Cell: ({ ...props }) => {
      const type = props.data?.[props?.row?.index || 0]?.type;

      return (
        <CustomTypo>
          {type === LogType.ChangeLinkPowder ? 'Value' : props.data?.[props?.row?.index || 0]?.type}
        </CustomTypo>
      );
    },
  },
  {
    id: 'action',
    Header: 'Action',
    accessor: 'action',
    width: '10%',
    flexGrow: 1,
    Cell: ({ ...props }) => {
      return <CustomTypo>{props.data?.[props?.row?.index || 0]?.action}</CustomTypo>;
    },
  },
  {
    id: 'oldValue',
    Header: 'Previous data',
    accessor: 'oldValue',
    width: '20%',
    flexGrow: 1,
    Cell: ({ ...props }) => {
      const type = props.data?.[props?.row?.index || 0]?.type;
      const oldValue = props.data?.[props?.row?.index || 0]?.oldValue;

      if (type === LogType.ChangeLinkPowder) {
        return (
          <>
            {JSON.parse(oldValue)?.map((el) => (
              <TemplateInfo key={el?.subId} template={el} style={powderStyle}></TemplateInfo>
            ))}
          </>
        );
      }

      return (
        <CustomTypo>
          {isISOString(oldValue) ? formatDate(oldValue, 'dd MMM yyyy') : oldValue}
        </CustomTypo>
      );
    },
  },
  {
    id: 'newValue',
    Header: 'New data',
    accessor: 'newValue',
    width: '20%',
    flexGrow: 1,
    Cell: ({ ...props }) => {
      const type = props.data?.[props?.row?.index || 0]?.type;
      const newValue = props.data?.[props?.row?.index || 0]?.newValue;

      if (type === LogType.ChangeLinkPowder) {
        return (
          <>
            {JSON.parse(newValue)?.map((el) => (
              <TemplateInfo key={el?.subId} template={el} style={powderStyle}></TemplateInfo>
            ))}
          </>
        );
      }

      return (
        <CustomTypo>
          {isISOString(newValue) ? formatDate(newValue, 'dd MMM yyyy') : newValue}
        </CustomTypo>
      );
    },
  },
];

export const changeSettingLogsColumns = (type?: LogType) => {
  const isBuild = type === LogType.ChangeBuildProcess;

  if (type) {
    return [
      {
        id: 'title',
        Header: 'Title',
        width: '15%',
        flex: 1,
        accessor: 'title',
        Cell: ({ ...props }) => {
          return <CustomTypo>{`Change ${props.data?.[props?.row?.index || 0]?.title}`}</CustomTypo>;
        },
      },
      {
        id: 'oldContent',
        Header: `Previous ${isBuild ? 'DED Process' : 'template'}`,
        accessor: 'oldContent',
        width: '20%',
        flexGrow: 1,
        Cell: ({ ...props }) => {
          const isFromOtherOrganization =
            props.data?.[props?.row?.index || 0]?.oldContent?.isFromOtherOrganization;

          if (isFromOtherOrganization) {
            return <CustomTypo>Not found</CustomTypo>;
          }

          return isBuild ? (
            <ProcessInfo
              process={props.data?.[props?.row?.index || 0]?.oldContent}
              style={{ padding: '8px 0px' }}
            ></ProcessInfo>
          ) : (
            <TemplateInfo
              template={props.data?.[props?.row?.index || 0]?.oldContent}
              style={{ padding: '8px 0px' }}
            ></TemplateInfo>
          );
        },
      },
      {
        id: 'newContent',
        Header: `New ${isBuild ? 'DED Process' : 'template'}`,
        accessor: 'newContent',
        width: '20%',
        flexGrow: 1,
        Cell: ({ ...props }) => {
          return isBuild ? (
            <ProcessInfo
              process={props.data?.[props?.row?.index || 0]?.newContent}
              style={{ padding: '8px 0px' }}
            ></ProcessInfo>
          ) : (
            <TemplateInfo
              template={props.data?.[props?.row?.index || 0]?.newContent}
              style={{ padding: '8px 0px' }}
            ></TemplateInfo>
          );
        },
      },
    ];
  } else return [];
};
