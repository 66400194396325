import type { CSSProperties } from 'react';

import { Checkbox, type CheckboxProps } from 'enable-ui';

import { CustomTypo } from './CustomTypography';

export interface CheckboxGroupProps {
  title?: string;
  titleStyle?: CSSProperties;
  options: CheckboxProps[];
}

export const CheckboxGroup = ({ title, titleStyle, options }: CheckboxGroupProps) => {
  return (
    <>
      {title && (
        <CustomTypo weight={600} style={{ marginBottom: 6, ...titleStyle }}>
          {title}
        </CustomTypo>
      )}

      {options.map((option) => (
        <Checkbox {...option} />
      ))}
    </>
  );
};

// const CustomCheckbox = styled(Checkbox)`
//   min-height: 26px;
//   height: fit-content;
//   font-size: 18px;
//   line-height: 26px;
//   padding: 0;
//   align-items: flex-start;
//   justify-content: flex-start;
//   text-align: left;
//   .checkbox {
//     margin-right: 0;
//     width: 18px;
//     height: 18px;
//     > svg {
//       width: 18px;
//       height: 18px;
//     }
//   }
//   span {
//     margin-left: 4px;
//   }

//   &.error .checkbox {
//     border-color: #ff4d4f;
//   }
// `;
