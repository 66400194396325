import { useEffect } from 'react';

import { ActionLogRow } from 'components/ActionLogRow';
import { PageContent } from 'components/layout';
import { ModalCard } from 'components/layout/components/ModalCard';
import { Button, Row } from 'enable-ui';

import { LogObjectType, useFetchActivityLog } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useProjectName } from 'modules/project/core';

const PAGE_LIMIT = 20;

export const ProjectActionLogsPage = () => {
  const name = useProjectName();

  const { count, items, page } = useActivityLogStore(
    (s) => s.state.changeLogs[LogObjectType.Project],
  );

  const fetchApi = useFetchActivityLog(LogObjectType.Project);

  useEffect(() => {
    fetchApi();

    return () => {
      useActivityLogStore.getState().clearState(LogObjectType.Project);
    };
  }, []);

  return (
    <PageContent>
      <ModalCard title={`Action log of ${name}`} style={{ width: 752 }}>
        {items.map((log) => (
          <ActionLogRow key={log.id} {...log} />
        ))}

        {count && count > page * PAGE_LIMIT ? (
          <Row justify="center">
            <Button size="small" onClick={() => fetchApi(true)}>
              Load more
            </Button>
          </Row>
        ) : null}
      </ModalCard>
    </PageContent>
  );
};
