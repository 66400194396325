import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { closeAllModal, openModal } from 'enable-ui';

import { LogObjectType } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';
import type { TemplateType } from 'modules/template/core';
import { useTemplatePropertyStore } from 'modules/template/core/controller/template-property.store';

import type { ProjectBuild } from '../type';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { BUILD_INFO_MENU_ITEMS } from '../constant';
import { fetchTemplates, reorderTemplates } from '../helpers';
import { setTemplatesByType } from '../store';
import { useBuildStore } from '../store/build.store';
import { useFetchProcesses } from './basic';

export const useFetchBuild = () => {
  const match = useMatch('/org/:orgId/projects/:projectId/builds/:buildId/*');
  const orgId = useOrgId();
  const buildId = match?.params?.buildId ?? '';
  const projectId = match?.params?.projectId ?? '';

  const fetchApi = async () => {
    const result = await apiCall<any, ProjectBuild>({
      url: `/organizations/${orgId}/projects/${projectId}/builds/${buildId}`,
      method: 'GET',
      isLoading: true,
    });

    if (result.id) {
      const templates = reorderTemplates(result.process.templates);

      result.process.templates = templates;

      useBuildStore.getState().setState({
        build: {
          ...result,
        },
      });

      useTemplatePropertyStore.getState().setState(templates.map((el) => el.sections));
      useActivityLogStore.getState().clearState(LogObjectType.Build, true);
    }
  };

  return fetchApi;
};

export const useInitBuildController = () => {
  const orgId = useOrgId();
  const fetchApi = useFetchBuild();
  const fetchProcesses = useFetchProcesses();

  const name = useBuildStore((s) => s.state.build.name);

  const params = useParams<{ buildId: string }>();

  const fetchTemplateByType = async (type: TemplateType) => {
    const templates = await fetchTemplates(orgId, type);

    if (templates?.items) {
      setTemplatesByType(type, templates.items);
    }
  };

  useEffect(() => {
    if (params.buildId) {
      fetchApi();
      fetchProcesses();
      useBuildStore.getState().setState({ selectedIndex: BUILD_INFO_MENU_ITEMS[0].key });

      fetchTemplateByType('Powder');
    }

    return () => {
      closeAllModal();
      useBuildStore.getState().clearState();
    };
  }, [params.buildId]);

  return name;
};

export const useEditBuildController = () => {
  const selectedIndex = useBuildStore((s) => s.state.selectedIndex);
  const buildId = useBuildStore((s) => s.state.build.id);
  const selectedSections = useTemplatePropertyStore((s) => s.state[Number(selectedIndex)]);

  const onChangeDedProcess = (processId: string) => {
    openModal(MODAL_KEY.BUILD_CHANGE_DED_PROCESS_MODAL, {
      buildId,
      processId,
    });
  };

  const onSyncClick = () => {
    openModal(MODAL_KEY.BUILD_SYNC_MODAL, {
      buildId,
    });
  };

  const onSaveClick = () => {
    openModal(MODAL_KEY.BUILD_CREATE_LINK_DED_PROCESS_MODAL, {
      buildId,
    });
  };

  return {
    selectedSections,
    onChangeDedProcess,
    onSyncClick,
    onSaveClick,
  };
};
