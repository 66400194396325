import styled from 'styled-components';

import { Checkbox, primary } from 'enable-ui';

import { clsx } from 'helpers';

export const Pill = ({
  children,
  showCheckbox,
  active,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { showCheckbox?: boolean; active: boolean }) => {
  return (
    <Wrapper className={clsx(active ? 'active' : '', className)} {...props}>
      {showCheckbox ? <Checkbox checked={active} style={{ pointerEvents: 'none' }} /> : null}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: var(--radius-max, 999px);
  display: flex;
  height: 36px;
  padding: 0px 14px 0 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid var(--border-neutral-default, rgba(0, 0, 0, 0.15));

  .es-check-box-wrapper {
    padding: 0;
    .checkbox {
      margin-right: 0;
    }
  }

  &.active {
    color: ${primary['primary-6']};
    border: 1px solid var(--border-brand-default, #003087);
    background: var(--surface-brand-subtle-default, #edf6ff);
  }
`;
