import { PageContent } from 'components/layout';

import { ProcessManager } from '../components';

export const ProjectProcessesPage = () => {
  return (
    <>
      <PageContent>
        <ProcessManager />
      </PageContent>
    </>
  );
};
