import axios, { AxiosError } from 'axios';

import { authenSelector, useAuthStore } from 'modules/auth/core';

import { API_URL } from '../constants/environment';

const getAccessToken: () => string = () => {
  /*TODO: Get user's access token from your current storage
    E.g: localStorage.getItem("access_token")
   */
  const auth = authenSelector(useAuthStore.getState());

  return auth?.accessToken;
};

const createHeaderToken = () => {
  const tokenType = 'Bearer'; //Modify your logic to get more token types
  const accessToken = getAccessToken();

  return `${tokenType} ${accessToken}`;
};

const initAxiosInterceptor = () => {
  axios.interceptors.request.use((config) => {
    //Set timeout if server takes too long to response: config.timeout = DEFAULT_MAX_TIMEOUT;
    config.url = `${config.url?.indexOf('http') !== 0 ? API_URL : ''}${config.url}`;
    config.headers.Authorization = createHeaderToken();
    config.headers.Accept = 'application/json';

    //TODO: If you want loading effect when sending request, put your start loading function here

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      //Put your close loading function here
      return response;
    },
    (error: AxiosError) => {
      //Put your close loading function here
      const statusCode = error.response ? error.response.status : null;

      switch (statusCode) {
        case 401:
          //Implement your Unauthorized request handler here
          useAuthStore.getState().clearAuthState();

          break;

        case 404:
          //Implement your Unauthorized request handler here
          break;

        //Add more handler here if needed
        default:
          console.log('error:', error);
          break;
      }

      return Promise.reject(error);
    },
  );
};

const publicAxiosInstance = axios.create({
  baseURL: API_URL,
});

export { initAxiosInterceptor, publicAxiosInstance };
