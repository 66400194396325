import { CheckboxGroup } from 'components/GroupCheckbox';
import { PageContent, PageHeader } from 'components/layout';
import { ModalCard } from 'components/layout/components/ModalCard';
import { Button, TextInput } from 'enable-ui';

import { useTemplateConfig } from '../core';
import { useAddTemplateController } from '../core/controller/add-template.controller';

export const AddTemplatePage = () => {
  const { disabled, name, options, onSubmit } = useAddTemplateController();
  const { addTitle, title, label } = useTemplateConfig();

  return (
    <>
      <PageHeader title={title} />
      <PageContent>
        <ModalCard title={addTitle} showCloseIcon>
          <TextInput
            title={`${label} name`}
            containerStyle={{ maxWidth: '100%', marginBottom: 16 }}
            value={name.value}
            onChange={(event) => name.setValue(event.target.value)}
          />

          <CheckboxGroup title="Sections" options={options} />

          <Button
            size="large"
            color="primary"
            style={{ marginTop: 24 }}
            disabled={disabled}
            onClick={onSubmit}
          >
            Add
          </Button>
        </ModalCard>
      </PageContent>
    </>
  );
};
