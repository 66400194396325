import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { PageContent } from 'components/layout';
import { ModalCard } from 'components/layout/components/ModalCard';
import { Button, TextInput, showToastMessage } from 'enable-ui';

import { mappingPath } from 'helpers';
import { useString } from 'hooks';
import { apiCall } from 'hooks/service';

import { PATH } from 'constants/path';

import { ProcessSelectInput } from '../components/builds/ProcessSelectInput';
import { type DefaultDedProcess, ProcessStatus } from '../core';

export const AddBuildPage = () => {
  const buildName = useString('');
  const selectedProcessId = useString('');
  const match = useMatch('/org/:orgId/projects/:projectId/*');
  const orgId = match?.params?.orgId;
  const projectId = match?.params?.projectId;
  const navigate = useNavigate();

  const [processes, setProcesses] = useState<DefaultDedProcess[]>([]);
  const isDisabled = !buildName.value || !selectedProcessId.value;

  useEffect(() => {
    fetchProcesses();
  }, []);

  const fetchProcesses = async () => {
    const result = await apiCall({
      url: `/organizations/${match?.params?.orgId}/projects/${match?.params?.projectId}/processes`,
      method: 'GET',
      data: {
        page: 1,
        status: ProcessStatus.Publish,
        limit: 999,
        order_by: 'createdAt',
        order_direction: 'desc',
      },
    });

    if (result.items) {
      setProcesses(result.items);
    }
  };

  const onSubmit = async () => {
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projectId}/builds`,
      method: 'POST',
      data: {
        name: buildName.value,
        processId: selectedProcessId.value,
      },
      showError: true,
      isLoading: true,
    });

    if (result?.id) {
      navigate(mappingPath(PATH.PROJECT_BUILD_EDIT, [orgId || '', projectId || '', result.id]));
      showToastMessage('success', 'Added successfully!');
    }
  };

  return (
    <PageContent style={{ overflow: 'visible' }}>
      <ModalCard title="Add Build" showCloseIcon style={{ height: 'fit-content', width: 592 }}>
        <TextInput
          title="Build name"
          containerStyle={{ marginBottom: 16 }}
          onChange={(event) => buildName.setValue(event.target.value)}
        />
        <CustomTypo weight={600} style={{ marginBottom: 6 }}>
          DED Process
        </CustomTypo>
        <ProcessSelectInput
          processes={processes}
          onChange={(val: any) => {
            selectedProcessId.setValue(val?.value?.id);
          }}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={isDisabled}
          onClick={onSubmit}
          style={{ marginTop: 24 }}
        >
          Add Build
        </Button>
      </ModalCard>
    </PageContent>
  );
};
