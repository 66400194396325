import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import { closeAllModal, openModal, showToastMessage } from 'enable-ui';

import { LogObjectType } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';
import type { TemplateSection, TemplateType } from 'modules/template/core';
import { useTemplatePropertyStore } from 'modules/template/core/controller/template-property.store';

import type { DedProcess } from '../type';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { DEFAULT_PROCESS_TEMPLATE_ID } from '../constant';
import { reorderTemplates } from '../helpers';
import { setProcessState, useProcessStore, useProjectId } from '../store';

export const useFetchProcess = () => {
  const orgId = useOrgId();
  const match = useMatch('/org/:orgId/projects/:projectId/ded-processes/:processId/*');
  const processId = match?.params?.processId || '';
  const projectId = match?.params?.projectId || '';

  const fetchApi = async (customProcessId?: string) => {
    const result = await apiCall<any, DedProcess>({
      url: `/organizations/${orgId}/projects/${projectId}/processes/${customProcessId || processId}`,
      method: 'GET',
      isLoading: true,
    });

    if (result.id) {
      const templates = reorderTemplates(result.templates);

      //If has unselected variants, open variant select modal
      if (templates.length > 0 && templates.some((el) => el.sections[0]?.selected === false)) {
        openModal(MODAL_KEY.VARIANT_SELECT);
      }

      result.templates = templates;

      setProcessState({
        dedProcess: result,
      });

      useTemplatePropertyStore.getState().setState(templates.map((el) => el.sections));
      useActivityLogStore.getState().clearState(LogObjectType.Process, true);
    }
  };

  return fetchApi;
};

export const useInitProcessController = () => {
  const fetchApi = useFetchProcess();

  const name = useProcessStore((s) => s.state.dedProcess.name);
  const status = useProcessStore((s) => s.state.dedProcess.status);

  useEffect(() => {
    fetchApi();

    return () => {
      closeAllModal();
    };
  }, []);

  return { name, status };
};

export const useUpdateVariants = (processId: string) => {
  const orgId = useOrgId();
  const projId = useProjectId();
  const fetchApi = useFetchProcess();

  const updateVariants = async (
    selectedVariants: TemplateSection[],
    changedTemplateType?: TemplateType,
    showToast: boolean = true,
  ) => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/${processId}/variants`,
      method: 'PATCH',
      data: { selectedVariantIds: selectedVariants.map((el) => el.id) },
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      fetchApi(result.id);

      closeAllModal();
      setProcessState({ selectedIndex: DEFAULT_PROCESS_TEMPLATE_ID });

      if (showToast) {
        showToastMessage('success', 'Updated variants successfully!');
      }

      const needLink =
        changedTemplateType === 'Powder' ||
        (changedTemplateType === 'DEDSystem' &&
          selectedVariants?.some((el) => el?.multipleSelected));

      if (needLink) {
        openModal(MODAL_KEY.PROCESS_LINK_POWDER);
      }
    }
  };

  return { updateVariants };
};
