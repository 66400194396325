export interface TemplateSection {
  id: string;
  name: string;
  masterId: string;
  parentId?: string;
  multipleSelected?: boolean;
  properties: TemplateSectionProperty[];
  expand?: boolean;
  powderIds?: string[];
  selected?: boolean;
}

export interface Template {
  id: string;
  name: string;
  subId: string;
  sections: TemplateSection[];
}

export type TemplateType = 'DEDSystem' | 'Powder' | 'Process';

export interface DefaultTemplate {
  id: string;
  name: string;
  type?: TemplateType;
  subId: string;
  masterId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Template extends DefaultTemplate {
  sections: TemplateSection[];
}

export enum PropertyType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  // Select = 'Select',
  File = 'File',
  Widget = 'Widget',
}

export interface TemplateSectionProperty {
  id: string;
  name: string;
  type: PropertyType;
  options?: string[];
  items?: TemplateSectionProperty[];
  value?: string;
  fileIds?: string[];
  files?: Array<File & { id?: string; masterId?: string }>;
  formula?: string;
  locked?: boolean;
  timestamp?: number;
  masterId?: string;
  isDisplayName?: string;
}

export type PropertySelection = {
  sectionIndex: number;
  propertyIndex: number;
  itemIndex?: number;
  optionIndex?: number;
  fileIndex?: number;
};

export type FieldProps = 'name' | 'value' | 'locked' | 'files';

export type LinkPowderProps = {
  powderFeederId: string;
  powderIds: string[];
};

export interface UpdateSectionItem {
  sectionId: string;
  properties: TemplateSectionProperty[];
  powderIds?: string[];
}
