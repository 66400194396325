import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Row, Tabs, Typography, openModal, primary } from 'enable-ui';

import {
  DEFAULT_PROCESS_TEMPLATE_ID,
  ProcessStatus,
  useProjectPermission,
} from 'modules/project/core';
import { setProcessState, useProcessStore } from 'modules/project/core/store';
import { TemplateContentWrapper } from 'modules/template/components';
import { TEMPLATE_CONFIG } from 'modules/template/core';
import { useTemplatePropertyStore } from 'modules/template/core/controller/template-property.store';

import { includes } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { TemplateSelectInput } from './TemplateSelectInput';

export const ProcessMenu = () => {
  const templates = useProcessStore((s) => s.state.dedProcess.templates);
  const templatesByType = useProcessStore((s) => s.state.templates);
  const selectedIndex = useProcessStore((s) => s.state.selectedIndex);
  const { isMember } = useProjectPermission();
  const status = useProcessStore((s) => s.state.dedProcess.status);

  const isPublished = status === ProcessStatus.Publish;

  const curTemplate = templates[Number(selectedIndex)];

  return (
    <ProjectContentWrapper title="DED Process information" titleProps={{ variant: 'h5' }}>
      <Tabs
        variant="text"
        tabPosition="vertical"
        className="section"
        items={[
          {
            children: '',
            key: DEFAULT_PROCESS_TEMPLATE_ID,
            label: (
              <Typography
                color={
                  selectedIndex === DEFAULT_PROCESS_TEMPLATE_ID ? primary['primary-6'] : undefined
                }
                weight={selectedIndex === DEFAULT_PROCESS_TEMPLATE_ID ? 500 : undefined}
                className="text-ellipsis-1"
              >
                DED Process information
              </Typography>
            ),
          },
        ]}
        activeKey={selectedIndex}
        onTabClick={() => {
          setProcessState({ selectedIndex: DEFAULT_PROCESS_TEMPLATE_ID });
        }}
      />

      <div className="section-divider" style={{ height: 24 }} />

      {templates.map((template, index) => {
        if (template.type?.toString() === 'BuildProperties') {
          return null;
        }

        const allOptions = template.type ? templatesByType[template.type] : [];
        const templateOptions = allOptions.filter(
          (el) =>
            !includes(
              templates.map((temp) => temp.masterId),
              el.id,
            ),
        );

        const notFound = !allOptions.find((el) => el.id === template.masterId);
        const templateIndex = String(index);
        const isSelected = selectedIndex === templateIndex;

        return (
          <section key={`${templateIndex}-${template.id}`}>
            <CustomTypo variant="h5" weight={500} className="text-ellipsis-1">
              {template.type ? TEMPLATE_CONFIG[template.type].processTitle : ''}{' '}
              {templates.length > 3 && index >= 2 ? index - 1 : ''}
            </CustomTypo>

            <TemplateSelectInput
              templates={templateOptions.filter((option) => option.id !== template.masterId)}
              onChange={(val: any) => {
                openModal(MODAL_KEY.TEMPLATE_CHANGE, { newVal: val?.value, currentVal: template });
              }}
              value={notFound ? null : { value: template }}
              containerStyle={{ marginBottom: 8 }}
              isClearable={false}
              isDisabled={!isMember || isPublished}
              customPlaceholder={notFound ? 'Not found' : undefined}
            />

            <Tabs
              variant="text"
              tabPosition="vertical"
              className="section"
              items={[
                {
                  children: '',
                  key: templateIndex,
                  label: (
                    <Row
                      justify="space-between"
                      align="center"
                      className={isSelected ? 'active' : ''}
                    >
                      <Typography
                        color={isSelected ? primary['primary-6'] : undefined}
                        weight={isSelected ? 500 : undefined}
                        className="text-ellipsis-1"
                      >
                        {template.name}
                      </Typography>
                    </Row>
                  ),
                },
              ]}
              activeKey={selectedIndex}
              onTabClick={() => {
                setProcessState({ selectedIndex: templateIndex });

                // Clear current template state
                if (curTemplate) {
                  useTemplatePropertyStore
                    .getState()
                    .setTemplateState(Number(selectedIndex), curTemplate.sections);
                }
              }}
            />
          </section>
        );
      })}
    </ProjectContentWrapper>
  );
};

export const ProjectContentWrapper = styled(TemplateContentWrapper)`
  padding-bottom: 0px;
  .mdl-heading {
    padding-bottom: 8px;
  }
  .tab-line {
    display: none;
  }
  section {
    margin: 0 12px;
    padding-top: 12px;
    padding-bottom: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .es-tab.section {
      padding: 0;
    }
    p[variant='h5'] {
      padding: 0 12px 8px;
    }
  }
`;
