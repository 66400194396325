import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  border-radius: var(--radius-medium, 8px);
  border: 1px solid var(--border-neutral-subtle-default, rgba(0, 0, 0, 0.1));
  background: var(--surface-neutral-subtlest-default, #fff);
  width: 300px;
  padding: 4px 0;
  box-shadow:
    0px 12px 16px -4px rgba(0, 0, 0, 0.06),
    0px 8px 12px 0px rgba(0, 0, 0, 0.06);

  svg:not(.custom-add-icon) path[stroke] {
    stroke: #ffffff !important;
  }

  .es-tab-item {
    padding: 0;
    height: 36px;
    margin: unset !important;
    > div {
      pointer-events: none;
      padding: 0 8px;
      height: 100%;
      &.active {
        background-color: #fafafa;
      }
    }
    &:hover {
      p {
        color: #222222;
        font-weight: normal;
      }
      > div {
        background-color: #f0f0f0;
        height: 100%;
      }
    }
  }
  .add-btm {
    margin: 16px 12px 8px;
    button {
      width: 100%;
    }
  }
`;
