import { type FC } from 'react';
import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Row, Spacing, Typography, neutral, neutralTrans } from 'enable-ui';

import { ReactComponent as DefaultAvatar } from 'assets/icons/Avatar.svg';
import { ReactComponent as Close } from 'assets/icons/x.svg';

interface MemberCardProps {
  fullName?: string;
  email?: string;
  removeUser?: () => void;
  assignOwner?: () => void;
  styles?: React.CSSProperties;
}

const CloseButton = styled.div`
  cursor: pointer;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    path[stroke] {
      stroke: ${neutral['neutral-11']};
    }
  }
`;

export const MemberCard: FC<MemberCardProps> = ({
  fullName,
  email,
  removeUser,
  assignOwner,
  styles,
}) => {
  return (
    <Row align="center" style={{ height: 54, width: 552, ...styles }}>
      <DefaultAvatar width={28} height={28} style={{ minWidth: 28 }} />
      <Spacing spacing="xs" type="margin" placement="left" style={{ flex: 1 }}>
        <Row direction="column" style={{ alignItems: 'start' }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <CustomTypo
              weight={400}
              variant="body-1"
              height={24}
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: 0,
              }}
            >
              {fullName}
            </CustomTypo>
          </div>
          <div style={{ width: '100%', display: 'flex' }}>
            <Typography
              variant="body-2"
              weight={400}
              color={neutral['neutral-7']}
              className="bg-white text-ellipsis-1"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {email}
            </Typography>
          </div>
        </Row>
      </Spacing>
      {assignOwner ? (
        <CustomTypo onClick={assignOwner} style={{ cursor: 'pointer', padding: '0px 8px' }}>
          Set as Owner
        </CustomTypo>
      ) : null}
      {removeUser ? (
        <CloseButton onClick={removeUser}>
          <Close />
        </CloseButton>
      ) : null}
    </Row>
  );
};
