import { useEffect } from 'react';
import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { Button, IconClose, Row, Typography, formatDate, neutral, openModal } from 'enable-ui';

import { ReactComponent as DefaultAvatar } from 'assets/icons/Avatar.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { type LogObjectType, useFetchActivityLog } from '../core';
import { CHANGE_LOG_LIMIT } from '../core/constant';
import { useActivityLogStore } from '../core/store';

interface Props {
  type: LogObjectType;
}

export const ChangeLogDrawer = ({ type }: Props) => {
  const open = useActivityLogStore((s) => s.state.openDrawer[type]);
  const showLoadMore = useActivityLogStore(
    (s) =>
      s.state.changeLogs[type].count &&
      s.state.changeLogs[type].count > s.state.changeLogs[type].page * CHANGE_LOG_LIMIT,
  );
  const logs = useActivityLogStore((s) => s.state.changeLogs[type].items);
  const fetchLogs = useFetchActivityLog(type);

  useEffect(() => {
    if (open) {
      fetchLogs();
    }

    return () => {
      if (open) {
        useActivityLogStore.getState().clearState(type, true);
      }
    };
  }, [open]);

  return (
    <Drawer style={{ width: open ? 280 : 0 }}>
      <Row justify="space-between" align="center" style={{ padding: '19px 14px 7px 24px' }}>
        <CustomTypo variant="h5" weight={500}>
          Change log
        </CustomTypo>

        <Button
          icon={IconClose}
          placementIcon="left"
          variant="text"
          size="small"
          onClick={() => useActivityLogStore.getState().clearState(type, true)}
        />
      </Row>

      <section>
        {logs.map((_v) => (
          <LogItem
            key={_v.id}
            onClick={() => {
              openModal(MODAL_KEY.LOG_DETAIL, _v);
            }}
          >
            <CustomTypo style={{ marginBottom: 2 }}>
              {formatDate(_v.createdAt, 'dd MMM yyyy, hh:mm a')}
            </CustomTypo>
            <Row>
              <DefaultAvatar width={20} height={20} style={{ minWidth: 20 }} />
              <CustomTypo variant="body-2" style={{ color: '#8C8C8C', marginLeft: 6 }}>
                {_v.createdBy ? _v.createdBy.fullName : <i>[Deleted account]</i>}
              </CustomTypo>
            </Row>
          </LogItem>
        ))}

        {showLoadMore ? (
          <Row style={{ padding: '8px 24px 32px' }}>
            <Button size="small" onClick={() => fetchLogs(true)}>
              Load more
            </Button>
          </Row>
        ) : null}

        {logs.length <= 0 ? (
          <Typography
            variant="body-1"
            color={neutral['neutral-7']}
            style={{ padding: '12px 24px' }}
          >
            No change log.
          </Typography>
        ) : null}
      </section>
    </Drawer>
  );
};

const Drawer = styled.div`
  z-index: 8;
  transition: all 0.2s;
  background: var(--surface-neutral-subtlest-default, #fff);
  box-shadow:
    0px 12px 16px -4px rgba(0, 0, 0, 0.06),
    0px 8px 12px 0px rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 69px;
  right: 9px;
  bottom: 9px;
  border-bottom-right-radius: 16px;
  overflow: hidden;

  > section {
    overflow: auto;
    max-height: calc(100% - 58px);
  }
`;

const LogItem = styled.div`
  padding: 12px var(--padding-frame, 24px);
  cursor: pointer;
  padding-bottom: 16px;
  &:hover {
    background-color: #0000001a;
  }
`;
