import { useEffect, useRef } from 'react';

import { openModal, showToastMessage, updateQueryStateTable, useTableStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { format } from 'date-fns';
import { downloadURI } from 'helpers/file';
import { apiCall } from 'hooks/service';
import { useTableManager } from 'hooks/table';
import { isEqual } from 'lodash';

import { initialQueryState } from 'constants/general';
import { MODAL_KEY } from 'constants/modal-key';

import {
  ExportType,
  resetExportPayload,
  setExportPayload,
  useProcessStore,
  useProjectId,
} from '../store';
import { useProjectPermission } from './basic';

export const processTableKey = 'processes';

export const useProcessesManagerController = () => {
  const orgId = useOrgId();
  const projId = useProjectId();
  const queryState = useTableStore((s) => s.state.queryState[processTableKey]);

  const fetchDataUrl =
    orgId && projId ? `/organizations/${orgId}/projects/${projId}/processes` : '';

  const {
    rowItems,
    hasNoData,
    isLoading,
    isFirstLoad,
    searchValue,
    total,

    onSearchChange,
    fetchData,
    onDeleteCallback,
  } = useTableManager(processTableKey, fetchDataUrl, {
    dependencies: [orgId, projId],
    fetchDataCallback: (response) => {
      ids.current = response.listIds || [];
      const newExportIds = exportIds.filter((el) => ids.current.includes(el));

      setExportPayload({
        type: getExportType(newExportIds, response.count),
        ids: newExportIds,
      });
    },
  });

  const exportPayload = useProcessStore((s) => s.state.export);
  const exportType = exportPayload.type;
  const exportIds = exportPayload.ids;
  const ids = useRef<string[]>([]);

  const { isMember, isOwner } = useProjectPermission();
  const isAllowExport = isOwner;
  const isAllowAdd = isMember;

  useEffect(() => {
    return function unsubscribe() {
      ids.current = [];
      resetExportPayload();
    };
  }, [orgId, projId]);

  const duplicateProcess = async (processId?: string) => {
    if (!processId) return;

    openModal(MODAL_KEY.PROCESS_BUILD_DUPLICATE, {
      id: processId,
      callback: () => {
        if (isEqual(initialQueryState, queryState)) {
          fetchData();
        } else {
          updateQueryStateTable(processTableKey, initialQueryState);
        }
      },
    });
  };

  const onChangeHeaderCheckbox = () => {
    const type = exportType === ExportType.None ? ExportType.All : ExportType.None;

    setExportPayload({
      type,
      ids: type === ExportType.All ? ids.current : [],
    });
  };

  const getExportType = (selectedIds: string[], currTotal?: number) => {
    return !selectedIds.length
      ? ExportType.None
      : selectedIds.length >= (currTotal || total)
        ? ExportType.All
        : ExportType.Selected;
  };

  const onChangeCheckbox = (id: string) => {
    const newExportIds = exportIds.includes(id)
      ? exportIds.filter((el) => el !== id)
      : exportIds.concat(id);

    setExportPayload({
      type: getExportType(newExportIds),
      ids: newExportIds,
    });
  };

  const getExportNumber = () => {
    if (!exportIds.length || exportIds.length >= total) {
      return 0;
    }

    return exportIds.length;
  };

  const getExportLabel = () => {
    const number = getExportNumber();

    if (!number) {
      return 'Export all';
    }

    if (number === 1) {
      return 'Export 1 DED Process';
    }

    return `Export ${number} DED Processes`;
  };

  const onExport = async () => {
    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/projects/${projId}/processes/export`,
      method: 'GET',
      data: {
        ids: exportIds,
      },
      isLoading: true,
      onFinally: () => isLoading.setValue(false),
      showError: true,
      config: {
        responseType: 'blob',
      },
    });

    if (result) {
      const url = URL.createObjectURL(result);
      const number = getExportNumber();

      resetExportPayload();
      downloadURI(url, `DED-processes_${format(new Date(), 'dd-MMM-yyyy')}`);
    }
  };

  const onImport = async (file: File) => {
    const formData = new FormData();

    formData.append('file', file);
    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/import`,
      method: 'POST',
      data: formData,
      showError: true,
      isLoading: true,
      onFinally: () => isLoading.setValue(false),
    });

    if (result === true) {
      showToastMessage('success', 'Imported successfully!');
      fetchData();
    }
  };

  return {
    orgId,
    projId,

    rowItems,
    total,

    hasNoData,
    isLoading: isLoading.value,
    isFirstLoad,
    searchValue,
    queryState,
    onSearchChange,
    fetchData,
    onDeleteCallback,
    isAllowExport,
    isAllowAdd,
    duplicateProcess,
    exportData: {
      exportType,
      exportIds,
      onChangeHeaderCheckbox,
      onChangeCheckbox,
      onExport,
      exportLabel: getExportLabel(),
      onImport,
    },
  };
};
