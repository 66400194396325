import { Route, Routes } from 'react-router-dom';

import { AuthRoutes } from 'modules/auth/Routers';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<AuthRoutes />} />
    </Routes>
  );
};

export { PublicRoutes };
