import type { UserProfile } from 'modules/auth/core';
import type { Organization } from 'modules/organization/core';
import type { Template, TemplateSection } from 'modules/template/core';

export interface Project {
  id: string;
  subId: string;
  name: string;
  createdAt: string;
}

export enum ProjectRole {
  Member = 'Member',
  Owner = 'Owner',
}

export interface ProjectMember {
  id: string;
  createdAt?: Date;
  member?: UserProfile | null;
  role?: ProjectRole;
}

export interface DefaultDedProcess {
  id: string;
  subId: string;
  name: string;
  parentId?: string;
}

export interface DedProcess extends DefaultDedProcess {
  isDraft?: boolean | null;
  organization?: Organization;
  project: Project;
  templates: Template[];
  updatedAt: string;
  status: ProcessStatus;
}

export interface TemplateSelectionState {
  selectedId: string;
  sections: TemplateSection[][];
  selectedVariants: TemplateSection[];
  expand: boolean;
}

export interface DefaultBuild {
  id: string;
  subId: string;
  name: string;
  updatedAt: string;
  process: DefaultDedProcess;
}

export interface DedProcess extends DefaultDedProcess {
  project: Project;
  templates: Template[];
}

export interface ProjectBuild extends DefaultBuild {
  process: DedProcess;
  date?: string;
  startTime?: string;
  totalRunTime?: string;
}

export enum ProcessStatus {
  Draft = 'Draft',
  Publish = 'Publish',
}

export interface BuildProperty {
  charpy?: string;
  corrosionResistance?: string;
  creep?: string;
  fatigure?: string;
  fractureToughness?: string;
  hardness?: string;
  porosity?: string;
  wearResistance?: string;
  yieldStrength?: string;
}
