import type { CSSProperties } from 'react';
import ReactSelect, {
  type DropdownIndicatorProps,
  type GroupBase,
  type Props,
  components as ReactSelectComponent,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import { RadiusConfig, Row, Tooltip, Typography } from 'enable-ui';

import { boxShadow, neutral, neutralTrans, primary } from 'styles/theme';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';

export interface CustomSelectProps {
  indicatorsContainerStyle?: CSSProperties;
  controlStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  optionStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  title?: string;
  titleTooltip?: string | JSX.Element;
  creatable?: boolean;
  showDropdownIcon?: boolean;
  error?: boolean;
}

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  indicatorsContainerStyle,
  controlStyle,
  valueStyle,
  inputStyle,
  optionStyle,
  menuStyle,
  containerStyle,
  title,
  titleTooltip,
  creatable,
  showDropdownIcon,
  error,
  components,
  styles,
  ...props
}: CustomSelectProps & Props<Option, IsMulti, Group>) {
  const defaultProps: Props<Option, IsMulti, Group> = {
    components: {
      IndicatorSeparator: () => null,
      DropdownIndicator:
        showDropdownIcon && !props.isDisabled
          ? (compoProps: DropdownIndicatorProps<Option, IsMulti, Group>) => (
              <ReactSelectComponent.DropdownIndicator {...compoProps}>
                <ArrowIcon />
              </ReactSelectComponent.DropdownIndicator>
            )
          : () => null,
      ...components,
    },
    styles: {
      container: (base) => ({
        ...base,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 10,
      }),
      indicatorsContainer: (base) => ({
        ...base,
        ...indicatorsContainerStyle,
      }),
      control: (base, { isFocused }) => ({
        ...base,
        boxShadow: 'none',
        borderRadius: RadiusConfig.xs,
        borderColor: error
          ? '#FF4D4F !important'
          : isFocused
            ? `${primary['primary-6']} !important`
            : neutralTrans['neutralTrans-15'],
        minHeight: 42,
        '&:active': {
          borderColor: primary['primary-6'],
        },
        '&:hover': {
          borderColor: neutralTrans['neutralTrans-15'],
          backgroundColor: '#f0f0f0',
        },
        ...controlStyle,
      }),
      valueContainer: (base) => ({
        ...base,
        fontSize: 14,
        ...valueStyle,
      }),
      placeholder: (base) => ({
        ...base,
        color: neutral['neutral-7'],
        fontSize: 14,
        lineHeight: '20px',
        ...inputStyle,
      }),
      menu: (base) => ({
        ...base,
        borderRadius: 8,
        border: `1px solid ${neutralTrans['neutralTrans-10']}`,
        backgroundColor: neutral['neutral-1'],
        color: neutral['neutral-11'],
        overflow: 'hidden',
        boxShadow: boxShadow['trans-6'],
        ...menuStyle,
      }),
      option: (base, { isSelected, isFocused }) => {
        return {
          ...base,
          padding: '0px 12px',
          backgroundColor: isSelected ? primary['primary-1'] : '#fff',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
          ...optionStyle,
        };
      },
      singleValue: (base) => ({
        fontSize: 14,
        ...base,
        ...inputStyle,
      }),
      multiValue: (base) => {
        return {
          ...base,
          border: `1px solid ${neutralTrans['neutralTrans-15']}`,
          backgroundColor: neutral['neutral-4'],
          borderRadius: 12,
        };
      },
      multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'transparent',
        },
      }),
      menuPortal: (base) => ({ ...base, zIndex: 999 }),
      ...styles,
    },
    isSearchable: true,
    placeholder: '',
    menuPosition: 'absolute',
  };

  const Title = !title ? null : titleTooltip ? (
    <Tooltip
      placement="top"
      content={titleTooltip}
      maxWidth={320}
      arrow={false}
      offset={[0, 6]}
      trigger="click"
    >
      <Row style={{ width: 'fit-content', marginBottom: 6 }} align="center">
        <Typography variant="body-3" color={neutral['neutral-7']} weight={600}>
          {title}
        </Typography>
      </Row>
    </Tooltip>
  ) : (
    <Typography
      variant="body-3"
      color={neutral['neutral-7']}
      weight={600}
      style={{ marginBottom: 6 }}
    >
      {title}
    </Typography>
  );

  return (
    <Wrapper style={containerStyle}>
      {Title}

      {creatable ? (
        <CreatableSelect {...defaultProps} {...props} />
      ) : (
        <ReactSelect {...defaultProps} {...props} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

export const filterOptionByKeys = (data: any, inputValue: string, keys: string[]) =>
  inputValue
    ? keys.some((key) =>
        data[key] ? String(data[key]).toLowerCase().trim().includes(inputValue) : false,
      )
    : true;
