import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  NavigationBar,
  Row,
  Tooltip,
  Typography,
  neutralTrans,
  openModal,
  primary,
} from 'enable-ui';

import { useIsAdmin, useIsSuperAdmin } from 'modules/auth/core';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-hour-4.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as Admin } from 'assets/icons/user-circle.svg';
import { ReactComponent as UserIcon } from 'assets/icons/users.svg';

import type { MenuItem } from 'enable-ui/components/Navigation/NavigationBar/core/types';

import { mappingPath } from 'helpers';
import { useBoolean } from 'hooks';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { setOrgState, useOrgStore } from '../core/store';

export interface Props {
  locationPathname: string;
}

export const OrganizationMenu: FC<Props> = ({ locationPathname }) => {
  const { orgs, curOrg } = useOrgStore((store) => store.state);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isSuperAdmin = useIsSuperAdmin();

  const open = useBoolean();

  return (
    <Wrapper direction="column">
      {!isSuperAdmin ? null : (
        <>
          <NavigationBar
            menuItems={{
              ADMINS: [
                {
                  label: 'Admins',
                  icon: <Admin className="admin-icon" />,
                  path: PATH.ADMINS,
                  onClickMenuItem: (item: MenuItem) => navigate(item.path),
                },
                {
                  label: 'Members',
                  icon: <UserIcon />,
                  path: PATH.ALL_MEMBERS,
                  onClickMenuItem: (item: MenuItem) => navigate(item.path),
                },
              ],
            }}
            mode="vertical"
            width="100%"
            height="100%"
            locationPathName={locationPathname}
          />
          <Divider />
        </>
      )}
      <Tooltip
        visible={open.value}
        onClickOutside={() => open.setValue(false)}
        allowHTML
        arrow={false}
        interactive
        trigger="click"
        offset={[45, 16]}
        placement="right-start"
        content={
          <Popover>
            {isAdmin ? (
              <>
                <Typography
                  variant="body-2"
                  weight={600}
                  color="#8C8C8C"
                  style={{ padding: '10px 12px 6px 12px' }}
                >
                  {curOrg?.name || ''}
                </Typography>
                <Row
                  className="item"
                  onClick={() => {
                    navigate(mappingPath(PATH.ORG_ACTION_LOGS, [curOrg?.id || '']));
                    open.setValue(false);
                  }}
                >
                  <div className="item-ic">
                    <ClockIcon />
                  </div>
                  <Typography>View action log</Typography>
                </Row>
                <Row
                  className="item"
                  onClick={() => {
                    openModal(MODAL_KEY.ORG_RENAME);
                    open.setValue(false);
                  }}
                >
                  <div className="item-ic">
                    <EditIcon />
                  </div>
                  <Typography>Rename</Typography>
                </Row>
                <Row
                  className="item"
                  onClick={() => {
                    openModal(MODAL_KEY.ORG_DELETE);
                    open.setValue(false);
                  }}
                >
                  <div className="item-ic">
                    <TrashIcon />
                  </div>
                  <Typography>Delete</Typography>
                </Row>
                <div className="divider" />
              </>
            ) : null}
            <Typography
              variant="body-2"
              weight={600}
              color="#8C8C8C"
              style={{ padding: '10px 12px 6px 12px' }}
            >
              Organization
            </Typography>

            <div className="org-list">
              {orgs.map((org) => (
                <Row
                  key={org.id}
                  className={`item${org.id === curOrg?.id ? ' selected' : ''}`}
                  onClick={() => {
                    navigate(mappingPath(isAdmin ? PATH.MEMBERS : PATH.PROJECTS, [org.id]));
                    setOrgState({ curOrg: org });
                    open.setValue(false);
                  }}
                >
                  <Typography className="text-ellipsis-1">{org.name}</Typography>
                </Row>
              ))}
            </div>

            {isAdmin ? (
              <div style={{ borderTop: '1px solid #0000001a', marginTop: 6, paddingTop: 6 }}>
                <Row
                  className="item pop-bottom"
                  onClick={() => {
                    openModal(MODAL_KEY.ORG_ADD);
                    open.setValue(false);
                  }}
                >
                  <div className="item-ic">
                    <PlusIcon />
                  </div>
                  <Typography color={primary['primary-6']} weight={500}>
                    Add organization
                  </Typography>
                </Row>
              </div>
            ) : null}
          </Popover>
        }
      >
        <Row direction="column" style={{ padding: '16px 16px 0 8px' }}>
          <Typography
            variant="body-2"
            weight={600}
            color="#8C8C8C"
            style={{ padding: '8px 0', textTransform: 'uppercase' }}
          >
            Organization
          </Typography>
          <InputContent isAdmin={isAdmin} onClick={() => open.setValue((prev) => !prev)}>
            <Typography className="text-ellipsis-1">{curOrg?.name || ''}</Typography>
            <ArrowIcon />
          </InputContent>
        </Row>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  .tippy-box {
    background-color: #fff !important;
    width: 280px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow:
      0px 12px 16px -4px rgba(0, 0, 0, 0.06),
      0px 8px 12px 0px rgba(0, 0, 0, 0.06);
  }
  .tippy-content {
    padding: 6px 0;
  }
  .divider {
    width: 100%;
    border-bottom: 1px solid #0000001a;
    margin-top: 6px;
    margin-bottom: 4px;
  }
  .item {
    align-items: center;
    padding: 0 8px 0 12px;
    cursor: pointer;
    height: 36px;
    &:hover {
      background-color: ${neutralTrans['neutralTrans-15']};
    }
    .item-ic {
      height: 20px;
      width: 20px;
      margin-right: 4px;
      transform: translateX(-2px);
    }
    &.selected {
      background-color: #edf6ff;
    }
    &.pop-bottom {
      svg path {
        stroke: ${primary['primary-6']};
      }
    }
  }
  .org-list {
    max-height: min(526px, calc(100vh - 240px));
    overflow: hidden auto;
  }
`;

const InputContent = styled.div<{ isAdmin?: boolean }>`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: '#fff';
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  p {
    width: fit-content;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`;
const Popover = styled.div``;

const Divider = styled.div`
  height: 1px;
  width: 248px;
  transform: translateX(-8px);
  margin-top: 8px;
  background-color: ${neutralTrans['neutralTrans-10']};
`;
