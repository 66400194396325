import { type FC } from 'react';
import { components as ReactSelectComponent } from 'react-select';

import { CustomSelect, filterOptionByKeys } from 'components/CustomSelect';
import { CustomTypo } from 'components/CustomTypography';
import { Button, Modal, Row, Spacing, Typography, neutral } from 'enable-ui';

import { ReactComponent as UserPlus } from 'assets/icons/user-plus.svg';

import { isNil, update } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { ProjectRole, useProjectMemberModalController } from '../../core';
import { MemberCard } from '../MemberCard';

export const ProjectMemberModal: FC = () => {
  const {
    isAdmin,
    isOwner,
    projOwner,
    userOptions,
    selectedUser,
    setSelectedUser,
    addMember,
    removeMember,
    projMembers,
    updateProjectRole,
  } = useProjectMemberModalController();

  const members = projMembers?.filter((el) => el.role === ProjectRole.Member);

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_MEMBER}
      closeOnClickOutside={false}
      header={
        <CustomTypo variant="h4" weight={500}>
          Project members
        </CustomTypo>
      }
      footer={<></>}
      size="lg"
      className="project-member"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, paddingBottom: 16 }}>
        {!isAdmin && !isOwner ? null : (
          <>
            <Row style={{ gap: 8, width: '100%', paddingRight: 24 }}>
              <CustomSelect
                options={userOptions}
                placeholder="Search with name or email"
                menuPortalTarget={null}
                onChange={(val) => {
                  setSelectedUser(val?.value || null);
                }}
                filterOption={(option, inputValue) =>
                  filterOptionByKeys(option.data.value, inputValue.toLowerCase().trim(), [
                    'email',
                    'fullName',
                  ])
                }
                value={{ label: selectedUser?.fullName, value: selectedUser }}
                components={{
                  NoOptionsMessage: () => {
                    return (
                      <Typography
                        variant="body-1"
                        color={neutral['neutral-7']}
                        style={{ padding: '10px 12px' }}
                      >
                        There is no member available in this Organization.
                      </Typography>
                    );
                  },
                  Option: (compoProps: any) => {
                    return (
                      <ReactSelectComponent.Option {...compoProps}>
                        <MemberCard
                          fullName={compoProps?.value?.fullName}
                          email={compoProps?.value?.email}
                          styles={{ width: '100%' }}
                        />
                      </ReactSelectComponent.Option>
                    );
                  },
                }}
              />
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled={!selectedUser}
                onClick={addMember}
              >
                Add
              </Button>
            </Row>
            <Row style={{ gap: 8 }} align="center">
              <UserPlus />
              <Typography variant="body-1" color={neutral['neutral-7']} weight={400}>
                Everyone added to this project will have the Member role.
              </Typography>
            </Row>
          </>
        )}
        {isNil(projMembers) || projMembers?.length < 1 ? null : (
          <>
            <Spacing spacing="md" type="padding" placement="top">
              <CustomTypo variant="body-1" height={24} weight={500}>
                Project Owner
              </CustomTypo>
              {projOwner?.member ? (
                <MemberCard
                  fullName={projOwner?.member?.fullName || ''}
                  email={projOwner?.member?.email || ''}
                  removeUser={isAdmin && !isOwner ? removeMember(projOwner) : undefined}
                />
              ) : (
                <Typography
                  variant="body-2"
                  color={neutral['neutral-7']}
                  weight={400}
                  style={{ marginTop: 8 }}
                >
                  There is no owner yet
                </Typography>
              )}
            </Spacing>
            <Spacing spacing="md" type="padding" placement="top">
              <CustomTypo variant="body-1" height={24} weight={500}>
                Members
              </CustomTypo>
              {members?.length ? (
                <div style={{ maxHeight: 330, overflowY: 'auto' }}>
                  {members?.map((el) => (
                    <MemberCard
                      fullName={el?.member?.fullName || ''}
                      email={el?.member?.email || ''}
                      removeUser={isAdmin || isOwner ? removeMember(el) : undefined}
                      assignOwner={
                        isAdmin || isOwner ? updateProjectRole(el, ProjectRole.Owner) : undefined
                      }
                    />
                  ))}
                </div>
              ) : (
                <Typography
                  variant="body-2"
                  color={neutral['neutral-7']}
                  weight={400}
                  style={{ marginTop: 8 }}
                >
                  There is no member yet
                </Typography>
              )}
            </Spacing>
          </>
        )}
      </div>
    </Modal>
  );
};
