import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';
import { isNil } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { ProjectRole, useFetchProjectMembers, useProjectStore } from '../../core';

export const ChangeProjectOwnerModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PROJECT_CHANGE_OWNER]);
  const { member, role } = modalProps || {};
  const isOwner = modalProps?.member?.role === ProjectRole.Owner;
  const curProject = useProjectStore((s) => s.state.curProject);
  const orgId = useOrgId();

  const { getProjMembers } = useFetchProjectMembers();

  const updateOwner = async () => {
    if (isNil(member?.id)) return;

    const result = await apiCall<any, any>({
      url: `/organizations/${orgId}/projects/${curProject?.id}/members/${member.id}`,
      method: 'PATCH',
      data: {
        role: role,
      },
      showError: true,
    });

    if (result) {
      getProjMembers();
      showToastMessage('success', 'Updated successfully!');
      closeModal(MODAL_KEY.PROJECT_CHANGE_OWNER);
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PROJECT_CHANGE_OWNER}
      header={'Transfer ownership'}
      submitButton={{
        label: 'Transfer ownership',
        buttonProps: { onClick: updateOwner, color: 'danger' },
      }}
    >
      <Typography style={{ marginBottom: 12, lineHeight: '22px' }}>
        This user will replace the role of the current Project Owner.
      </Typography>
    </Modal>
  );
};
