import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomDropdown } from 'components/CustomDropdown';
import { Dot } from 'components/Dot';
import { PageHeader } from 'components/layout';
import { Button, Row, Spacing, Tooltip, Typography, neutral, openModal, primary } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { red } from 'styles/theme';

import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-hour-4.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UserIcon } from 'assets/icons/users.svg';

import { mappingPath } from 'helpers';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import {
  setProjectState,
  useFetchProjectMembers,
  useProjectMembers,
  useProjectPermission,
  useProjectStore,
} from '../core';

export const ProjectHeader = () => {
  const curProject = useProjectStore((s) => s.state.curProject);
  const orgId = useOrgId();
  const navigate = useNavigate();
  const projMembers = useProjectMembers();
  const { isOwner } = useProjectPermission();

  const isProcessPage = location.pathname.includes('ded-process');
  const isBuildPage = location.pathname.includes('builds');
  const isLogPage = location.pathname.includes('action-logs');

  const { getProjMembers } = useFetchProjectMembers();

  useEffect(() => {
    if (curProject?.id && orgId) {
      getProjMembers();
    }

    return () => {
      setProjectState({ members: [] });
    };
  }, [curProject?.id, orgId]);

  const ids = [orgId, curProject?.id || ''];

  return (
    <StyledPageHeader>
      <Row align="center" style={{ height: '100%' }}>
        <Typography
          color="#8C8C8C"
          className="text-ellipsis-1"
          title={curProject?.subId}
          style={{ maxWidth: 100 }}
        >
          {curProject?.subId}
        </Typography>
        <Dot style={{ margin: '0 8px' }} />

        <Typography
          variant="h5"
          weight={500}
          className="text-ellipsis-1"
          title={curProject?.name}
          style={{ maxWidth: 'calc(100% - 360px)' }}
        >
          {curProject?.name}
        </Typography>

        <Row
          direction="column"
          className={`${isProcessPage ? 'active ' : ''}`}
          style={{ width: 'fit-content', height: '100%', marginLeft: 20 }}
        >
          <Button
            variant="text"
            size="small"
            icon={
              <div>
                <MapIcon width={18} height={18} />
              </div>
            }
            placementIcon="left"
            className={`${isProcessPage ? 'active ' : ''}prj`}
            onClick={() => navigate(mappingPath(PATH.PROJECT_DED_PROCESSES, ids))}
          >
            DED Processes
          </Button>
          <div className="underline" />
        </Row>
        <Row
          direction="column"
          className={`${isBuildPage ? 'active ' : ''}`}
          style={{ width: 'fit-content', height: '100%' }}
        >
          <Button
            variant="text"
            size="small"
            icon={
              <div>
                <BoxIcon width={18} height={18} />
              </div>
            }
            placementIcon="left"
            className={`${isBuildPage ? 'active ' : ''}prj`}
            onClick={() => navigate(mappingPath(PATH.PROJECT_BUILDS, ids))}
          >
            Builds
          </Button>
          <div className="underline" />
        </Row>
      </Row>
      <Row align="center" justify="end" style={{ width: 'fit-content' }}>
        <Spacing spacing="xs" placement={['left', 'right']} type="margin">
          <Button
            variant="outlined"
            color="default"
            icon={<UserIcon />}
            placementIcon="left"
            onClick={() => {
              openModal(MODAL_KEY.PROJECT_MEMBER);
            }}
          >
            {projMembers?.length || '0'}
          </Button>
        </Spacing>

        <Tooltip content="Action log" arrow={false} placement="bottom" offset={[0, 4]}>
          <div>
            <Button
              variant="text"
              icon={<ClockIcon />}
              placementIcon="left"
              size="small"
              className={isLogPage ? 'active' : ''}
              onClick={() => navigate(mappingPath(PATH.PROJECT_ACTION_LOG, ids))}
            />
          </div>
        </Tooltip>

        {isOwner ? (
          <CustomDropdown
            menu={{
              items: [
                {
                  key: MODAL_KEY.PROJECT_RENAME,
                  label: 'Rename project',
                  prefix: <EditIcon />,
                },
                {
                  key: MODAL_KEY.PROJECT_DELETE,
                  label: 'Delete project',
                  prefix: <TrashIcon className="danger-ic" />,
                  style: { color: red['red-6'] },
                },
              ],
              selectedKeys: [],
              onClick: (item) => {
                openModal(item.key);
              },
            }}
            dropdownConfig={{
              offset: [-86, 0],
            }}
          >
            <DotsIcon />
          </CustomDropdown>
        ) : null}
      </Row>
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled(PageHeader)`
  .prj {
    border-radius: 0px;
    height: 100%;
    padding: 0px 8px;
    gap: 4px;
    color: ${neutral['neutral-7']};
    background-color: transparent;
    transform: translateY(2px);
    svg path {
      stroke: ${neutral['neutral-7']};
    }
    &:hover {
      color: ${neutral['neutral-11']};
      background-color: transparent;
    }
  }
  button > div {
    padding: 3px;
    display: flex;
    align-items: center;
  }

  .underline {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${primary['primary-6']};
    opacity: 0;
  }

  .active .underline {
    opacity: 1;
  }
  button.active {
    color: ${primary['primary-6']} !important;
    svg path {
      stroke: ${primary['primary-6']} !important;
    }
  }
`;
