import { CustomTypo } from 'components/CustomTypography';
import { Modal, TextInput, closeModal, showToastMessage, useDialogStore } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { useOrgId } from 'modules/organization/core';
import { useFetchBuild, useProjectId } from 'modules/project/core';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

const schema = Yup.object({
  name: Yup.string().required().label('Name'),
});

export const CreateLinkBuildToNewProcessModal = () => {
  const orgId = useOrgId();
  const projectId = useProjectId();
  const fetchApi = useFetchBuild();

  const modalProps = useDialogStore(
    (s) => s.state.data[MODAL_KEY.BUILD_CREATE_LINK_DED_PROCESS_MODAL],
  );

  const buildId = modalProps?.buildId;

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: '' },
    onSubmit: async (values) => {
      const result = await apiCall({
        url: `/organizations/${orgId}/projects/${projectId}/builds/${buildId}/generate-process`,
        data: values,
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.BUILD_CREATE_LINK_DED_PROCESS_MODAL);
        fetchApi();
        showToastMessage('success', 'Created successfully!');
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.BUILD_CREATE_LINK_DED_PROCESS_MODAL}
      header="Create and link to a new DED Process"
      submitButton={{
        label: 'Create and link',
        buttonProps: { onClick: handleSubmit, disabled: !isValid },
      }}
      closeOnClickOutside={false}
    >
      <CustomTypo style={{ lineHeight: '22px', marginBottom: 16 }}>
        This will create a new DED Process containing the detected changes. The original process
        will remain unchanged.
      </CustomTypo>
      <TextInput
        title="Name of the new DED Process"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
