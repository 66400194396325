export const roundNumber = (num: number, decimal: number) => {
  return Number(num.toFixed(decimal));
};

type ClassValue = string | null | undefined;

export function clsx(...inputs: ClassValue[]) {
  let i = 0,
    tmp,
    str = '';
  const len = inputs.length;

  for (; i < len; i++) {
    if ((tmp = inputs[i])) {
      if (typeof tmp === 'string') {
        str += (str && ' ') + tmp;
      }
    }
  }

  return str;
}
