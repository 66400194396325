import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import { Typography, openModal, red } from 'enable-ui';

import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { MODAL_KEY } from 'constants/modal-key';

export const projectColumns = (onDelete?: () => void, userId?: string, isAdmin?: boolean) => {
  return [
    {
      id: 'subId',
      Header: 'Project ID',
      width: '15%',
      flex: 1,
      accessor: 'subId',
      sort: true,
      sortType: 'subId',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.subId}</CustomTypo>;
      },
    },
    {
      id: 'name',
      Header: 'Project name',
      accessor: 'name',
      width: '30%',
      flexGrow: 1,
      sort: true,
      sortType: 'name',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.name}</CustomTypo>;
      },
    },
    {
      id: 'ownership',
      Header: 'Owner',
      width: '90%',
      flexGrow: 1,
      Cell: ({ ...props }) => {
        return (
          <CustomTypo>
            {props.data?.[props?.row?.index || 0]?.members?.[0]?.member?.fullName}
          </CustomTypo>
        );
      },
    },

    {
      id: 'action',
      width: '20px',
      minWidth: '20px',
      Cell: ({ ...props }) => {
        return (
          <div
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={
              isAdmin || userId === props.data?.[props?.row?.index || 0]?.members?.[0]?.member?.id
                ? {}
                : { display: 'none' }
            }
          >
            <CustomDropdown
              size="small"
              TickIcon={<></>}
              menu={{
                onClick: (item) => {
                  openModal(item.key, {
                    proj: props.data?.[props?.row?.index || 0],
                    isBlockNavigate: true,
                    callback: onDelete,
                  });
                },
                items: [
                  {
                    key: MODAL_KEY.PROJECT_RENAME,
                    label: 'Rename project',
                    prefix: <EditIcon />,
                  },
                  {
                    key: MODAL_KEY.PROJECT_DELETE,
                    label: 'Delete project',
                    prefix: <Trash className="danger-ic" />,
                    style: { color: red['red-6'] },
                  },
                ],
                selectedKeys: [],
              }}
              dropdownConfig={{ trigger: 'click' }}
            >
              <div className="action-icon">
                <Dots />
              </div>
            </CustomDropdown>
          </div>
        );
      },
    },
  ];
};
