import { PageContent } from 'components/layout';

import { BuildManager } from '../components/builds/BuildManager';

export const ProjectBuildsPage = () => {
  return (
    <>
      <PageContent>
        <BuildManager />
      </PageContent>
    </>
  );
};
