import { useEffect, useMemo, useRef, useState } from 'react';

import { debounce } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export function useDefault<T>(defaultValue: T) {
  const [value, setValue] = useState(defaultValue);

  return { value, setValue };
}

export const useBoolean = (defaultValue = false) => {
  return useDefault(defaultValue);
};

export const useNumber = (defaultValue = 0) => {
  return useDefault(defaultValue);
};

export const useString = (defaultValue = '') => {
  return useDefault(defaultValue);
};

interface State {
  pageLoading?: boolean;
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
}

const initState: State = {
  pageLoading: false,
};

export const useGeneralStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const showPageLoading = () => {
  useGeneralStore.getState().setState({ pageLoading: true });
};

export const hidePageLoading = () => useGeneralStore.getState().setState({ pageLoading: false });

export const useDebounce = (callback: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref?.current?.();
    };

    return debounce(func, 200);
  }, []);

  return debouncedCallback;
};
