export const MODAL_KEY = Object.freeze({
  ORG_RENAME: 'ORG_RENAME',
  ORG_DELETE: 'ORG_DELETE',
  ORG_ADD: 'ORG_ADD',

  MEM_DELETE: 'MEM_DELETE',
  MEM_RESET: 'MEM_RESET',
  MEM_ADD: 'MEM_ADD',
  ADMIN_ADD: 'ADMIN_ADD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',

  PROJECT_RENAME: 'PROJECT_RENAME',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_ADD: 'PROJECT_ADD',
  PROJECT_MEMBER: 'PROJECT_MEMBER',
  PROJECT_REMOVE_MEMBER: 'PROJECT_REMOVE_MEMBER',
  PROJECT_CHANGE_OWNER: 'PROJECT_CHANGE_OWNER',

  PROCESS_DELETE: 'PROCESS_DELETE',
  PROCESS_LINK_POWDER: 'PROCESS_LINK_POWDER',

  FIELD_REMOVE: 'FIELD_REMOVE',
  TEMPLATE_DELETE: 'TEMPLATE_DELETE',
  TEMPLATE_CHANGE: 'TEMPLATE_CHANGE',
  ADD_CUSTOM_SECTION: 'ADD_CUSTOM_SECTION',

  VARIANT_SELECT: 'VARIANT_SELECT',

  PROCESS_BUILD_DELETE: 'PROCESS_BUILD_DELETE',
  PROCESS_BUILD_DUPLICATE: 'PROCESS_BUILD_DUPLICATE',
  CREATE_BUILD_FROM_PROCESS: 'CREATE_BUILD_FROM_PROCESS',
  PUBLISH_PROCESS: 'PUBLISH_PROCESS',
  LOG_DETAIL: 'LOG_DETAIL',

  BUILD_CHANGE_DED_PROCESS_MODAL: 'BUILD_CHANGE_DED_PROCESS_MODAL',
  BUILD_CREATE_LINK_DED_PROCESS_MODAL: 'BUILD_CREATE_LINK_DED_PROCESS_MODAL',
  BUILD_SYNC_MODAL: 'BUILD_SYNC_MODAL',
});
