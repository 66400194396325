import { CustomDropdown } from 'components/CustomDropdown';
import { CustomTypo } from 'components/CustomTypography';
import { Typography, openModal, red } from 'enable-ui';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

import { compareAsc, format } from 'date-fns';

import { MODAL_KEY } from 'constants/modal-key';

import { TemplateInfo } from '../components/TemplateInfo';
import { ProcessInfo } from '../components/ded-process/ProcessInfo';

export const buildColumns = (
  modalCallback?: () => Promise<void>,
  onDuplicate?: (id?: string) => Promise<void>,
  isAllowAction?: boolean,
) => {
  return [
    {
      id: 'subId',
      Header: 'Build ID',
      width: '15%',
      flex: 1,
      accessor: 'subId',
      sort: true,
      sortType: 'subId',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.subId}</CustomTypo>;
      },
    },
    {
      id: 'name',
      Header: 'Build name',
      accessor: 'name',
      width: '25%',
      flexGrow: 1,
      sort: true,
      sortType: 'name',
      Cell: ({ ...props }) => {
        return <CustomTypo>{props.data?.[props?.row?.index || 0]?.name}</CustomTypo>;
      },
    },
    {
      id: 'process.name',
      Header: 'Process source',
      width: '35%',
      flex: 1,
      accessor: 'process.name',
      sort: true,
      sortType: 'process.name',
      Cell: ({ ...props }) => {
        const process = props.data?.[props?.row?.index || 0]?.process;

        return <ProcessInfo process={process} />;
      },
    },
    {
      id: 'updatedAt',
      Header: 'Last update',
      width: '25%',
      flex: 1,
      accessor: 'updatedAt',
      sort: true,
      sortType: 'updatedAt',
      Cell: ({ ...props }) => {
        return (
          <CustomTypo style={{ color: '#8C8C8C' }}>
            {format(
              new Date(props.data?.[props?.row?.index || 0]?.updatedAt),
              'dd MMM yyyy, hh:mm a',
            )}
          </CustomTypo>
        );
      },
    },
    {
      id: 'action',
      width: '20px',
      minWidth: '20px',
      Cell: ({ ...props }) => {
        return (
          <div
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={isAllowAction ? {} : { display: 'none' }}
          >
            <CustomDropdown
              size="small"
              TickIcon={<></>}
              menu={{
                onClick(item) {
                  if (item.key === 'delete') {
                    openModal(MODAL_KEY.PROCESS_BUILD_DELETE, {
                      id: props.data?.[props?.row?.index || 0]?.id,
                      isBuild: true,
                      callback: modalCallback,
                    });
                  }

                  if (item.key === 'duplicate') {
                    onDuplicate?.(props.data?.[props?.row?.index || 0]?.id);
                  }
                },
                items: [
                  {
                    key: 'duplicate',
                    label: <Typography variant="body-1">Duplicate</Typography>,
                    prefix: <Copy />,
                  },
                  {
                    key: 'delete',
                    label: (
                      <Typography variant="body-1" color={red['red-6']}>
                        Delete
                      </Typography>
                    ),
                    prefix: <Trash className="delete-icon" />,
                  },
                ],
                selectedKeys: [],
              }}
              dropdownConfig={{ trigger: 'click' }}
            >
              <div className="action-icon">
                <Dots />
              </div>
            </CustomDropdown>
          </div>
        );
      },
    },
  ];
};
