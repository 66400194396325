import type { DeepPartial } from 'enable-ui';

import type { DefaultTemplate, TemplateSection, TemplateType } from 'modules/template/core';

import type { DedProcess, TemplateSelectionState } from '../type';

import { cloneDeep, findIndex, merge, xorBy } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { DEFAULT_PROCESS_TEMPLATE_ID, defaultDedProcess } from '../constant';
import { mappingSection } from '../helpers';

export enum ExportType {
  All = 'All',
  Selected = 'Selected',
  None = 'None',
}

interface ExportPayload {
  type: ExportType;
  ids: string[];
}

interface State {
  templates: Record<TemplateType, DefaultTemplate[]>;

  processName: string;

  ['DEDSystem']: TemplateSelectionState;
  ['Process']: TemplateSelectionState;
  ['Powder']: TemplateSelectionState[];

  dedProcess: DedProcess;
  selectedIndex: string;

  openActivityLog: boolean;

  export: ExportPayload;
}

interface Store {
  state: State;

  setState: (payload: Partial<State>) => void;
  mergeState: (payload: DeepPartial<State>) => void;
  clearState: () => void;

  setTemplatesByType: (type: TemplateType, payload: DefaultTemplate[]) => void;

  setProcessSectionsByType: (
    type: TemplateType,
    payload: TemplateSection[],
    index?: number,
  ) => void;

  toggleExpandProcessVariantByType: (type: TemplateType, index?: number) => void;

  selectProcessTemplateByType: (type: TemplateType, id: string, index?: number) => void;

  selectProcessSectionVariantByType: (
    type: TemplateType,
    payload: TemplateSection,
    index?: number,
    variantIndex?: number,
  ) => void;

  setExportPayload: (payload: ExportPayload) => void;
  resetExportPayload: () => void;
}

const defaultSelectionState: TemplateSelectionState = {
  selectedId: '',
  sections: [],
  selectedVariants: [],
  expand: true,
};

const initState: State = {
  templates: {
    DEDSystem: [],
    Powder: [],
    Process: [],
  },

  processName: '',

  DEDSystem: defaultSelectionState,
  Process: defaultSelectionState,
  Powder: [],

  dedProcess: defaultDedProcess,
  selectedIndex: DEFAULT_PROCESS_TEMPLATE_ID,

  openActivityLog: false,

  export: {
    type: ExportType.None,
    ids: [],
  },
};

export const useProcessStore = create(
  immer<Store>((set) => ({
    setTemplatesByType: (type, payload) => {
      set((store) => {
        store.state.templates[type] = payload;
      });
    },
    selectProcessTemplateByType: (type, id, index = 0) => {
      set((store) => {
        if (type === 'Powder') {
          if (store.state[type][index]) {
            if (id) {
              store.state[type][index].selectedId = id;
            } else {
              store.state[type][index] = defaultSelectionState;
            }
          } else {
            store.state[type].push({
              ...defaultSelectionState,
              selectedId: id,
            });
          }
        } else {
          store.state[type].selectedId = id;
          store.state[type].expand = true;
        }
      });
    },
    toggleExpandProcessVariantByType: (type, index = 0) => {
      set((store) => {
        if (type === 'Powder') {
          store.state[type][index || 0].expand = !store.state[type][index || 0].expand;
        } else {
          store.state[type].expand = !store.state[type].expand;
        }
      });
    },
    setProcessSectionsByType: (type, payload, index = 0) => {
      const sections = mappingSection(payload);
      const selectedVariants = sections.map((el) => el[0]) || [];

      set((store) => {
        if (type === 'Powder') {
          if (store.state[type][index]) {
            store.state[type][index].sections = sections;
            store.state[type][index].selectedVariants = selectedVariants;
          } else {
            store.state[type].push({
              ...defaultSelectionState,
              sections,
              selectedVariants,
            });
          }
        } else {
          store.state[type].sections = sections;
          store.state[type].selectedVariants = selectedVariants;
        }
      });
    },
    selectProcessSectionVariantByType: (type, payload, index = 0, variantIndex = 0) => {
      set((store) => {
        if (type === 'Powder') {
          const oldIndex = findIndex(store.state[type][index]?.selectedVariants, {
            masterId: payload?.masterId,
          });

          store.state[type][index].selectedVariants[oldIndex] = payload;
        } else if (payload.multipleSelected) {
          const newVariants = xorBy(store.state[type].selectedVariants, [payload], 'id');
          const isRemove = newVariants.length < store.state[type].selectedVariants.length;

          store.state[type].selectedVariants = newVariants;

          if (isRemove && store.state.Powder.length > 1) {
            store.state.Powder.pop();
          }
        } else {
          const oldIndex = findIndex(store.state[type].selectedVariants, {
            masterId: payload?.masterId,
          });

          store.state[type].selectedVariants[oldIndex] = payload;
        }
      });
    },

    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    mergeState: (payload) => {
      set((store) => {
        store.state = merge(store.state, payload);
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },

    setExportPayload: (payload: ExportPayload) => {
      set((store) => {
        store.state.export = payload;
      });
    },
    resetExportPayload: () => {
      set((store) => {
        store.state.export = {
          type: ExportType.None,
          ids: [],
        };
      });
    },
  })),
);

export const {
  setState: setProcessState,
  clearState: clearProcessState,

  selectProcessSectionVariantByType,
  selectProcessTemplateByType,
  setProcessSectionsByType,
  setTemplatesByType,
  toggleExpandProcessVariantByType,
  setExportPayload,
  resetExportPayload,
} = useProcessStore.getState();

export const useSelectedVariants = () =>
  useProcessStore((s) => {
    const processVariants = s.state.Process.selectedVariants;
    const dedModelVariants = s.state.DEDSystem.selectedVariants;
    const powderVariants = s.state.Powder.flatMap((pow) => pow.selectedVariants);

    return [...processVariants, ...dedModelVariants, ...powderVariants];
  });
