import { useNavigate } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { Row, Spacing, Typography, neutral } from 'enable-ui';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';
import { useOrgId } from 'modules/organization/core';

import { Container, UserAction, UserActionItem } from '../styles/user-profile';

import { ReactComponent as DefaultAvatar } from 'assets/icons/Avatar.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as UserCicle } from 'assets/icons/user-circle.svg';

import { mappingPath } from 'helpers';

import { APP_VERSION } from 'constants/environment';
import { PATH } from 'constants/path';

import { onLogout } from '../core/controller';

export const PopoverUserProfile: React.FC<{
  setVisible: (value: boolean) => void;
}> = ({ setVisible }) => {
  const userProfile = useAuthStore(userProfileSelector);
  const navigate = useNavigate();
  const orgId = useOrgId();

  return (
    <Container>
      <Spacing spacing="md" className="bg-white" style={{ height: 'fit-content' }}>
        <Row align="center" className="bg-white">
          <DefaultAvatar width={44} height={44} style={{ minWidth: 44 }} />
          <Spacing spacing="sm" type="margin" placement="left">
            <Row direction="column" className="bg-white" style={{ alignItems: 'start' }}>
              <div style={{ width: '100%', display: 'flex' }}>
                <Typography
                  weight={500}
                  variant="h5"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '10rem',
                    padding: 0,
                  }}
                >
                  {userProfile?.fullName}
                </Typography>
              </div>
              <div style={{ width: '100%', display: 'flex' }}>
                <Typography
                  variant="body-1"
                  weight={400}
                  color={neutral['neutral-7']}
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '10rem',
                  }}
                >
                  {userProfile?.email}
                </Typography>
              </div>
            </Row>
          </Spacing>
        </Row>
      </Spacing>
      <UserAction direction="column">
        <UserActionItem
          className="user-item"
          align="center"
          onClick={() => {
            navigate(mappingPath(PATH.USER_PROFILE, [orgId]));
            setVisible(false);
          }}
        >
          <UserCicle style={{ minWidth: 20 }} />
          <CustomTypo
            weight={400}
            variant="body-1"
            style={{
              marginLeft: 8,
            }}
          >
            My profile
          </CustomTypo>
        </UserActionItem>
        <UserActionItem className="user-item" align="center" onClick={onLogout}>
          <Logout style={{ minWidth: 20 }} />
          <CustomTypo
            weight={400}
            variant="body-1"
            style={{
              marginLeft: 8,
            }}
          >
            Log out
          </CustomTypo>
        </UserActionItem>
      </UserAction>
      <Typography variant="body-2" style={{ padding: '8px 16px' }} color={neutral['neutral-7']}>
        {APP_VERSION}
      </Typography>
    </Container>
  );
};
