import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { MasterLayout } from 'components/layout/MasterLayout';
import { UserProfilePage } from 'components/layout/components/UerProfilePage';

import { ProcessSearch } from 'modules/advanced-search/pages/ProcessSearch';
import { useIsAdmin } from 'modules/auth/core';
import { OrgActionLogsPage, ProjectActionLogsPage } from 'modules/log/pages';
import { useInitOrg } from 'modules/organization/core/controller';
import { useOrgId } from 'modules/organization/core/store';
import { ProjectHeader } from 'modules/project/components/ProjectHeader';
import { useInitProject } from 'modules/project/core';
import {
  AddProcessPage,
  EditProcessPage,
  ProjectBuildsPage,
  ProjectProcessesPage,
} from 'modules/project/pages';
import { AddBuildPage } from 'modules/project/pages/AddBuildPage';
import { EditBuildPage } from 'modules/project/pages/EditBuildPage';
import { ProjectPage } from 'modules/project/pages/ProjectPage';
import { useInitTemplateSections } from 'modules/template/core';
import { AddTemplatePage, TemplatePage } from 'modules/template/pages';
import { EditTemplatePage } from 'modules/template/pages/EditTemplatePage';
import { UserPage } from 'modules/user/pages';
import { AdminPage } from 'modules/user/pages/AdminPage';
import { AllMembersPage } from 'modules/user/pages/AllMembersPage';

import { mappingPath } from 'helpers';
import { isNil } from 'lodash';

import { PATH } from 'constants/path';

const PrivateRoutes = () => {
  useInitOrg();
  useInitProject();
  useInitTemplateSections();
  const isAdmin = useIsAdmin();

  const orgId = useOrgId();

  const memberPage = mappingPath(PATH.MEMBERS, [orgId]);

  const projectPage = mappingPath(PATH.PROJECTS, [orgId]);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={PATH.MEMBERS} element={<UserPage />} />
        <Route path={PATH.ADMINS} element={<AdminPage />} />
        <Route path={PATH.ALL_MEMBERS} element={<AllMembersPage />} />
        <Route path={PATH.USER_PROFILE} element={<UserProfilePage />} />
        <Route path={PATH.CHANGE_PASSWORD} element={<UserProfilePage />} />
        <Route path={PATH.ORG_ACTION_LOGS} element={<OrgActionLogsPage />} />

        <Route path={PATH.TEMPLATE_EDIT} element={<EditTemplatePage />} />
        <Route path={PATH.TEMPLATES} element={<TemplatePage />} />
        <Route path={PATH.TEMPLATE_ADD} element={<AddTemplatePage />} />
        <Route path={PATH.PROJECTS} element={<ProjectPage />} />
        <Route path={PATH.SEARCH} element={<ProcessSearch />} />

        <Route
          element={
            <>
              <ProjectHeader />
              <Outlet />
            </>
          }
        >
          <Route path={PATH.PROJECT_DED_PROCESSES} element={<ProjectProcessesPage />} />
          <Route path={PATH.PROJECT_DED_PROCESS_ADD} element={<AddProcessPage />} />
          <Route path={PATH.PROJECT_DED_PROCESS_EDIT} element={<EditProcessPage />} />

          <Route path={PATH.PROJECT_BUILDS} element={<ProjectBuildsPage />} />
          <Route path={PATH.PROJECT_BUILD_ADD} element={<AddBuildPage />} />
          <Route path={PATH.PROJECT_BUILD_EDIT} element={<EditBuildPage />} />
          <Route path={PATH.PROJECT_ACTION_LOG} element={<ProjectActionLogsPage />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={!isNil(isAdmin) && isAdmin ? memberPage : projectPage} />}
        />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
