import { type CSSProperties, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { CustomTypo } from 'components/CustomTypography';
import { DropdownWrapper } from 'components/DropdownWrapper';
import { Tabs } from 'components/Tabs';
import {
  Button,
  CalendarInput,
  Checkbox,
  Dropdown,
  IconClose,
  Row,
  TextInput,
  showToastMessage,
} from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import { LogObjectType } from 'modules/log/core';
import { useActivityLogStore } from 'modules/log/core/store';
import { useOrgId } from 'modules/organization/core';
import {
  BUILD_INFO_FORM_FIELDS,
  BUILD_PROPERTIES_FORM_FIELDS,
  useProjectId,
  useProjectPermission,
} from 'modules/project/core';
import {
  useEditBuildController,
  useFetchBuild,
} from 'modules/project/core/controller/build-edit.controller';
import { reorderTemplates } from 'modules/project/core/helpers';
import { useBuildStore } from 'modules/project/core/store/build.store';
import { TemplatePropertyCard } from 'modules/template/components/section-attribute/TemplatePropertyCard';
import { SectionBlockWrapper } from 'modules/template/components/section-attribute/styles';
import { defaultTemplate } from 'modules/template/core';

import { ReactComponent as AddIcon } from 'assets/icons/plus-thick.svg';

import { format } from 'date-fns';
import { apiCall } from 'hooks/service';
import { isEqual, isNil, omit, partition, xor } from 'lodash';

export const ProjectBuildSection = () => {
  const fetchApi = useFetchBuild();
  const orgId = useOrgId();
  const projId = useProjectId();
  const { isMember } = useProjectPermission();
  const setState = useBuildStore((s) => s.setState);

  const { selectedSections } = useEditBuildController();
  const build = useBuildStore((s) => omit(s.state.build, 'process'));

  const selectedIndex = useBuildStore((s) => s.state.selectedIndex);
  const openAddPropDropdown = useBuildStore((s) => s.state.openAddPropDropdown);
  const selectingIds = useBuildStore((s) => s.state.selectingIds);
  const selectedTemplate = useBuildStore(
    (s) =>
      reorderTemplates(s.state.build.process.templates)[Number(selectedIndex)] || defaultTemplate,
  );

  const powderTemplates = useBuildStore((s) =>
    s.state.build.process.templates.filter((el) => el.type === 'Powder'),
  );

  const buildGeneralForm = useFormManager({
    defaultValues: build,
    onSubmit: async (values) => {
      const result = await apiCall({
        url: `/organizations/${orgId}/projects/${projId}/builds/${build.id}`,
        method: 'PATCH',
        data: { ...values, date: buildDate },
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        setState({ build: result });
        showToastMessage('success', 'Saved successfully!');
        useActivityLogStore.getState().clearState(LogObjectType.Build, true);
      }
    },
  });

  const buildDate = buildGeneralForm.form.watch('date');
  const [remainProps, curProps] = useMemo(
    () =>
      partition(BUILD_PROPERTIES_FORM_FIELDS, (el) =>
        isNil(buildGeneralForm.form.getValues(el.accessor as any)),
      ),
    [buildGeneralForm.form.watch()],
  );

  useEffect(() => {
    buildGeneralForm.form.reset(build);
  }, [build.id, selectedIndex]);

  const toggleId = (id: string) => {
    setState({
      selectingIds: xor(selectingIds, [id]),
    });
  };

  if (selectedSections?.length) {
    return (
      <TemplatePropertyCard
        originSections={selectedTemplate.sections}
        templateId={selectedTemplate.id}
        templateIndex={Number(selectedIndex)}
        uploadTargetId={build.id}
        powderTemplates={powderTemplates}
        submitSuccessCallback={fetchApi}
        options={{
          collapsible: true,
          propertyValueEditable: isMember,
          propertyModifiable: isMember && selectedTemplate.type?.toString() === 'BuildProperties',
          propertyModifiableCustomOnly: isMember,
          propertyNameEditable: isMember && selectedTemplate.type?.toString() === 'BuildProperties',
          propertyRemovable: isMember && selectedTemplate.type?.toString() === 'BuildProperties',
        }}
      />
    );
  }

  const noPermissionStyle: CSSProperties = isMember ? {} : { pointerEvents: 'none' };

  return (
    <SectionBlockWrapper>
      {selectedIndex === 'build-information' ? (
        <>
          <CustomTypo className="h3-5-medium" style={{ marginTop: 12 }}>
            Build information
          </CustomTypo>
          <Row style={{ padding: '24px 0px', gap: 16 }} direction="column">
            <Row>
              <div style={{ flex: 1 }}>
                <CustomTypo weight={600}>Process ID</CustomTypo>
                <CustomTypo style={{ marginTop: 4 }}>{build.subId}</CustomTypo>
              </div>
              <div style={{ flex: 1 }}>
                <CustomTypo weight={600}>Last update</CustomTypo>
                <CustomTypo style={{ marginTop: 4 }}>
                  {build.updatedAt ? format(new Date(build.updatedAt), 'dd MMM, hh:mm a') : ''}
                </CustomTypo>
              </div>
            </Row>

            {BUILD_INFO_FORM_FIELDS.map((el) => {
              if (el.accessor === 'date') {
                return (
                  //To-do implement design: Allow null value
                  <CalendarInput
                    key={el.accessor}
                    title="Date"
                    dropdownConfig={{
                      hideOnClick: true,
                    }}
                    onChange={(newDate) => {
                      buildGeneralForm.form.setValue('date', newDate.toISOString());
                    }}
                    date={buildDate ? new Date(buildDate) : (null as any)}
                    containerStyle={noPermissionStyle}
                  />
                );
              }

              return (
                <TextInput
                  key={el.accessor}
                  title={el.title}
                  {...buildGeneralForm.form.register(el.accessor as any)}
                  {...buildGeneralForm.registerError(el.accessor as any)}
                  containerStyle={noPermissionStyle}
                />
              );
            })}
          </Row>
        </>
      ) : null}

      {isMember && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={buildGeneralForm.handleSubmit}
          disabled={
            !buildGeneralForm.form.watch('name') ||
            isEqual(
              omit(buildGeneralForm.form.watch(), [
                'updatedAt',
                'createdBy',
                'buildProperty.createdAt',
                'buildProperty.id',
                'buildProperty.updatedAt',
              ]),
              omit(build, [
                'updatedAt',
                'createdBy',
                'buildProperty.createdAt',
                'buildProperty.id',
                'buildProperty.updatedAt',
              ]),
            )
          }
        >
          Save
        </Button>
      )}
    </SectionBlockWrapper>
  );
};
