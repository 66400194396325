import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { ReactComponent as Copy } from 'assets/icons/copy.svg';

import { MODAL_KEY } from 'constants/modal-key';

export const NewPasswordModal = () => {
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.NEW_PASSWORD]);

  return (
    <Modal
      modalKey={MODAL_KEY.NEW_PASSWORD}
      header={'Account information'}
      submitButton={{
        label: 'Done',
        buttonProps: {
          onClick: () => {
            closeModal(MODAL_KEY.NEW_PASSWORD);
          },
          color: 'primary',
        },
      }}
      cancelButton={{
        hide: true,
      }}
    >
      <Typography>
        E-mail: <strong>{modalProps?.account?.email}</strong>
      </Typography>
      <Typography style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        New password: <strong>{modalProps?.account?.password}</strong>
        <Copy
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigator.clipboard.writeText(modalProps?.account?.password);
            showToastMessage('success', 'Password copied!', { autoClose: 200 });
          }}
        />
      </Typography>
    </Modal>
  );
};
