import Yup from 'helpers/validation-yup';

export const nameSchema = Yup.object({
  fullName: Yup.string().required().label('Name'),
});

export const passwordSchema = Yup.object({
  oldPassword: Yup.string().min(6).label('Password'),
  newPassword: Yup.string().min(6).label('Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
    .label('Confirm Password')
    .required('Please confirm your password'),
});
