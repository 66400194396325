import { Modal, TextInput, closeModal, showToastMessage } from 'enable-ui';
import { useFormManager } from 'form/form-manager';

import Yup from 'helpers/validation-yup';
import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { renameOrgState, useOrgStore } from '../../core/store';

export const RenameOrgModal = () => {
  const curOrg = useOrgStore((s) => s.state.curOrg);

  const schema = Yup.object({
    name: Yup.string()
      .notOneOf([curOrg?.name || ''])
      .required()
      .label('Name'),
  });

  const {
    form: {
      register,
      formState: { isValid },
    },
    handleSubmit,
  } = useFormManager({
    schema,
    defaultValues: { name: curOrg?.name || '' },
    onSubmit: async (values) => {
      if (!curOrg) {
        return;
      }

      const result = await apiCall({
        url: `/organizations/${curOrg?.id}`,
        data: values,
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        closeModal(MODAL_KEY.ORG_RENAME);
        renameOrgState({
          ...curOrg,
          name: values.name || curOrg.name,
        });
        showToastMessage('success', 'Renamed successfully!');
      }
    },
  });

  return (
    <Modal
      modalKey={MODAL_KEY.ORG_RENAME}
      header="Rename Organization"
      cancelButton={{ hide: true }}
      submitButton={{ label: 'Save', buttonProps: { onClick: handleSubmit, disabled: !isValid } }}
    >
      <TextInput
        title="Organization name"
        {...register('name')}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </Modal>
  );
};
