import { useEffect, useRef } from 'react';

import { openModal, showToastMessage, updateQueryStateTable, useTableStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';
import { useProjectPermission } from 'modules/project/core/controller';
import {
  ExportType,
  resetExportPayload,
  setExportPayload,
  useProcessStore,
  useProjectId,
} from 'modules/project/core/store';

import { format } from 'date-fns';
import { downloadURI } from 'helpers/file';
import { apiCall } from 'hooks/service';
import { useTableManager } from 'hooks/table';
import { isEqual } from 'lodash';

import { initialQueryState } from 'constants/general';
import { MODAL_KEY } from 'constants/modal-key';

export const processTableKey = 'processes';

export const useProcessesManagerController = () => {
  const orgId = useOrgId();
  const queryState = useTableStore((s) => s.state.queryState[processTableKey]);

  const fetchDataUrl = orgId ? `/organizations/${orgId}/processes` : '';

  const {
    rowItems,
    hasNoData,
    isLoading,
    isFirstLoad,
    searchValue,
    total,

    onSearchChange,
    fetchData,
    onDeleteCallback,
  } = useTableManager(processTableKey, fetchDataUrl, {
    dependencies: [orgId],
    params: { org_id: orgId },
    fetchDataCallback: (response) => {
      ids.current = response.listIds || [];
      const newExportIds = exportIds.filter((el) => ids.current.includes(el));
    },
  });

  const exportPayload = useProcessStore((s) => s.state.export);
  const exportType = exportPayload.type;
  const exportIds = exportPayload.ids;
  const ids = useRef<string[]>([]);

  const { isMember, isOwner } = useProjectPermission();
  const isAllowExport = isOwner;
  const isAllowAdd = isMember;

  useEffect(() => {
    return function unsubscribe() {
      ids.current = [];
      resetExportPayload();
    };
  }, [orgId]);

  const duplicateProcess = async (processId?: string) => {
    if (!processId) return;

    openModal(MODAL_KEY.PROCESS_BUILD_DUPLICATE, {
      id: processId,
      callback: () => {
        if (isEqual(initialQueryState, queryState)) {
          fetchData();
        } else {
          updateQueryStateTable(processTableKey, initialQueryState);
        }
      },
    });
  };

  return {
    orgId,

    rowItems,
    total,

    hasNoData,
    isLoading: isLoading.value,
    isFirstLoad,
    searchValue,
    queryState,
    onSearchChange,
    fetchData,
    onDeleteCallback,
    isAllowExport,
    isAllowAdd,
    duplicateProcess,
    exportData: {
      exportType,
      exportIds,
    },
  };
};
