import styled from 'styled-components';

import { Dropdown, type DropdownProps, neutralTrans, red } from 'enable-ui';

const DropdownWrapper = styled.div`
  .dropdown-children {
    width: 32px !important;
    height: 32px;
    padding: 6px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background: ${neutralTrans['neutralTrans-10']};
    }
  }

  .dropdown {
    min-width: 200px;
    max-width: 200px;
    padding: 4px 0;
  }

  .prefix .danger-ic path[stroke] {
    stroke: ${red['red-6']};
  }
`;

export const CustomDropdown: React.FC<DropdownProps> = (props) => {
  return (
    <DropdownWrapper>
      <Dropdown {...props}></Dropdown>
    </DropdownWrapper>
  );
};
