import { useNavigate } from 'react-router-dom';

import { CustomTypo } from 'components/CustomTypography';
import { TableLoadingRow, TableWrapper } from 'components/TableWrapper';
import {
  Button,
  Row,
  Table,
  TextInput,
  Typography,
  openModal,
  updateQueryStateTable,
  useTableStore,
} from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { neutral, neutralTrans } from 'styles/theme';

import { ReactComponent as Box } from 'assets/icons/box.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

import { mappingPath } from 'helpers';
import { useTableManager } from 'hooks/table';
import { isEqual } from 'lodash';

import { initialQueryState } from 'constants/general';
import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { useProjectId } from '../../core';
import { buildColumns } from '../../core/BuildColumns';
import { useProjectPermission } from '../../core/controller';

const tableKey = 'builds';

export const BuildManager = () => {
  const navigate = useNavigate();
  const orgId = useOrgId();
  const projId = useProjectId();
  const { isMember } = useProjectPermission();
  const queryState = useTableStore((s) => s.state.queryState[tableKey]);

  const fetchDataUrl = orgId && projId ? `/organizations/${orgId}/projects/${projId}/builds` : '';

  const {
    rowItems,
    hasNoData,
    isLoading: { value: isLoading },
    isFirstLoad,
    searchValue,
    total,

    onSearchChange,
    onDeleteCallback,
    fetchData,
  } = useTableManager(tableKey, fetchDataUrl);

  const duplicateBuild = async (buildId?: string) => {
    if (!buildId) return;

    openModal(MODAL_KEY.PROCESS_BUILD_DUPLICATE, {
      id: buildId,
      isBuild: true,
      callback: () => {
        if (isEqual(initialQueryState, queryState)) {
          fetchData();
        } else {
          updateQueryStateTable(tableKey, initialQueryState);
        }
      },
    });
  };

  const renderContent = () => {
    if (isFirstLoad) {
      return TableLoadingRow;
    }

    if (!isLoading && !rowItems.length) {
      return queryState?.search ? (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Search className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']}>
            There is no result matching your search
          </Typography>
        </Row>
      ) : (
        <Row direction="column" align="center" style={{ gap: 8, margin: '80px 0px' }}>
          <Box className="empty-data-icon" />
          <Typography variant="body-1" color={neutral['neutral-7']} style={{ marginBottom: 24 }}>
            There is no Build yet.
          </Typography>

          {!isMember ? null : (
            <Button
              color="primary"
              icon={<Plus />}
              placementIcon="left"
              onClick={() => navigate(mappingPath(PATH.PROJECT_BUILD_ADD, [orgId, projId]))}
            >
              Add a Build
            </Button>
          )}
        </Row>
      );
    }

    return (
      <>
        <Table
          dataSource={isLoading ? [] : rowItems}
          columns={buildColumns(onDeleteCallback, duplicateBuild, isMember || false)}
          queryState={queryState}
          updateQueryState={updateQueryStateTable}
          tableKey="builds"
          total={total}
          paginationConfigs={{ showRowsPerPage: true, showTotal: true, show: !isLoading }}
          headerConfigs={{
            styledTr: {
              backgroundColor: 'transparent',
              height: 48,
              borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
            },
          }}
          onClickRow={(row) => {
            navigate(
              mappingPath(PATH.PROJECT_BUILD_EDIT, [
                orgId || '',
                projId || '',
                row?.original?.id || '',
              ]),
            );
          }}
          bodyConfigs={{ isSameRowBg: true }}
        />

        {isLoading ? TableLoadingRow : null}
      </>
    );
  };

  return (
    <TableWrapper className={isLoading || !rowItems.length ? 'dt-empty' : ''}>
      <Row style={{ padding: 24 }} justify="space-between" align="center">
        <CustomTypo variant="h3" weight={500}>
          Builds
        </CustomTypo>

        {hasNoData || !isMember ? null : (
          <Row style={{ width: 'fit-content', gap: 8 }}>
            <Button
              color="primary"
              icon={<Plus />}
              placementIcon="left"
              onClick={() => navigate(mappingPath(PATH.PROJECT_BUILD_ADD, [orgId, projId]))}
            >
              Add a Build
            </Button>
          </Row>
        )}
      </Row>

      {!hasNoData && (
        <Row style={{ padding: '0px 24px' }} justify="start" align="center">
          <TextInput
            size="default"
            containerStyle={{ width: 320 }}
            prefix={<Search />}
            placeholder="Search with ID or Build name"
            onChange={onSearchChange}
            value={searchValue}
          />
        </Row>
      )}

      {renderContent()}
    </TableWrapper>
  );
};
