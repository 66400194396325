import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Row } from 'enable-ui';

import type { ActivityLog } from 'modules/log/core';
import { ACTION_LOG_CONFIG } from 'modules/organization/core/constant';

import { ReactComponent as DotIcon } from 'assets/icons/timeline-dot.svg';

import { format } from 'date-fns';
import { useNumber } from 'hooks';

import { CustomTypo } from './CustomTypography';

export const ActionLogRow = ({
  createdBy,
  createdAt,
  oldContent,
  newContent,
  type,
  objectSubId,
}: ActivityLog) => {
  const ref = useRef<HTMLDivElement>(null);

  const height = useNumber();

  const renderLogContent = () => {
    if (oldContent.name && newContent.name) {
      return (
        <>
          {oldContent.name}
          <span> to </span>
          {newContent.name}
        </>
      );
    }

    return newContent.name || oldContent.name;
  };

  useEffect(() => {
    if (ref.current) {
      height.setValue(ref.current.clientHeight - 20);
    }
  }, []);

  return (
    <Row style={{ gap: 16, minHeight: 72 }} ref={ref}>
      <div style={{ width: 20, height: height.value }}>
        <DotIcon style={{ height: 20, width: 20 }} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="100%"
          viewBox="0 0 20 52"
          fill="none"
          preserveAspectRatio="none"
          style={{ flex: 1, height: '100%' }}
        >
          <path
            d="M10 0V52"
            stroke="#DDE8F3"
            strokeDasharray="4,4"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>

      <Wrapper>
        <CustomTypo weight={600}>
          {createdBy?.fullName} {createdBy ? `(${createdBy.email})` : <i>[Deleted account]</i>}
          <span> {ACTION_LOG_CONFIG[type]} </span>
          {renderLogContent()}
        </CustomTypo>
        <CustomTypo variant="body-2" style={{ marginTop: 6, color: '#8C8C8C' }}>
          {format(new Date(createdAt), 'dd MMM yyyy')} at {format(new Date(createdAt), 'hh:mm a')}
        </CustomTypo>
      </Wrapper>
    </Row>
  );
};

const Wrapper = styled.div`
  padding-bottom: 6px;
  span {
    font-weight: normal;
  }
`;
