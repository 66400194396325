import { isArray, isString } from 'lodash';

import { ERROR_MESSAGE } from 'constants/message';

export const getErrorMessage = (errors: any) => {
  console.log(errors);

  if (errors?.response?.data?.error?.response?.details) {
    return errors?.response?.data?.error?.response?.details?.[0];
  }

  if (isArray(errors.errors) && errors.errors?.length) {
    return errors.errors[0]?.message || errors.message;
  }

  if (errors?.response?.data?.message) {
    return errors.response.data.message;
  }

  if (errors.message) {
    return errors.message;
  }

  if (isString(errors.errors)) {
    return errors.errors;
  }

  return ERROR_MESSAGE.UNKNOWN;
};
