import { Modal, Typography, closeModal, showToastMessage, useDialogStore } from 'enable-ui';

import { useOrgId } from 'modules/organization/core';

import { apiCall } from 'hooks/service';

import { MODAL_KEY } from 'constants/modal-key';

import { ProcessStatus, useProjectId } from '../../core';

export const PublishProcessModal = () => {
  const projId = useProjectId();
  const orgId = useOrgId();
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.PUBLISH_PROCESS]);

  const { id, callback } = modalProps || {};

  const onClick = async () => {
    if (!projId || !orgId) return;

    const result = await apiCall({
      url: `/organizations/${orgId}/projects/${projId}/processes/${id}`,
      data: { status: ProcessStatus.Publish },
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result.id) {
      closeModal(MODAL_KEY.PUBLISH_PROCESS);
      callback?.();
      showToastMessage('success', 'Publish successfully!');
    }
  };

  return (
    <Modal
      modalKey={MODAL_KEY.PUBLISH_PROCESS}
      header={'Publish process'}
      submitButton={{
        label: 'Publish process',
        buttonProps: { onClick },
      }}
    >
      <Typography style={{ lineHeight: '22px' }}>Are you sure to publish this process?</Typography>
    </Modal>
  );
};
