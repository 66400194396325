import { TEMPLATE_CONFIG, type TemplateType } from 'modules/template/core';

import { type DedProcess, ProcessStatus, type Project } from './type';

export const defaultState = Object.freeze<Project>({
  name: '',
  createdAt: '',
  id: '',
  subId: '',
});

export const DEFAULT_PROCESS_TEMPLATE_ID = 'ded_process_information';

export const defaultDedProcess: DedProcess = {
  id: '',
  name: '',
  project: {
    id: '',
    subId: '',
    name: '',
    createdAt: '',
  },
  subId: '',
  templates: [],
  updatedAt: '',
  status: ProcessStatus.Draft,
};

export const BUILD_INFO_MENU_ITEMS = [
  {
    key: 'build-information',
    label: 'Build information',
  },
  {
    key: '0',
    label: 'Build properties',
  },
];

export const BUILD_PROCESS_MENU_ITEMS: { key: TemplateType; label: string }[] = [
  {
    key: 'DEDSystem',
    label: TEMPLATE_CONFIG['DEDSystem'].processTitle,
  },
  {
    key: 'Powder',
    label: TEMPLATE_CONFIG['Powder'].processTitle,
  },
  {
    key: 'Process',
    label: TEMPLATE_CONFIG['Process'].processTitle,
  },
];

export const BUILD_INFO_FORM_FIELDS: Readonly<{ title: string; accessor: string }[]> = [
  {
    title: 'Build name',
    accessor: 'name',
  },
  {
    title: 'Date',
    accessor: 'date',
  },
  {
    title: 'Start time',
    accessor: 'startTime',
  },
  {
    title: 'Total run time',
    accessor: 'totalRunTime',
  },
];

export const BUILD_PROPERTIES_FORM_FIELDS: Readonly<
  { title: string; accessor: string; key: string }[]
> = [
  {
    title: 'Porosity',
    accessor: 'buildProperty.porosity',
    key: 'porosity',
  },
  {
    title: 'Yield strength',
    accessor: 'buildProperty.yieldStrength',
    key: 'yieldStrength',
  },
  {
    title: 'Charpy',
    accessor: 'buildProperty.charpy',
    key: 'charpy',
  },
  {
    title: 'Facture toughness',
    accessor: 'buildProperty.fractureToughness',
    key: 'fractureToughness',
  },
  {
    title: 'Creep',
    accessor: 'buildProperty.creep',
    key: 'creep',
  },
  {
    title: 'Fatigure',
    accessor: 'buildProperty.fatigure',
    key: 'fatigure',
  },
  {
    title: 'Hardness',
    accessor: 'buildProperty.hardness',
    key: 'hardness',
  },
  {
    title: 'Corrosion resistance',
    accessor: 'buildProperty.corrosionResistance',
    key: 'corrosionResistance',
  },
  {
    title: 'Wear resistance',
    accessor: 'buildProperty.wearResistance',
    key: 'wearResistance',
  },
];
